import React from 'react'
import Button from '@material-ui/core/Button'
import { isAdmin } from '../../../util/validation'
import { AppContainer, CardContainer } from '../../../container'
import { status as statusColor } from '../../../materialTheme/colors'
import { brandName } from '../../../util/brandConfig'

interface defaultValueProps {
    defaultValue: string
    valueType: string
    cardId: number
}

const InputText = ({ defaultValue, valueType, cardId }: defaultValueProps) => {
    const {
        updateName,
        updatePostCode,
        updateCardNumber,
        getCardError,
        setCardNumber,
        setCardHolderName,
        setCardHolderPostCode,
        cardError,
    } = CardContainer.useContainer()
    const handleChange = (event: any) => {
        if (valueType === 'cardName') {
            updateName(event.target.value, cardId)
            setCardHolderName(event.target.value)
        }
        if (valueType === 'cardPostCode') {
            updatePostCode(event.target.value, cardId)
            setCardHolderPostCode(event.target.value)
        }
        if (valueType === 'cardNumber') {
            updateCardNumber(event.target.value, cardId)
            setCardNumber(event.target.value)
        }
    }

    return (
        <div className="pb-2">
            <input
                type="text"
                placeholder={defaultValue}
                onBlur={handleChange}
                className={`${
                    (valueType === 'cardNumber' && getCardError(cardId, 'number')) ||
                        (valueType === 'cardName' && getCardError(cardId, 'name')) ||
                        (valueType === 'cardPostCode' && getCardError(cardId, 'postcode'))
                        ? 'p-inputtext p-component p-invalid p-d-block'
                        : ''
                    } p-1 pl-2 border border-white-600 container`}
            />
            {!cardError ? (
                getCardError(cardId, 'number') && valueType === 'cardNumber' ? (
                    <small id="cardnumber2-help" className="font-thin p-error p-d-block">
                        Card number is invalid.
                    </small>
                ) : (
                        ''
                    )
            ) : getCardError(cardId, 'number') && valueType === 'cardNumber' ? (
                <small id="cardnumber2-help" className="font-thin p-error p-d-block">
                    {`The ${brandName} card number doesn't match our records, please try
                    again.`}
                </small>
            ) : (
                        ''
                    )}
            {!cardError ? (
                getCardError(cardId, 'name') && valueType === 'cardName' ? (
                    <small id="cardname2-help" className="font-thin p-error p-d-block">
                        Card name is not available.
                    </small>
                ) : (
                        ''
                    )
            ) : getCardError(cardId, 'name') && valueType === 'cardName' ? (
                <small id="cardname2-help" className="font-thin p-error p-d-block">
                    {`The last name doesn't match the ${brandName} card number, please try
                    again.`}
                </small>
            ) : (
                        ''
                    )}
            {!cardError ? (
                getCardError(cardId, 'postcode') && valueType === 'cardPostCode' ? (
                    <small
                        id="cardpostcode2-help"
                        className="font-thin p-error p-d-block"
                    >
                        Card post code is invalid.
                    </small>
                ) : (
                        ''
                    )
            ) : getCardError(cardId, 'postcode') && valueType === 'cardPostCode' ? (
                <small id="cardpostcode2-help" className="font-thin p-error p-d-block">
                    {`The post code doesn't match the ${brandName} card number, please try
                    again.`}
                </small>
            ) : (
                        ''
                    )}
        </div>
    )
}

const StatusDropdown = ({ cardId, action, status, isNew }: any) => {
    const {
        setCardStatus,
        updateCardStatus,
        updatePreviousStatus,
    } = CardContainer.useContainer()
    const options = ['Active', 'Disable']

    const setDropDown = (value: string) => {
        setCardStatus(value)
        updateCardStatus(value, cardId)
        updatePreviousStatus(status)
    }

    return action === 'save' || action === 'cancel' ? (
        isNew ? (
            <span>-</span>
        ) : (
                <span
                    className={`text-sm capitalize`}
                    style={{
                        color:
                            status.toLowerCase() === 'disable'
                                ? statusColor.disabled
                                : statusColor.active,
                    }}
                >
                    {status}
                </span>
            )
    ) : (
            <select
                value={status}
                onChange={(event) => {
                    setDropDown(event.target.value)
                }}
                className="w-full bg-white text-xs h-6 border-gray-400 border  focus:outline-none"
            >
                {options.map((option, index) => (
                    <option key={index} value={option}>
                        {option}
                    </option>
                ))}
            </select>
        )
}

const ActionIcons = ({
    cardId,
    cardNo,
    colleague,
    action,
    isNew,
    fAndfLimitReached,
    isAdminDisabled,
    status,
    cardStatusAdmin,
    isColleague,
}: any) => {
    const { colleagueDetail, role, isColleagueTerminated } = AppContainer.useContainer()
    const {
        updateAction,
        cardStatus,
        resetCardError,
        setCardNumber,
        setCardHolderName,
        setCardHolderPostCode,
        cardNumber,
        cardHolderName,
        cardHolderPostCode,
        updateCardHolderDetails,
        updateCardStatus,
        updateCardStatusBFF,
        cardPrevStatus,
        clearCardDetail,
        setOpenDialog,
        isActionAdmin,
    } = CardContainer.useContainer()

    const handleEditClick = () => {
        updateAction(cardId, 'edit')
    }

    const handleDeleteClick = () => {
        setOpenDialog(true)
    }

    const disableDeleteIcon = () => {
        if (isColleagueTerminated()) {
            return false
        }
        if (cardNo === 'xxxxxxxxxxxxxxxxxxx') {
            return false
        }
        if (fAndfLimitReached) {
            return false
        }

        return cardStatusAdmin !== 'true' && status !== 'Cancelled'
    }

    const disableEditIcon = () => {
        if (isColleagueTerminated()) {
            return false
        }
        if (cardNo === 'xxxxxxxxxxxxxxxxxxx') {
            return false
        }
        if (isActionAdmin) {
            return true
        }

        if (isAdminDisabled === 'true' && role === 'COLLEAGUE') {
            return false
        }
        if (
            status.toLowerCase() === 'cancelled' ||
            status.toLowerCase() === 'processing'
        ) {
            return false
        }
        return true
    }

    const handleClearClick = () => {
        updateAction(cardId, 'cancel')
        resetCardError(cardId)
        Array.from(document.querySelectorAll('input')).forEach(
            (input) => (input.value = '')
        )
        clearCardDetail(cardId)
        setCardNumber('')
        setCardHolderName('')
        setCardHolderPostCode('')
    }

    const handleCreateClick = () => {
        updateCardHolderDetails(
            colleagueDetail.employeeId,
            cardId,
            cardNumber,
            cardHolderName,
            cardHolderPostCode
        )
        updateAction(cardId, 'save')
    }

    const handleSaveClick = () => {
        updateCardStatusBFF(
            colleagueDetail.employeeId,
            cardNo,
            cardId,
            cardStatus,
            colleague,
            isAdmin(role)
        )
        updateAction(cardId, 'save')
    }

    const handleCancelClick = () => {
        updateCardStatus(cardPrevStatus, cardId)
        updateAction(cardId, 'save')
    }

    const buttonTemplate =
        action === 'save' || action === 'cancel' ? (
            colleague ? (
                <div className="flex justify-end">
                    <Button
                        variant="contained"
                        className="w-32 h-8 focus:outline-none"
                        color="primary"
                        onClick={handleEditClick}
                        disabled={!disableEditIcon()}
                    >
                        Edit
          </Button>
                </div>
            ) : !isNew ? (
                <div className="flex w-full mt-2 justify-around">
                    <Button
                        variant="contained"
                        className="w-32 h-8 focus:outline-none"
                        color="primary"
                        onClick={handleDeleteClick}
                        disabled={!disableDeleteIcon()}
                    >
                        Delete
          </Button>
                    <Button
                        variant="contained"
                        className="w-32 h-8 focus:outline-none"
                        color="primary"
                        onClick={handleEditClick}
                        disabled={!disableEditIcon()}
                    >
                        Edit
          </Button>
                </div>
            ) : (
                        <div className="flex w-full mt-2">
                            <Button
                                variant="contained"
                                className="w-1/2 h-8 focus:outline-none mr-1.5"
                                color="primary"
                                onClick={handleClearClick}
                                 disabled={isColleagueTerminated()}
                            >
                                Cancel
          </Button>
                            <Button
                                variant="contained"
                                className="w-1/2 h-8 focus:outline-none"
                                color="primary"
                                onClick={handleCreateClick}
                                 disabled={isColleagueTerminated()}
                            >
                                Save
          </Button>
                        </div>
                    )
        ) : (
                <div className="flex w-full mt-2">
                    <Button
                        variant="contained"
                        className="w-1/2 h-8 focus:outline-none mr-1.5"
                        color="primary"
                        onClick={handleCancelClick}
                    >
                        Cancel
        </Button>
                    <Button
                        variant="contained"
                        className="w-1/2 h-8 focus:outline-none"
                        color="primary"
                        onClick={handleSaveClick}
                    >
                        Save
        </Button>
                </div>
            )
    return <>{buttonTemplate}</>
}

export const ActionTemplate = ({
    cardId,
    cardNo,
    colleague,
    action,
    isNew,
    fAndfLimitReached,
    isAdminDisabled,
    status,
    cardStatusAdmin,
    isColleague,
}: any) => {
    return (
        <ActionIcons
            cardId={cardId}
            colleague={colleague}
            action={action}
            cardNo={cardNo}
            isNew={isNew}
            fAndfLimitReached={fAndfLimitReached}
            isAdminDisabled={isAdminDisabled}
            status={status}
            cardStatusAdmin={cardStatusAdmin}
            isColleague={isColleague}
        />
    )
}

export const CellTemplate = ({ defaultValue, cardId, valueType }: any) => {
    return (
        <InputText
            defaultValue={defaultValue}
            cardId={cardId}
            valueType={valueType}
            aria-describedby="cardname2-help"
        />
    )
}

export const StatusTemplate = ({ cardId, action, status, isNew }: any) => {
    return (
        <StatusDropdown
            cardId={cardId}
            action={action}
            status={status}
            isNew={isNew}
        />
    )
}
