import React, { useEffect } from 'react'
import { Container } from '../../../component/common/'
import { SearchContainer } from '../../../container'
import TroubleShootCardSearch from '../../../component/moreCardSearch/TroubleShootCardSearch'
import TroubleShootCardSearchResult from '../../../component/moreCardSearch/TroubleShootCardSearchResult'
import GoBackTo from '../../../component/adminHome/GoBackTo'
//import ErrorMessage from '../../../component/troubleShoot/ErrorMessage'
import consoleLog from '../../../util/consoleLog'
import SearchByEmailId from '../../../component/troubleShoots/SearchByEmailId'
import { brandName } from '../../../util/brandConfig'

function MoreCdsCards() {
    const {
        searchByMorrisonsCardResult,
        moreCardDetails,
        troubleShootSuccess,
        troubleshootFullResult,
        clearSearchResult
    } = SearchContainer.useContainer()

    useEffect(() => {
        clearSearchResult()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    consoleLog('MoreCdsCards', '24',
        'searchByMorrisonsCardResult : ',
        searchByMorrisonsCardResult,
        moreCardDetails,
        troubleShootSuccess
    )
    return (
        <Container>
            <div className="mt-8">
                <GoBackTo title={`Troubleshoot link ${brandName} Card`} link="/admin" />
            </div>

            <div className="flex-col mob:flex-col p-4">

                <SearchByEmailId  />
            </div>
            <div className="w-full bg-gray-100 p-4">
                <TroubleShootCardSearch />
                {troubleshootFullResult && <TroubleShootCardSearchResult />}
            </div>

        </Container>
    )
}
export default MoreCdsCards
