import React from 'react'

interface AvatarProps {
  imgSrc: string
}

function Avatar({ imgSrc }: AvatarProps) {
  return (
    <div className="w-16 h-16 rounded-full overflow-hidden mr-4">
      <img src={imgSrc} alt="avatar" />
    </div>
  )
}

export default Avatar
