import React, { useState } from 'react'
import { Container } from '../common'
import ColleagueSearchOption from './ColleagueSearchOption'
import ColleagueSearch from './ColleagueSearch'
import NonColleagueSearch from './NonColleagueSearch'
import { SearchResult, SearchResultMob } from '../common'
import { AppContainer } from '../../container'

const MainSearch = () => {
  const [option, setOption] = useState<string>('Colleague')
  const [error, setError] = useState<boolean>(false)
  const [searchResult, setSearchResult] = useState<any>(null)
  const { isMobile } = AppContainer.useContainer()

  const handleChange = (value: any) => {
    setOption(value)
    setSearchResult(null)
  }

  const handleSearchResult = (result: any) => {
    setSearchResult(result)
  }

  return (
    <Container>
      <div className="flex w-full flex-wrap mt-12 justify-center">
        {error && (
          <div className="text-red-600 m-4 w-full text-left">
            Please enter a valid search text!
          </div>
        )}
        <div className="mob:w-11/12">
          <ColleagueSearchOption onChange={handleChange} />
        </div>
        <div className="w-9/12 mob:w-11/12">
          {option === 'Colleague' ? (
            <ColleagueSearch
              onSearch={handleSearchResult}
              colleagueType={option}
            />
          ) : (
            <NonColleagueSearch
              onSearch={handleSearchResult}
              onError={setError}
              colleagueType={option}
            />
          )}
        </div>
      </div>
      <div className="flex w-full">
        {isMobile ? (
          <SearchResultMob searchResult={searchResult} role="admin" />
        ) : (
          <SearchResult searchResult={searchResult} role="admin" />
        )}
      </div>
    </Container>
  )
}
export default MainSearch
