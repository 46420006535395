import { useState, useEffect } from 'react'
import { createContainer } from 'unstated-next'
import { getFirebaseApp } from '../firebase'
import { callCloudFunctionsBFF } from '../cloudFunction'

const useNonColleagueContainer = () => {
  const [loading, setLoading] = useState<any>(false)
  const [employeeId, setEmployeeId] = useState<any>(null)
  const [discountCardApiResp, setDiscountCardApiResp] = useState<any>(false)
  const [moreCardApiResp, setMoreCardApiResp] = useState<any>(false)
  const [faqForNonColleague, setFaqForNonColleague] = useState<any>()
  const [fireStoreMaintenance, setFireStoreMaintenance] = useState<any>(null)

  const [nonColleagueDetails, setNonColleagueDetails] = useState<any>({
    lastName: '',
    firstName: '',
    discountCardNumber: '',
    moreCardNumber: '',
  })
  const [showDialogApiNonColleagueCardError, setShowDialogApiNonColleagueCardError] = useState(false)

  const readFireStoreDB = async () => {
    const { firebase, fireStoreDB } = await getFirebaseApp()
    if (firebase.apps.length === 1) {
        await fireStoreDB.collection("CDSMaintenance")
            .onSnapshot(async (snapShot: any) => {
                const doc = snapShot.docs[0]
                const data = await doc.data()
                setFireStoreMaintenance(data)
            });
    }
}

  useEffect(() => {
    readFireStoreDB()
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [])

  const setFirstName = (firstName: string) => {
    setNonColleagueDetails({ ...nonColleagueDetails, firstName })
  }

  const setLastName = (lastName: string) => {
    setNonColleagueDetails({ ...nonColleagueDetails, lastName })
  }

  const setMoreCardNumber = (moreCardNumber: string) => {
    setNonColleagueDetails({ ...nonColleagueDetails, moreCardNumber })
  }

  const setDiscountCardNumber = (discountCardNumber: string) => {
    setNonColleagueDetails({ ...nonColleagueDetails, discountCardNumber })
  }

  const googleSignWithEmail = async (email: string, password: string) => {
    const { firebase } = await getFirebaseApp()
    if (firebase.apps.length === 1) {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(function (result: any) {})
        .catch(function (err: any) {
          setLoading(false)
        })
    }
  }

  const googleCreateUserWithEmail = async (email: string, password: string) => {
    const { firebase } = await getFirebaseApp()
    if (firebase.apps.length === 1) {
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(function (result: any) {})
        .catch(function (err: any) {
          setLoading(false)
        })
    }
  }

  const validateCDSCardBFF = async () => {
    setDiscountCardApiResp(null)
    const payload = {
      cardNumber: nonColleagueDetails.discountCardNumber,
      lastName: nonColleagueDetails.lastName,
      firstName: nonColleagueDetails.firstName,
    }
    setLoading(true)
    const callValidateCDSCardBFF = await callCloudFunctionsBFF(
      'validateNonColleagueCard'
    )
    const { data } = await callValidateCDSCardBFF(payload)
    setEmployeeId(data?.empId)
    setDiscountCardApiResp(data)
    setLoading(false)
  }

  const linkMoreCardNonColleagueBFF = async (payload: any) => {
    setMoreCardApiResp(null)
    setLoading(true)
    const callValidateMoreCardBFF = await callCloudFunctionsBFF(
      'linkMoreCardNonColleague'
    )
    const { data } = await callValidateMoreCardBFF(payload)
     if(data.apiFailedMessage){
        setShowDialogApiNonColleagueCardError(true)
    }
    setMoreCardApiResp(data)
    setLoading(false)
  }
  const callFaqForNonColleague = async (colleagueType: string) => {
    try {
      setLoading(true)
      const getFaqForNonColleague = await callCloudFunctionsBFF('faqDetails')
      const { data } = await getFaqForNonColleague({ colleagueType })
      setFaqForNonColleague(data)
      setLoading(false)
    } catch (err: any) {
      setLoading(false)
    }
  }

  return {
    loading,
    nonColleagueDetails,
    discountCardApiResp,
    moreCardApiResp,
    employeeId,
    setFirstName,
    setLastName,
    setMoreCardNumber,
    setDiscountCardNumber,
    setLoading,
    googleSignWithEmail,
    validateCDSCardBFF,
    googleCreateUserWithEmail,
    linkMoreCardNonColleagueBFF,
    callFaqForNonColleague,
    faqForNonColleague,
    showDialogApiNonColleagueCardError,
    setShowDialogApiNonColleagueCardError,
    fireStoreMaintenance
  }
}

const NonColleagueContainer = createContainer(useNonColleagueContainer)
export default NonColleagueContainer
