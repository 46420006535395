import React from 'react'
import { DataTable } from 'primereact/datatable'
import { PrimeColumn } from '../../common'
import {
  CardNumberTemplate,
  DiscountReceivedTemplate,
  StatusTemplate,
  CancelledDateTemplate,
} from './CancelledCardsTemplate'

import { CardContainer } from '../../../container'
import consoleLog from '../../../util/consoleLog'

const CancelledCards = () => {
  const { cancelledCards } = CardContainer.useContainer()

  const headerStyle = { fontSize: '14px', fontWeight: 'bold' }
  const cellCommonStyle = { fontSize: '14px', fontWeight: 'bold' }
  consoleLog('CancelledCards','19',cancelledCards)

  return cancelledCards ? (
    <div className="mt-4">
      <div className="mb-4 font-medium text-lg">Colleague Cancelled Cards</div>

      <DataTable value={cancelledCards}>
        <PrimeColumn
          field="cardNumber"
          body={CardNumberTemplate}
          style={cellCommonStyle}
          headerStyle={{ ...headerStyle, width: '180px' }}
          header="Card No."
        />
        <PrimeColumn
          field="cardNumber"
          body={DiscountReceivedTemplate}
          style={cellCommonStyle}
          headerStyle={{ ...headerStyle, width: '180px' }}
          header="Discount Received until Cancellation"
        />
        <PrimeColumn
          field="cardNumber"
          body={StatusTemplate}
          style={cellCommonStyle}
          headerStyle={{ ...headerStyle, width: '180px' }}
          header="Status"
        />
        <PrimeColumn
          field="cardNumber"
          body={CancelledDateTemplate}
          style={cellCommonStyle}
          headerStyle={{ ...headerStyle, width: '180px' }}
          header="Date of Cancellation"
        />
      </DataTable>
    </div>
  ) : null
}

export default CancelledCards
