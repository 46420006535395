import React from 'react'
import { Link } from 'react-router-dom';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

interface GoBackToProps {
    title: string;
    link: string;
}

const GoBackTo: React.FC<GoBackToProps> = ({title, link}) => {
  return (
      <div className='mb-2 text-gray-600'>
        <h1><Link to={link}><ArrowBackIosIcon /></Link>{title}</h1>
      </div>
  )
}
export default GoBackTo
