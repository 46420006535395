import React from 'react'
import { Container } from '../../../component/common/'
import GroupAllowance from '../../../component/groupAllowance/GroupAllowance'
import SetAllowanceMob from '../../../component/groupAllowance/SetAllowanceMob'
import { AppContainer } from '../../../container'

function SetAllowance() {
  const { isMobile } = AppContainer.useContainer()

  return (
    <Container>{isMobile ? <SetAllowanceMob /> : <GroupAllowance />}</Container>
  )
}
export default SetAllowance
