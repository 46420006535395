import { Currency } from '../../common'

export const CurrencyTemplateCardAllowance = ({
  cardAllowance,
  status,
  saved,
  action,
  showAnchor,
}: any) => {
  return (
    <div
      className={`${
        status.toLowerCase() === 'disable' && action === 'save'
          ? 'text-gray-400'
          : ''
      } flex text-sm ${showAnchor ? 'mt-8' : ''}`}
    >
      {cardAllowance !== '' ? <Currency amount={cardAllowance} /> : '-'}
    </div>
  )
}
export const CurrencyTemplateAmountUsed = ({
  amountUsed,
  status,
  saved,
  action,
  showAnchor,
}: any) => {
  return (
    <div
      className={`${
        status.toLowerCase() === 'disable' && action === 'save'
          ? 'text-gray-400'
          : ''
      } flex text-sm ${showAnchor ? 'mt-8' : ''}`}
    >
      {amountUsed !== '' ? <Currency amount={amountUsed} /> : '-'}
    </div>
  )
}
export const CurrencyTemplateAmountRemaining = ({
  amountRemaining,
  status,
  saved,
  action,
  showAnchor,
}: any) => {
  return (
    <div
      className={`${
        status.toLowerCase() === 'disable' && action === 'save'
          ? 'text-gray-400'
          : ''
      } flex text-sm ${showAnchor ? 'mt-8' : ''}`}
    >
      {amountRemaining !== '' ? <Currency amount={amountRemaining} /> : '-'}
    </div>
  )
}

export const TemplatedAllowanceDisable = ({
  cardAllowance,
}: any) => {
  return (
    <div
      className={'text-gray-400 flex text-sm '}
    >
      {cardAllowance !== '' ? <Currency amount={cardAllowance} /> : '-'}
    </div>
  )
}

export const TemplateAmountRemainingDisable = ({
  amountRemaining,
}: any) => {
  return (
    <div
      className={'text-gray-400 flex text-sm '}
    >
      {amountRemaining !== '' ? <Currency amount={amountRemaining} /> : '-'}
    </div>
  )
}

export const TemplateAmountUsedDisable = ({
  amountUsed,
}: any) => {
  return (
    <div
      className={'text-gray-400 flex text-sm '}
    >
      {amountUsed !== '' ? <Currency amount={amountUsed} /> : '-'}
    </div>
  )
}
