import { useState, useEffect } from 'react'
import { createContainer } from 'unstated-next'
import { callCloudFunctionsBFF } from '../cloudFunction'
import { disableGoogleSignIn } from '../appConfig'
import { isValidPostCode, isValidMoreCard } from '../util/validation'
import { getData } from '../service'
import consoleLog from '../util/consoleLog'

const useCardContainer = () => {
    const [cardDetails, setCardDetails] = useState<any>(null)
    const [dataChange, setDataChange] = useState<boolean>(false)
    const [cardDetailsAction, setCardDetailsAction] = useState<any>(null)
    const [cardStatus, setCardStatus] = useState<string>('Active')
    const [cardError, setCardError] = useState<boolean>(false)
    const [cardCancelled, setCardCancelled] = useState<any>([])
    const [loading, setLoading] = useState<boolean>(true)
    const [cardNumber, setCardNumber] = useState<any>('')
    const [cardHolderName, setCardHolderName] = useState<string>('')
    const [cardHolderPostCode, setCardHolderPostCode] = useState<string>('')
    const [cardTransactionDetails, setCardTransactionDetails] = useState<any>(
        null
    )
    const [cardPrevStatus, setCardPrevStatus] = useState<string>('')
    const [spinCardLoading, setSpinCardLoading] = useState<boolean>(false)
    const [cancelOrderNewCard, setCancelOrderNewCard] = useState<any>({
        message: null,
        errorMessage: null,
    })

    const [cancelledCards, setCancelledCards] = useState<any>(null)
    const [linkMoreCardResponse, setLinkMoreCardResponse] = useState<any>({
        message: null,
        errorMessage: null,
    })
    const [faqForNonColleague, setFaqForNonColleague] = useState<any>()
    const [isActionAdmin, setIsActionAdmin] = useState('')

    const [openDialog, setOpenDialog] = useState(false)

    const [cardMapped, setCardMapped] = useState(false)
    const [showDialogApiCardError, setShowDialogApiCardError] = useState(false)
    const [cancelOrderNewResp, setCancelOrderNewResp] = useState<any>(null)
    const [enableDisableCardResp, setEnableDisableCardresp] = useState<any>(null)

    const updateAction = (cardId: number, action: string) => {
        const parseGroup = cardDetailsAction.map((card: any) => {
            return card.cardId === cardId ? { ...card, action: action } : card
        })
        setCardDetailsAction(parseGroup)
    }

    const updateCardHolderDetails = (
        empId: number,
        cardId: number,
        cardNumber: number,
        name: string,
        postcode: string
    ) => {
        if (Array.isArray(cardDetailsAction)) {
            const updateCardHolderName = cardDetailsAction.map((card: any) => {
                if (card.cardId === cardId) {
                    if (!isValidMoreCard(card.cardNumber)) {
                        updateCardError(true, cardId, 'number')
                    } else if (card.cardHolderName === '') {
                        updateCardError(true, cardId, 'name')
                    } else if (!isValidPostCode(card.cardHolderPostCode)) {
                        updateCardError(true, cardId, 'postcode')
                    } else {
                        card.status = 'Active'
                        card.saved = true
                        updateFriendsFamilyCardBFF(
                            empId,
                            cardId,
                            cardNumber,
                            name,
                            postcode
                        )
                    }
                }
                return card
            })
            setCardDetailsAction(updateCardHolderName)
        }
    }

    const updateName = (name: string, cardId: number) => {
        if (Array.isArray(cardDetailsAction)) {
            const updatedName = cardDetailsAction.map((card: any) => {
                if (card.cardId === cardId) {
                    if (name && name.length > 0) {
                        card.cardHolderName = name
                        updateCardError(false, cardId, 'name')
                    } else {
                        updateCardError(true, cardId, 'name')
                    }
                }
                return card
            })
            setCardError(false)
            setCardDetailsAction([...updatedName])
        }
    }

    const updatePostCode = (postCode: string, cardId: number) => {
        if (Array.isArray(cardDetailsAction)) {
            const updatedName = cardDetailsAction.map((card: any) => {
                if (card.cardId === cardId) {
                    if (isValidPostCode(postCode)) {
                        card.cardHolderPostCode = postCode
                        updateCardError(false, cardId, 'postcode')
                    } else {
                        updateCardError(true, cardId, 'postcode')
                    }
                }
                return card
            })
            setCardError(false)
            setCardDetailsAction([...updatedName])
        }
    }

    const updateCardNumber = (cardNumber: string, cardId: number) => {
        if (Array.isArray(cardDetailsAction)) {
            const updatedName = cardDetailsAction.map((card: any) => {
                if (card.cardId === cardId) {
                    if (isValidMoreCard(cardNumber)) {
                        card.cardNumber = cardNumber
                        updateCardError(false, cardId, 'number')
                    } else {
                        updateCardError(true, cardId, 'number')
                    }
                }
                return card
            })
            setCardError(false)
            setCardDetailsAction([...updatedName])
        }
    }

    //   const updateCardStatusByCardNumber = (
    //     cardStatus: string,
    //     cardNumber: number
    //   ) => {
    //     if (Array.isArray(cardDetailsAction)) {
    //       const updateStatus = cardDetailsAction.map((card: any) => {
    //         if (card.cardNumber === cardNumber) {
    //           card.status = cardStatus
    //           card.action = 'save'
    //         }
    //         return card
    //       })
    //       consoleLog('CardContainer','146','updateStatus : ', updateStatus)
    //       setCardDetailsAction([...updateStatus])
    //     }
    //   }

    const updateCardStatus = (cardStatus: string, cardId: number) => {
        consoleLog('CardContainer', '152', cardStatus, cardId)
        if (Array.isArray(cardDetailsAction)) {
            const updateStatus = cardDetailsAction.map((card: any) => {
                consoleLog('CardContainer', '155',
                    'card.cardId === cardId',
                    card.cardId,
                    cardId,
                    card.cardId === cardId
                )
                if (card.cardId === cardId) {
                    card.status = cardStatus
                }
                consoleLog('CardContainer', '164', 'card : ', card)
                return card
            })
            consoleLog('CardContainer', '167', 'updateStatus : ', updateStatus)
            setCardDetailsAction([...updateStatus])
        }
    }

    const updateCardError = (
        cardError: boolean,
        cardId: number,
        field: string
    ) => {
        if (Array.isArray(cardDetailsAction)) {
            const updateError = cardDetailsAction.map((card: any) => {
                if (card.cardId === cardId) {
                    card[`${field}Error`] = cardError
                }
                return card
            })
            setCardDetailsAction([...updateError])
        }
    }

    const getCardError = (cardId: number, field: string) => {
        let status = false
        if (Array.isArray(cardDetailsAction)) {
            cardDetailsAction.forEach((card: any) => {
                if (card.cardId === cardId) {
                    status = card[`${field}Error`]
                    return
                }
            })
        }
        return status
    }

    const updateFriendsFamilyCardBFF = async (
        empId: number,
        cardId: number,
        cardNumber: number,
        name: string,
        postcode: string
    ) => {
        setSpinCardLoading(true)
        const payload: any = {}
        cardDetailsAction.forEach((card: any) => {
            if (card.cardId === cardId) {
                payload.moreCardNumber = cardNumber
                payload.empId = empId
                payload.postCode = postcode
                payload.lastName = name
                payload.action = 'issue'
                return
            }
        })
        const callFriendsFamilyCardBFF = await callCloudFunctionsBFF(
            'addAndUpdateFriendAndFamily'
        )
        const { data } = await callFriendsFamilyCardBFF(payload)

        if (data.apiFailedMessage) {
            setShowDialogApiCardError(true)
            updateAction(cardId, 'edit')

            if (Array.isArray(cardDetailsAction)) {
                const updateCardHolderName = cardDetailsAction.map((card: any) => {
                    if (card.cardId === cardId) {
                        card.status = ''
                        card.saved = false
                        card.isNew = true
                    }
                    return card
                })
                setCardDetailsAction(updateCardHolderName)
            }

        } else {
            getColleagueCardDetails(isActionAdmin ? isActionAdmin : '')

            if (data.isCardMapped) {
                setCardMapped(data.isCardMapped)
            }

            if (data.statusFlag) {
                if (Array.isArray(cardDetailsAction)) {
                    const updateCardHolderName = cardDetailsAction.map((card: any) => {
                        if (card.cardId === cardId) {
                            card.cardNumber = 'xxxxxxxxxxxxxxxxxxx'
                            card.status = 'Processing'
                            card.saved = true
                            card.isNew = false
                        }
                        return card
                    })
                    setCardDetailsAction(updateCardHolderName)
                }
            } else {
                if (Array.isArray(cardDetailsAction)) {
                    const updateCardHolderName = cardDetailsAction.map((card: any) => {
                        if (card.cardId === cardId) {
                            card.status = 'active'
                            card.saved = false
                        }
                        return card
                    })
                    setCardDetailsAction(updateCardHolderName)
                }
                setCardError(true)
                if (data.isValidMoreCardNumber === null || !data.isValidMoreCardNumber) {
                    updateCardError(true, cardId, 'number')
                } else if (data.isValidLastName === null || !data.isValidLastName) {
                    updateCardError(true, cardId, 'name')
                } else {
                    updateCardError(true, cardId, 'postcode')
                }
            }
        }
        setSpinCardLoading(false)
    }

    const updateCardStatusBFF = async (
        empId: number,
        cardNumber: number,
        cardId: number,
        status: string,
        isColleague: Boolean,
        actionDoneBy: string = '',
        custNo: string = ''
    ) => {
        setSpinCardLoading(true)
        const payload: any = {}
        cardDetailsAction.forEach((card: any) => {
            if (card.cardId === cardId) {
                payload.cardNumber = cardNumber
                payload.empId = empId
                payload.action = status.toLowerCase() === 'active' ? 'enable' : status
                payload.userType = isColleague ? 'colleague' : 'F&F'
                payload.actionDoneBy = actionDoneBy
                payload.custNo = custNo
                return
            }
        })
        consoleLog('CardContainer', '282', 'payload : ', payload)
        const callFriendsFamilyCardBFF = await callCloudFunctionsBFF(
            'enableDisableCardDetails'
        )
        const { data } = await callFriendsFamilyCardBFF(payload)
        setEnableDisableCardresp(data)


        if (data.apiFailedMessage) {
            setShowDialogApiCardError(true)
            setSpinCardLoading(false)
            parseCardDetails()
            setCardError(true)
        } else {
            if (data.message === 'Card Status changed successfully.') {
                getColleagueCardDetails(isActionAdmin ? isActionAdmin : '')

                // eslint-disable-next-line
                //   updateCardStatusByCardNumber(status, payload.cardNumber)
            } else {
                if (Array.isArray(cardDetailsAction)) {
                    const updateCardHolderName = cardDetailsAction.map((card: any) => {
                        if (card.cardId === cardId) {
                            card.status = '-'
                            card.saved = false
                        }
                        return card
                    })
                    setCardDetailsAction(updateCardHolderName)

                }
                setCardError(true)
            }
            setSpinCardLoading(false)
            setCardError(false)
        }
    }

    const getCancelOrderNewCards = () => {
        let cardNumber = ''
        if (Array.isArray(cardDetails)) {
            cardDetails.forEach((card: any) => {
                if (card.isColleague) {
                    cardNumber = card.cardNumber
                }
            })
        }
        return cardNumber
    }

    const isNonColleagueGroup = () => {
        if (Array.isArray(cardDetails)) {
            cardDetails.forEach((card: any) => {
                if (card.isNonColleague) {
                    return true
                }
            })
        }
        return false
    }

    const callCancelOrderNewCardBFF = async (payload: any) => {
        setSpinCardLoading(true)
        const getCancelAndOrderNewCardBFF = await callCloudFunctionsBFF(
            'cancelOrderNewCard'
        )
        const { data } = await getCancelAndOrderNewCardBFF(payload)
        setCancelOrderNewResp(data)

        if (data.apiFailedMessage) {
            setShowDialogApiCardError(true)
        } else {
            getColleagueCardDetails(isActionAdmin ? isActionAdmin : '')
        }
        const { errorMessage, message } = data
        setCancelOrderNewCard({ errorMessage, message })
        setSpinCardLoading(false)
    }

    const callCancelOrderNewCardFAndFBFF = async (payload: any) => {
        setSpinCardLoading(true)
        const getCancelAndOrderNewCardBFF = await callCloudFunctionsBFF(
            'cancelFriendsAndFamilyCard'
        )
        const { data } = await getCancelAndOrderNewCardBFF(payload)

        if (data.apiFailedMessage) {
            setShowDialogApiCardError(true)
        } else {
            getColleagueCardDetails(isActionAdmin ? isActionAdmin : '')
        }
        const { errorMessage, message } = data
        setCancelOrderNewCard({ errorMessage, message })
        setSpinCardLoading(false)
    }

    const callLinkMoreCard = async (payload: any) => {
        setSpinCardLoading(true)
        const getLinkMoreCardBFF = await callCloudFunctionsBFF('linkMoreCard')
        const { data } = await getLinkMoreCardBFF(payload)
        if (data.apiFailedMessage) {
            setShowDialogApiCardError(true)
        }
        setLinkMoreCardResponse(data)
        setSpinCardLoading(false)
    }

    const callFaqForNonColleague = async (colleagueType: string) => {
        setSpinCardLoading(true)
        const getFaqForNonColleague = await callCloudFunctionsBFF('faqDetails')
        const { data } = await getFaqForNonColleague({ colleagueType })
        setFaqForNonColleague(data)
        setSpinCardLoading(false)
    }

    const pushCancelOrderNewCard = (newCard: any) => {
        setCardCancelled([newCard, ...cardCancelled])
    }
    const parseCardDetails = () => {

        if (cardDetails) {
            let ffcount = 0
            const parseGroup:any = []
            // const parseGroup = cardDetails.map((card: any, index: number) => {

               cardDetails.forEach((card: any, index: number) => {
                card.showAnchor = !card.isColleague && cardDetails.indexOf(card) === 1
                card.cardId = index
                card.cardHolderName = !card.cardNumber ? '' : card.cardHolderName
                card.cardHolderPostalCode = !card.cardNumber
                    ? ''
                    : card.cardHolderPostalCode
                card.showAnchor = !card.isColleague && cardDetails.indexOf(card) === 1
                card.saved = card.cardNumber ? true : false
                card.isNew = !card.cardNumber ? true : false
                card.status = card.status ? card.status : ''
                if(card.isColleague === false)
                {
                    ffcount++
                }
                if(ffcount < 2 ){
                    parseGroup.push( { ...card, action: 'save' })
                }
               
            })
            setCardDetailsAction(parseGroup)
        }
    }
    useEffect(() => {
        // if (cardDetails) {
        //   const parseGroup = cardDetails.map((card: any, index: number) => {
        //     card.showAnchor = !card.isColleague && cardDetails.indexOf(card) === 1
        //     card.cardId = index
        //     card.cardHolderName = !card.cardNumber ? '' : card.cardHolderName
        //     card.cardHolderPostalCode = !card.cardNumber
        //       ? ''
        //       : card.cardHolderPostalCode
        //     card.showAnchor = !card.isColleague && cardDetails.indexOf(card) === 1
        //     card.saved = card.cardNumber ? true : false
        //     card.isNew = !card.cardNumber ? true : false
        //     return { ...card, action: 'save' }
        //   })
        //   setCardDetailsAction(parseGroup)
        // }
        parseCardDetails()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cardDetails])

    const getColleagueCardDetailsMock = () => {
        const result = getData('cardDetails')
        setCardDetails(result)
    }

    const getColleagueCardTransactionDetailsMock = () => {
        const result = getData('transactionDetails')
        setCardTransactionDetails(result)
    }

    const callColleagueCardDetailsBFF = async (payload: any) => {
        const getColleagueCardDetailsBFF = await callCloudFunctionsBFF(
            'colleagueCardDetails'
        )
        const { data } = await getColleagueCardDetailsBFF(payload)
        setCardDetails(data?.cardDetails)
        setCancelledCards(data?.cancelledCardDetails)
        setLoading(true)
    }

    const callColleagueCardTransactionDetailsBFF = async (payload: any) => {
        const getColleagueCardTransactionDetailsBFF = await callCloudFunctionsBFF(
            'cardTransactionDetails'
        )
        const { data } = await getColleagueCardTransactionDetailsBFF(payload)
        consoleLog('CardContainer', '415', 'data : ', data)
        setCardTransactionDetails(data)
        setLoading(true)
    }

    const getColleagueCardDetails = (empId: string) => {
        const payload: any = {}
        if (empId !== '') {
            payload.empId = empId
        }

        disableGoogleSignIn
            ? getColleagueCardDetailsMock()
            : callColleagueCardDetailsBFF(payload)
    }

    const getColleagueCardTransactionDetails = (empId: string) => {
        const payload: any = {}
        if (empId !== '') {
            payload.empId = empId
        }

        disableGoogleSignIn
            ? getColleagueCardTransactionDetailsMock()
            : callColleagueCardTransactionDetailsBFF(payload)
    }

    const resetCardError = (cardId: number) => {
        updateCardError(false, cardId, 'number')
        updateCardError(false, cardId, 'postcode')
        updateCardError(false, cardId, 'name')
    }

    const updatePreviousStatus = (cardStatus: string) => {
        setCardPrevStatus(cardStatus)
    }

    const clearCardDetail = (cardId: number) => {
        if (Array.isArray(cardDetailsAction)) {
            const updateCard = cardDetailsAction.map((card: any) => {
                if (card.cardId === cardId) {
                    card.cardNumber = ''
                    card.cardHolderName = ''
                    card.cardHolderPostalCode = ''
                }
                return card
            })
            setCardDetailsAction([...updateCard])
        }
    }

    const isColleagueCardCancelled = () => {
        let colleagueCardCancel = false
        if (Array.isArray(cardDetails)) {
            cardDetails.forEach((card: any, index: number) => {
                if (card.isColleague && card.status === 'cancelled') {
                    colleagueCardCancel = true
                }
            })
        }
        return colleagueCardCancel
    }

    const isColleagueCardCancelledShowPopup = () => {
        let colleagueCardCancel = false
        if (Array.isArray(cardDetails)) {
            cardDetails.forEach((card: any, index: number) => {
                if (card.isColleague && (card.disableCancelCard === true)) {
                    colleagueCardCancel = true
                }
            })
        }
        return colleagueCardCancel
    }
    const isLinkMoreCard = () => {
        let colleagueLinkMoreCard = false
        if (Array.isArray(cardDetails)) {
            cardDetails.forEach((card: any, index: number) => {
                consoleLog('CardContainer', '482', ' card.moreCardNumber : ', card.moreCardNumber)
                if ((
                    card.isColleague &&
                    card.moreCardNumber !== '' &&
                    card.moreCardNumber !== null &&
                    card.moreCardNumber !== undefined
                ) || (card.isColleague && card.custNo !== '')) {
                    colleagueLinkMoreCard = true
                }
            })
        }
        return colleagueLinkMoreCard
    }

    return {
        cardDetails,
        updateAction,
        cardDetailsAction,
        setCardStatus,
        cardStatus,
        updateName,
        updatePostCode,
        updateCardNumber,
        updateCardHolderDetails,
        updateCardStatus,
        setDataChange,
        dataChange,
        getCardError,
        getCancelOrderNewCards,
        pushCancelOrderNewCard,
        loading,
        cardError,
        updateCardError,
        setCardError,
        resetCardError,
        cardNumber,
        setCardNumber,
        cardHolderName,
        setCardHolderName,
        cardHolderPostCode,
        setCardHolderPostCode,
        updateFriendsFamilyCardBFF,
        updateCardStatusBFF,
        callCancelOrderNewCardBFF,
        cancelOrderNewCard,
        spinCardLoading,
        getColleagueCardDetails,
        getColleagueCardTransactionDetails,
        cardTransactionDetails,
        cardPrevStatus,
        updatePreviousStatus,
        clearCardDetail,
        isColleagueCardCancelled,
        isLinkMoreCard,
        cancelledCards,
        callLinkMoreCard,
        linkMoreCardResponse,
        callFaqForNonColleague,
        faqForNonColleague,
        openDialog,
        setOpenDialog,
        callCancelOrderNewCardFAndFBFF,
        isNonColleagueGroup,
        isActionAdmin,
        setIsActionAdmin,
        cardMapped,
        showDialogApiCardError,
        setShowDialogApiCardError,
        cancelOrderNewResp,
        enableDisableCardResp,
        isColleagueCardCancelledShowPopup
    }
}

const CardContainer = createContainer(useCardContainer)
export default CardContainer
