import firebase from 'firebase/app'
import 'firebase/functions'
import 'firebase/firestore'
import 'firebase/auth'
import axios from 'axios'
import consoleLog from '../util/consoleLog'

let initializeOnce = false
let functions: any = null
let fireStoreDB : any = null
let url = '/__/firebase/init.json'

if (process.env.REACT_APP_ENV === 'development')
  url = 'https://pepl-colleaguediscountsys-dev.web.app/__/firebase/init.json'

export const firebaseDevConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
}

const getFirebaseConfig = async () => {
  return axios.get(url)
}

export const getFirebaseApp = async () => {
  if (initializeOnce === false) {
    initializeOnce = true
    let configResp: any = null
    let firebaseConfig: any = null
    try {
      consoleLog('index','36','in')
      configResp = await getFirebaseConfig()
    } catch (error: any) {
      configResp = { data: firebaseDevConfig }
    }
    firebaseConfig = configResp.data ? configResp.data : firebaseDevConfig
    if (firebase.apps.length === 0) {
      firebase.initializeApp(firebaseConfig)
    }

    consoleLog('index','46',process.env.REACT_APP_ENV)
    const region =
      process.env.REACT_APP_ENV === 'prd' ? 'europe-west1' : 'europe-west2'

    functions = firebase.app().functions(region)
    fireStoreDB = firebase.firestore()
  }
  return { firebase, functions, fireStoreDB }
}
