import React from 'react'
// import { Container } from '../component/common'
import logo from '../asset/svg/morrisons.svg'
function Maintenance({maintenanceMessage}:any) {
  return (
    // <div className="container mx-auto lg:px-8 lg:px-4">
    <div className="w-full bg-primary">
      <div className="h-screen pt-32 ">
        <div className="h-32">
          <img
            alt="Morrisons MyMorri"
            className="block w-auto h-full mx-auto"
            src={logo}
          />
        </div>
        <div className="text-xl text-center text-tertiary mt-8 text-lg">
          {maintenanceMessage.errorMessage1}
        </div>
        <h1 className="text-xl text-center text-tertiary mt-2 text-lg">
          {maintenanceMessage.errorMessage2}
        </h1>
      </div>
    </div>
    // </div>
  )
}
export default Maintenance