import React from 'react'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import Group from '@material-ui/icons/Group'
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet'
import CreditCardIcon from '@material-ui/icons/CreditCard'
import { Icon } from '../common'
import { tertiary } from '../../materialTheme/colors'
import { brandName } from '../../util/brandConfig'

function AdminNavigation() {
  return (
    <div className="w-full flex justify-center items-center mt-24 mob:mt-6 mob:flex-col mob:p-1.5">
      <Link to="/admin/group-list" className="w-1/4 m-4 mob:w-full">
        <Button
          variant="contained"
          className="w-full h-14 focus:outline-none "
          color="primary"
          startIcon={<Icon MIcon={Group} size={40} color={tertiary.tertiary} />}
        >
          Membership Group
        </Button>
      </Link>
      <Link to="/admin/setallowance" className="w-1/4 m-4 mob:w-full">
        <Button
          variant="contained"
          className="w-full h-14 focus:outline-none"
          color="primary"
          startIcon={
            <Icon
              MIcon={AccountBalanceWalletIcon}
              size={40}
              color={tertiary.tertiary}
            />
          }
        >
          Set Allowance
        </Button>
      </Link>
      <Link to="/admin/troubleshoot" className="w-1/4 m-4 mob:w-full">
        <Button
          variant="contained"
          className="w-full h-14 focus:outline-none"
          color="primary"
          startIcon={
            <Icon MIcon={CreditCardIcon} size={40} color={tertiary.tertiary} />
          }
        >
          {`Troubleshoot link ${brandName} Card`}
        </Button>
      </Link>
    </div>
  )
}

export default AdminNavigation
