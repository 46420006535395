import React from 'react'

interface FieldErrorsProps {
  show?: boolean
  message: string
}

export const FieldErrors: React.FC<FieldErrorsProps> = ({
  message,
  show = false,
}) => {
  return (
    <div className={`${!show ? '' : ''} text-xs text-error -mt-3 mb-3 sm:mb-0`}>
      {message}
    </div>
  )
}

export default FieldErrors
