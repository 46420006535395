export const formatCard = (cardNo: any) => {
  return cardNo
    ? cardNo
        .replace(/^(.{4})(.{5})(.{5})(.{4})(.{1})(.*)$/, '$1 $2 $3 $4 $5 $6')
        .trim()
    : ''
}

export const formatDate = (refreshDate: any) => {
  return refreshDate
}
