import React from 'react'
import Skeleton from '@material-ui/lab/Skeleton'

function ContentLoader() {
  return (
    <>
      <Skeleton variant="rect" className="m-2 block" width={210} height={15} />
      <Skeleton variant="rect" className="m-2" width={310} height={15} />
      <Skeleton variant="rect" className="m-2" width={410} height={15} />
      <Skeleton variant="rect" className="m-2" width={510} height={15} />
    </>
  )
}

export default ContentLoader
