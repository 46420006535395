import React from 'react'
import Button from '@material-ui/core/Button'

import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles'

import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import Icon from '../icon/Icon'
import { tertiary } from '../../../materialTheme/colors';
import ReportProblemRoundedIcon from '@material-ui/icons/ReportProblemRounded';
import {SearchContainer} from '../../../container'


const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  })

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string
  children: React.ReactNode
  onClose: () => void
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

function DialogApiErrorSearchContainer() {
  
  const [open, setOpen] = React.useState(true)
  const {setShowDialogApicardError} = SearchContainer.useContainer()

  const handleClose = () => {
    setOpen(false)
    setShowDialogApicardError(false)
  }
  return (
    <div className="w-full  bg-gray-100 flex justify-center py-4">
      <Dialog onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        className="mob:h-full mob:w-full"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        
        </DialogTitle>
        <DialogContent>
            <div className="ml-36 mob:mx-20">
              <Icon 
              MIcon={ReportProblemRoundedIcon}
              size={40}
              color={tertiary.tertiary}
            />
            </div>
             <div className="px-20 mob:px-0">
            The System is currently busy.<br/>
            <div className="ml-10 mob:mx-12">Please try again.</div>  
            </div>

        </DialogContent>

        <DialogActions>
          <div className="mob:flex mob:w-5/12 mob:justify-center mob:m-auto items-center mr-36 -my-1.5">
            <Button
              autoFocus
              onClick={handleClose}
              color="primary"
              className="focus:outline-none mb-4 mob:w-11/12 w-24"
              variant="contained"
            >
              Ok
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default DialogApiErrorSearchContainer
