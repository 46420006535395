import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import TabPanel from './TabPanel'
import { Container } from '../../common'
import { black } from '../../../materialTheme/colors'

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const useStyles = makeStyles((theme) => {
  return {
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
    },
    tabs: {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.primary.main,
    },
    appBar: {
      backgroundColor: 'transparent',
      boxShadow: 'none',
      borderBottom: `1px solid ${black.black25} `,
    },
  }
})

function MuiTab({ tabConfig }: any) {
  const classes = useStyles()
  const [value, setValue] = React.useState(0)

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue)
  }

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar}>
        <Container>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="Card Details"
            className={classes.tabs}
          >
            {tabConfig.map((tabItem: any, index: number) => (
              <Tab
                key={index}
                label={tabItem.header}
                {...a11yProps(index)}
                className="focus:outline-none"
              />
            ))}
          </Tabs>
        </Container>
      </AppBar>
      <Container>
        {tabConfig.map((tabItem: any, index: number) => (
          <TabPanel key={index} value={value} index={index}>
            {tabItem.Content}
          </TabPanel>
        ))}
      </Container>
    </div>
  )
}

export default MuiTab
