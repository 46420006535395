import { performanceTesting } from '../appConfig'
import { brandName } from '../util/brandConfig'

export const isEmail = (email: string) => {
  const emailValidation = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/
  return emailValidation.test(email)
}

export const isCard = (card: string) => {
  return isValidMoreCard(card)
}

export const isEmployeeId = (employeeId: string) => {
  const employeeIdValidation = /^[0-9]{5,10}$/
  return employeeIdValidation.test(employeeId)
}

export const isValidSearchText = (search: string) => {
  return isEmail(search) || isCard(search) || isEmployeeId(search)
}

export const validateMoreCardNumber = (value: string) => {
  let errorMessage

  if (!value) return
  if (!/^[0-9]+$/.test(value)) {
    errorMessage = "This is not a valid "+ brandName +" card number"
  } else {
    let nCheck = 0
    let bEven = false

    value = value.replace(/\D/g, '')

    for (var n = value.length - 1; n >= 0; n--) {
      var cDigit = value.charAt(n),
        nDigit = parseInt(cDigit, 10)

      if (bEven && (nDigit *= 2) > 9) nDigit -= 9

      nCheck += nDigit
      bEven = !bEven
    }

    if ((nCheck % 10 === 0) === false) {
      errorMessage = "This is not a valid "+ brandName +" card number"
    }
  }
  return errorMessage
}

export const isValidPostCode = (postCode: string) => {
  const postCodeValidation = /^([a-z0-9]\s*){5,8}$/i
  return postCodeValidation.test(postCode)
}

export const isValidMoreCard = (cardNumber: string) => {
  const moreCardValidation = /^[0-9]{19}$/
  return moreCardValidation.test(cardNumber)
}

export const isCentralAdmin = (role: string) => {
  return role === 'ADMIN' ? true : false
}

export const isAdmin = (role: string) => {
  return role === 'ADMIN' || role === 'STOREADMIN' ? 'admin' : ''
}

export const isAdminOrLP = (role: string) => {
  return performanceTesting ? true : role === 'ADMIN' || role === 'LPADMIN'
}

export const isStoreAdmin = (role: string) => {
  return performanceTesting ? true : role === 'STOREADMIN'
}
