import React from 'react'
import { DataTable } from 'primereact/datatable'
import { PrimeColumn } from '../../common'
import { ActionTemplateAdmin, ActionTemplateStoreAdmin } from './ActionTemplate'

interface SearchResultProps {
  searchResult: any
  role: string
}

function SearchResult({ searchResult, role }: SearchResultProps) {
  const cellCommonStyle = { fontSize: '14px', fontWeight: 'normal' }
  const headerStyle = {
    fontSize: '14px',
    fontWeight: 'bold',
    itemAlign: 'center',
  }

  return searchResult === null ? null : (
    <div className="mt-12 w-full">
      {Array.isArray(searchResult) && searchResult.length > 0 ? (
        <DataTable value={searchResult}>
          <PrimeColumn
            field="cardNumber"
            style={cellCommonStyle}
            header="Card Number"
            headerStyle={{ ...headerStyle, width: '40px' }}
          />
          <PrimeColumn
            field="colleagueName"
            style={cellCommonStyle}
            header="Employee Name"
            headerStyle={{ ...headerStyle, width: '20px' }}
          />
          <PrimeColumn
            field="employeeId"
            style={cellCommonStyle}
            header="Employee Id"
            headerStyle={{ ...headerStyle, width: '20px' }}
          />
          <PrimeColumn
            field="email"
            style={cellCommonStyle}
            header="Email Id"
            headerStyle={{ ...headerStyle, width: '70px' }}
          />
          <PrimeColumn
            field="Action"
            style={cellCommonStyle}
            header="Action"
            body={
              role === 'admin' ? ActionTemplateAdmin : ActionTemplateStoreAdmin
            }
            headerStyle={{ ...headerStyle, width: '10px' }}
          />
        </DataTable>
      ) : (
        <div className="w-full flex h-14 justify-center items-center text-red-600 text-lg">
          <span>No colleague found!</span>
        </div>
      )}
    </div>
  )
}

export default SearchResult
