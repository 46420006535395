import React from 'react'
import { Link } from 'react-router-dom'
import { brandName } from '../../util/brandConfig'

interface LinkMoreCardSuccessProps {}

export const LinkMoreCardSuccess: React.FC<LinkMoreCardSuccessProps> = () => {
  return (
    <section className="mt-10">
      <p className="mb-4 font-semibold text-lg leading-tight">
        Congratulations!
      </p>
      <p className="mb-4 leading-tight">
        {`You have successfully linked your ${brandName} card with your{' '}`}
        <b>Colleague Discount Club</b>.
      </p>
      <p className="mb-4 leading-tight">
        {`You will only need to use your Colleague Discount Club card at the
        checkout to receive Colleague Discount and ${brandName} offers. Please dispose
        of your old ${brandName} card if you haven't done so already.`}
      </p>
      <p className="mb-4 leading-tight">
        Click{' '}
        <Link to="/home" className="underline text-blue-600">
          here{' '}
        </Link>
        to return to the Colleague Discount Club.
      </p>
    </section>
  )
}