import { useState, useEffect } from 'react'
import { createContainer } from 'unstated-next'
import { callCloudFunctionsBFF } from '../cloudFunction'
import { getData } from '../service'
import { disableGoogleSignIn } from '../appConfig'
import { isEmail, isCard, isEmployeeId } from '../util/validation'
import consoleLog from '../util/consoleLog'

const useSearchContainer = () => {
    const [loading, setLoading] = useState<any>(false)
    const [isErrorSearchByCard, setIsErrorSearchByCard] = useState<any>(false)
    const [moreCardDetails, setMoreCardDetails] = useState<any>()
    const [moreCardResponse, setMoreCardResponse] = useState<any>()
    const [troubleShootSuccess, setTroubleShootSuccess] = useState<any>(false)
    const [clearMoreCardSearch, setClearMoreCardSearch] = useState<any>(false)
    const [clearEmailSearch, setClearEmailSearch] = useState<any>(false)
    const [isErrorSearchByEmployee, setIsErrorSearchByEmployee] = useState<any>(
        false
    )
    const [
        searchByDiscountCardResult,
        setSearchByDiscountCardResult,
    ] = useState<any>([])

    const [
        troubleshootByDiscountCardResult,
        setTroubleshootByDiscountCardResult,
    ] = useState<any>([])

    const [
        troubleshootFullResult,
        setTroubleshootFulldResult,
    ] = useState<any>(null)

    const [
        searchByMorrisonsCardResult,
        setSearchByMorrisonsCardResult,
    ] = useState<any>([])

    const [moreCardByEmail, setMoreCardByEmail] = useState<any>([])
    const [showDialogApiCardError, setShowDialogApicardError] = useState(false)

    useEffect(() => {
        validateCDSSearchResults()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [moreCardResponse, searchByDiscountCardResult])

    const [isCDSValidationError, setCDSValidationError] = useState(false)

    const getSearchResultDummy = () => {
        return getData('employeeSearch')
    }

    const getCDSSearchResultDummy = () => {
        return getData('searchByCDS')
    }

    const getSearchPayload = (searchKey: string, type: string) => {
        if (type === 'firstname') return { firstName: searchKey }
        if (type === 'lastname') return { lastName: searchKey }
        if (type === 'lastname') return { lastName: searchKey }
        if (type === 'and') return searchKey
        if (!type || type === '') {
            if (isEmail(searchKey)) {
                return { email: searchKey }
            }
            if (isCard(searchKey)) {
                return { cardNumber: searchKey }
            }
            if (isEmployeeId(searchKey)) {
                return { empId: searchKey }
            }
        }
    }

    const getSearchResult = async (
        searchKey: any,
        type: string,
        filter: any = {}
    ) => {
        const callGetSearchResultBFF = async () => {
            setLoading(true)
            const payload: any = getSearchPayload(searchKey, type)
            consoleLog('SearchContainer', '79', 'filter : ', filter)
            if (filter && filter.location) {
                payload.location = filter.location
            }
            if (filter && filter.nonColleagueOption) {
                payload.groupName = filter.nonColleagueOption
            }
            if (filter && filter.colleagueType) {
                let colleagueType = filter.colleagueType
                consoleLog('SearchContainer', '88', 'payload : ', payload)
                if (payload.groupName === 'Miscellaneous-colleagues') {
                    colleagueType = 'Colleague'
                }
                payload.colleagueType = colleagueType
            }
            const getSearchResultBFF = await callCloudFunctionsBFF('colleagueSearch')
            const { data } = await getSearchResultBFF(payload)
            const searchResult = data ? data : []
            setLoading(false)
            return searchResult
        }
        return disableGoogleSignIn
            ? getSearchResultDummy()
            : callGetSearchResultBFF()
    }

    const getCDSSearchResult = async (payload: any, filterText: string) => {
        const callGetSearchResultBFF = async () => {
            setSearchByDiscountCardResult([])
            setSearchByMorrisonsCardResult([])
            setLoading(true)
            resetSearchByCDSErrors()
            const getSearchResultBFF = await callCloudFunctionsBFF('colleagueSearch')
            const { data } = await getSearchResultBFF(payload)
            const searchResult =
                data && data.length > 0
                    ? [
                        {
                            firstName: data[0].firstName,
                            lastName: data[0].lastName,
                            preferredName: data[0].preferredName,
                            firstNameMismatchError: false,
                            lastNameMismatchError: false,
                            preferredNameMismatchError: false,
                        },
                    ]
                    : []
            setLoading(false)

            if (filterText === 'SearchByEmployee' && searchResult.length === 0) {
                setIsErrorSearchByEmployee(true)
            } else if (filterText === 'SearchByCard' && searchResult.length === 0) {
                setIsErrorSearchByCard(true)
            }
            setSearchByDiscountCardResult(searchResult)
        }
        return disableGoogleSignIn
            ? getCDSSearchResultDummy()
            : callGetSearchResultBFF()
    }

    const validateCustomerId = (name: string) => {
        return name ? String(name).toLowerCase().trim() : name
    }

    const validateCDSSearchResults = () => {
        const searchResultDiscountCard = parseCDSCardDetails(searchByDiscountCardResult)
        const searchByMoreCardResult = parseMoreCardDetails(moreCardResponse)

        if (
            searchResultDiscountCard.length > 0 &&
            searchByMoreCardResult.length > 0
        ) {
            //     let success = true
            if (
                (validateCustomerId(searchResultDiscountCard[0].accountID) !==
                    validateCustomerId(searchByMoreCardResult[0].accountID)) &&
                (validateCustomerId(searchResultDiscountCard[0].accountID) !== ''
                    && validateCustomerId(searchByMoreCardResult[0].accountID) !== '')
            ) {
                searchResultDiscountCard[0].accountIDMismatchError = true
                searchByMoreCardResult[0].accountIDMismatchError = true
                // success = false
            } else {
                searchResultDiscountCard[0].accountIDMismatchError = false
                searchByMoreCardResult[0].accountIDMismatchError = false
            }

            //     if (
            //         validateName(searchByMoreCardResult[0].lastName) !==
            //         validateName(searchResultDiscountCard[0].lastName)
            //     ) {
            //         searchResultDiscountCard[0].lastNameMismatchError = true
            //         searchByMoreCardResult[0].lastNameMismatchError = true
            //         success = false
            //     } else {
            //         searchResultDiscountCard[0].lastNameMismatchError = false
            //         searchByMoreCardResult[0].lastNameMismatchError = false
            //     }
            // setTroubleShootSuccess(success)
        }
        setTroubleShootSuccess(true)
        if (Array.isArray(searchResultDiscountCard)) {
            setTroubleshootByDiscountCardResult([...searchResultDiscountCard])
        } else {
            setTroubleshootByDiscountCardResult([])
        }
        if (Array.isArray(searchByMorrisonsCardResult)) {
            setMoreCardDetails([...searchByMoreCardResult])
        } else {
            setMoreCardDetails([])
        }
    }

    const resetSearchByCDSErrors = () => {
        setIsErrorSearchByCard(false)
        setIsErrorSearchByEmployee(false)
    }

    const parseMoreCardDetails = (data: any) => {
        const parseData = []

        if (data && data.firstName)
            parseData.push({
                ...data,
                accountIDMismatchError: false,
            })
        return parseData
    }

    const parseCDSCardDetails = (data: any) => {
        let searchCDSResult: any = []

        if (data && data.length > 0) {
            const res = data[0]
            searchCDSResult.push({
                ...res,
                accountIDMismatchError: false,
            }
            )

        }
        return searchCDSResult
    }

    const clearSearchResult = () => {
        setMoreCardByEmail([])
        setMoreCardResponse([])
        setTroubleshootByDiscountCardResult([])
        setMoreCardDetails([])
        setSearchByDiscountCardResult(null)
        setTroubleshootFulldResult(null)
    }

    const callGetMoreCardBFF = async (payload: any) => {
        setLoading(true)
        clearSearchResult()
        setClearEmailSearch(true)
        setClearMoreCardSearch(false)
        const getMoreCardBFF = await callCloudFunctionsBFF('getMoreCard')
        const { data } = await getMoreCardBFF(payload)
        setTroubleshootFulldResult(data)
        if (data && Array.isArray(data.salesForce))
            setMoreCardResponse(data.salesForce[0])
        if (data && Array.isArray(data.cds))
            setSearchByDiscountCardResult(data.cds)
        setLoading(false)
    }

    const callGetMoreCardByEmail = async (payload: any) => {
        setLoading(true)
        clearSearchResult()
        setClearEmailSearch(false)
        setClearMoreCardSearch(true)
        const getMoreCardByEmailBFF = await callCloudFunctionsBFF('getMoreCardByEmail')
        const { data } = await getMoreCardByEmailBFF(payload)
        if (Array.isArray(data) && data.length > 0 && data[0].apiFailedMessage) {
            setShowDialogApicardError(true)
        }
        setMoreCardByEmail(data)
        setLoading(false)
    }



    const CDSInputValdiation = (value: any) => {
        setCDSValidationError(value)
    }
    return {
        getSearchResult,
        getCDSSearchResult,
        loading,
        isErrorSearchByCard,
        isErrorSearchByEmployee,
        resetSearchByCDSErrors,
        validateCDSSearchResults,
        searchByMorrisonsCardResult,
        callGetMoreCardBFF,
        moreCardResponse,
        setMoreCardResponse,
        setSearchByMorrisonsCardResult,
        moreCardDetails,
        troubleShootSuccess,
        CDSInputValdiation,
        isCDSValidationError,
        moreCardByEmail,
        callGetMoreCardByEmail,
        setShowDialogApicardError,
        showDialogApiCardError,
        troubleshootByDiscountCardResult,
        troubleshootFullResult,
        clearSearchResult,
        clearMoreCardSearch,
        clearEmailSearch
    }
}

const SearchContainer = createContainer(useSearchContainer)
export default SearchContainer
