const black = {
  black: '#000000',
  black25: 'rgba(0, 0, 0, 0.25)',
  black50: 'rgba(0, 0, 0, 0.5)',
}

const white = {
  white: '#ffffff',
  white10: 'rgba(255, 255, 255, 0.1)',
  white50: 'rgba(255, 255, 255, 0.5)',
}

const message = {
  error: '#d0021b',
  validation: '#01bb56',
}

const socialMedia = {
  twitter: '#4699fe',
  facebook: '#5166b2',
  linkedin: '#0077b5',
  google: '#dd5246',
}

const primary = {
  primary: '#00683A',
  dark: '#005F39',
  light: '#7fc49f',
  lighter: '#e5f3eb',
  lightest: '#e3e7e6',
  primary50: 'rgba(0, 78, 55, 0.5)',
}

const secondary = {
  secondary: '#004e37',
  dark: '#004330',
  light: '#00763C',
  lighter: '#e5edeb',
  lightest: '#f2f6f5',
  secondary50: 'rgba(0, 137, 63, 0.5)',
}

const tertiary = {
  tertiary: '#fcbc00',
  light: '#fddd7f',
  dark: '#e9ae01',
  lighter: '#fef8e5',
  lightest: '#fff8eb',
}

const body = {
  body: '#383b3a',
  dark: '#303231',
  light: '#727575',
  lighter: '#c1c0c2',
  lightest: '#ebebeb',
  border: '#bfbfbf',
}

const disable = {
  primary: '#cdcdcd',
}

const status = {
    active: '#00683A',
    disabled: '#f44336'
}

export {
  body,
  primary,
  secondary,
  tertiary,
  message,
  black,
  white,
  socialMedia,
  disable,
  status
}
