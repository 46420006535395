import { useState, useEffect } from 'react'
import { createContainer } from 'unstated-next'
import { getFirebaseApp } from '../firebase'
import { callCloudFunctionsBFF } from '../cloudFunction'
import { getData } from '../service'
import { disableGoogleSignIn, performanceTesting } from '../appConfig'
import consoleLog from '../util/consoleLog'

const useAppContainer = () => {
    const [userInfo, setUserInfo] = useState<any>(null)
    const [colleagueDetail, setColleagueDetail] = useState<any>(null)
    const [authenticated, setAuthenticated] = useState(false)
    const [role, setRole] = useState('')
    const [location, setLocation] = useState('')
    const [employeeId, setEmployeeId] = useState('')
    const [isLoading, setIsLoading] = useState(true)
    const [isMobile, setIsMobile] = useState(false)
    const [ptLogin, setPtLogin] = useState(false)
    const [isSSO, setIsSSO] = useState<any>(null)
    const [firebaseObj, setFirebaseObj] = useState<any>(null)
    const [fireStoreMaintenance, setFireStoreMaintenance] = useState<any>(null)

    const validateEmail = (email: string) =>
        email.split('@')[1].includes('morrisonsplc')

    const checkFireBaseSession = async (firebase: any) => {
        firebase
            .auth()
            .setPersistence(firebase.auth.Auth.Persistence.SESSION)
            .then(() => { })
            .catch((error: any) => { })
    }

    const logout = () => {
        if (firebaseObj) {
            firebaseObj.auth().signOut()
        }
    }

    const ssoSingleSignIn = async () => {
        const { firebase } = await getFirebaseApp()
        setFirebaseObj(firebase)
        await checkFireBaseSession(firebase)
        await firebase.auth().onAuthStateChanged((result: any) => {
            if (result) {
                const { email, family_name, given_name, photoURL, displayName } = result
                let changeName = displayName
                if(result && Array.isArray(result.providerData)){
                    const changeUser = result.providerData
                    changeName = changeUser[changeUser.length -1].displayName
                }
                if (validateEmail(email)) {
                    setUserInfo({
                        imgSrc: photoURL,
                        firstName: given_name,
                        lastName: family_name,
                        displayName: changeName,
                    })
                    setIsSSO(true)
                    setAuthenticated(true)
                    consoleLog('AppContainer', '42', 'in sucess sso')
                } else {
                    setAuthenticated(false)
                    setIsLoading(false)
                    setIsSSO(false)
                }
            } else {
                setIsLoading(false)
                setIsSSO(false)
            }
        })
    }

    const googleSignInWithPopup = async () => {
        const { firebase } = await getFirebaseApp()
        const base_provider = new firebase.auth.GoogleAuthProvider()
        if (firebase.apps.length === 1) {
            firebase
                .auth()
                .signInWithPopup(base_provider)
                .then(function (result: any) {
                    const {
                        email,
                        family_name,
                        given_name,
                        picture,
                    } = result.additionalUserInfo.profile

                    if (validateEmail(email)) {
                        setUserInfo({
                            imgSrc: picture,
                            firstName: given_name,
                            lastName: family_name,
                        })
                        setAuthenticated(true)
                    } else {
                        setAuthenticated(false)
                        setIsLoading(false)
                    }
                })
                .catch(function (err: any) {
                    setIsLoading(false)
                })
        }
    }

    const googleCreateUserWithEmail = async (email: string, password: string) => {
        const { firebase } = await getFirebaseApp()
        if (firebase.apps.length === 1) {
            firebase
                .auth()
                .createUserWithEmailAndPassword(email, password)
                .then(function (result: any) {
                    consoleLog('AppContainer', '96', email, password)
                    setIsLoading(false)
                })
                .catch(function (err: any) {
                    setIsLoading(false)
                })
        }
    }

    const googleSignWithEmail = async (email: string, password: string) => {
        const { firebase } = await getFirebaseApp()
        if (firebase.apps.length === 1) {
            firebase
                .auth()
                .signInWithEmailAndPassword(email, password)
                .then(function (result: any) {
                    const { user } = result
                    const { email, family_name, given_name, picture } = user

                    if (validateEmail(email)) {
                        setUserInfo({
                            imgSrc: picture,
                            firstName: given_name,
                            lastName: family_name,
                        })
                        setAuthenticated(true)
                        setPtLogin(true)
                    }
                })
                .catch(function (err: any) {
                    setIsLoading(false)
                })
        }
    }

    const checkIsMobile = () => {
        const width = window.innerWidth
        if (width < 769) {
            setIsMobile(true)
        }
    }

    useEffect(() => {
        window.addEventListener('resize', checkIsMobile)
        checkIsMobile()
        if (disableGoogleSignIn) {
            setIsLoading(false)
            setAuthenticated(true)
            setUserInfo(null)
        } else {
            performanceTesting ? setIsLoading(false) : ssoSingleSignIn()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (firebaseObj)
            readFireStoreDB()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [firebaseObj])
    const readFireStoreDB = async () => {
        const { fireStoreDB } = await getFirebaseApp()
        if (firebaseObj.apps.length === 1) {
            await fireStoreDB.collection("CDSMaintenance")
                .onSnapshot(async (snapShot: any) => {
                    const doc = snapShot.docs[0]
                    const data = await doc.data()
                    setFireStoreMaintenance(data)
                });
        }
    }

    useEffect(() => {
        if (isSSO === false && process.env.REACT_APP_ENV === 'dev') {
            googleSignInWithPopup()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSSO])

    const getColleagueDetailsBFF = async (empId: string) => {
        if (disableGoogleSignIn) {
            const data = getData('cardDetails')
            setColleagueDetail(data)
        } else {
            setIsLoading(true)
            const payload: any = {}
            if (empId !== '') {
                payload.empId = empId
            }
            const callColleagueDetailsBFF = await callCloudFunctionsBFF(
                'colleagueDetails'
            )
            const { data } = await callColleagueDetailsBFF(payload)
            consoleLog('AppContainer', '174', 'Colleage deatils : ', data)

            setColleagueDetail(data)
            setIsLoading(false)
        }
    }

    const getColleagueRoleBFF = async () => {
        setIsLoading(true)
        const callColleagueDetailsBFF = await callCloudFunctionsBFF(
            'colleagueDetails'
        )
        const { data } = await callColleagueDetailsBFF()
        setRole(data?.role)
        // setRole('ADMIN')
        setLocation(data?.location)

        setEmployeeId(data?.employeeId)
        setIsLoading(false)
    }

    const isColleagueTerminated = () => {
        return colleagueDetail && colleagueDetail.isColleagueTerminate === true ? true : false
    }

    useEffect(() => {
        if (authenticated) {
            getColleagueRoleBFF()
        }
    }, [authenticated])

    return {
        userInfo,
        colleagueDetail,
        authenticated,
        isLoading,
        isMobile,
        getColleagueDetailsBFF,
        role,
        employeeId,
        googleSignWithEmail,
        googleCreateUserWithEmail,
        ptLogin,
        location,
        isSSO,
        firebaseObj,
        logout,
        isColleagueTerminated,
        fireStoreMaintenance
    }
}

const AppContainer = createContainer(useAppContainer)
export default AppContainer
