import React from 'react'
import { AppContainer } from '../../../container'
import consoleLog from '../../../util/consoleLog'
import { brandName } from '../../../util/brandConfig'

const CardList = ({ cardNumber, isAdmin }: any) => {
    return (
        <div className={`flex  items-center `}>
            <div className="w-full ">
                {isAdmin ? (
                    <>
                        <div>
                            Please confirm you want to cancel discount card {cardNumber} and order a
          new card?
        </div>
                        <div className="mt-4">
                            Please let the colleague know that you have cancelled and re-ordered their discount card.
                  </div>
                        <div className="mt-4">
                            {`If they have already linked their ${brandName} account, their new discount card will take a couple of weeks to arrive at their home address, but they will be able to use the digital card in the ${brandName} app in the meantime. If they haven't linked their ${brandName} account, their card will be sent to their site/store People team.`}
                  </div>
                    </>

                ) : <>
                        <div>
                            Please confirm you want to cancel your discount card {cardNumber} and order a
          new card?
        </div>
                        <div className="mt-4">
                            {`If you have already linked your ${brandName} account, your new discount card will take a couple of weeks to arrive at your home address, but you will be able to use the digital card in the ${brandName} app in the meantime. If you haven't linked your ${brandName} account, your card will be sent to your site/store People team.`}
            </div>
                    </>
                }
            </div>
        </div>
    )
}

const OrderNewCard = ({ cardNumber, isAdmin }: any) => {
    consoleLog('OrderNewCard', '26', 'isAdmin : ', isAdmin)
    const { isMobile } = AppContainer.useContainer()
    const width = isMobile ? '100%' : '500px'
    return (
        <div style={{ width }}>
            <div className="flex w-full">
                <div className="w-1/12 "></div>
            </div>
            <CardList cardNumber={cardNumber} isAdmin={isAdmin} />
        </div>
    )
}

export default OrderNewCard
