import React, { useState, useEffect } from 'react'
import InfoIcon from '@material-ui/icons/Info'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import CloseIcon from '@material-ui/icons/Close'
import { CardContainer } from '../../container'
import consoleLog from '../../util/consoleLog'

interface HelpProps {}

interface IFaq {
  answer: string
  question: string
}

const Help: React.FC<HelpProps> = () => {
  const [open, setOpen] = useState(false)
  const {
    callFaqForNonColleague,
    faqForNonColleague,
  } = CardContainer.useContainer()

  useEffect(() => {
    async function callFaq() {
      await callFaqForNonColleague('colleague')
    }
    callFaq()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = () => {
    setOpen(false)
  }
  consoleLog('Help','39','faqForNonColleague : ', faqForNonColleague)

  return (
    <div className="mb-8 mt-8 flex items-center text-sm">
      <InfoIcon className="mr-3" />
      <div>
        <p>
          If you have any problems, please refer to the{' '}
          <span
            onClick={handleToggle}
            className="underline text-blue-600 cursor-pointer"
          >
            FAQs
          </span>
          .
        </p>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div
            className="w-1/2 mob:w-11/12 p-4 bg-white m-auto"
            style={{
              maxWidth: 760,
            }}
          >
            <span onClick={handleClose} className="cursor-pointer float-right">
              <CloseIcon />
            </span>
            <h2 className="text-xl font-bold">Frequently Asked Questions</h2>
            <div className="overflow-auto h-84">
              {faqForNonColleague &&
                Array.isArray(faqForNonColleague.faq) &&
                faqForNonColleague.faq.map((faq: IFaq, index: number) => (
                  <div style={{ paddingTop: '1rem' }} key={index}>
                    <strong>{faq.question}</strong>
                    <p>{faq.answer}</p>
                  </div>
                ))}
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  )
}

export default Help
