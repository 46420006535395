import React from 'react'
import { useParams } from 'react-router-dom'
import { Container } from '../../component/common'
import ColleagueDetails from '../../component/card/ColleagueDetails'
import CardDetails from '../../component/card/CardDetails'

function StoreColleagueDetails() {
  let { empId } = useParams<{ empId: string }>()
  return (
    <Container>
      <ColleagueDetails empId={empId} />
      <CardDetails empId={empId} />
    </Container>
  )
}
export default StoreColleagueDetails
