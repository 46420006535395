import React, { useState } from 'react'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import { GroupContainer } from '../../container'
import InputText from './InputText'
import { Currency } from '../common'
import Button from '@material-ui/core/Button'
import GoBackTo from '../adminHome/GoBackTo'

const SetAllowanceMob = () => {
  const [expanded, setExpanded] = useState('')
  const {
    groupDetailsAction,
    allowance,
    updateGroupAllowance,
    updateAllowance,
    allowanceError,
  } = GroupContainer.useContainer()

  const handleChange = (panel: any) => (event: any) => {
    if (expanded !== panel) {
      setExpanded(panel)
      updateAllowance('')
    } else {
      setExpanded('')
    }
  }

  const handleCancelClick = () => {
    setExpanded('')
    updateAllowance('')
  }

  const isColleague = (groupName: string) => {
    return (
      groupName === 'Morrisons Colleagues' ||
      groupName === 'Miscellaneous-colleagues'
    )
  }

  const handleSaveClick = (groupName: any) => {
    updateGroupAllowance(allowance, groupName)
  }
  return (
    <div>
      {allowanceError && (
        <div className="mb-2 text-red-400">
          Please enter a valid yearly allowance
        </div>
      )}
      <GoBackTo title="Set Allowances" link="/admin" />
      {(groupDetailsAction || []).map((group: any, index: any) => {
        const panel = 'panel' + index
        return (
          <Accordion
            expanded={expanded === panel}
            onChange={handleChange(panel)}
            key={index}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <div className="flex w-full">
                <Typography className="w-3/4">{group.groupName}</Typography>
                <Typography className="w-1/4">
                  {!isColleague(group.groupName) ? (
                    <span className="ml-5">
                      <Currency amount={group.groupAllowance} />
                    </span>
                  ) : (
                    <span className="w-full text-center">-</span>
                  )}
                </Typography>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              {!isColleague(group.groupName) && (
                <div className="w-full">
                  <h1 className="mb-1 ml-2">Yearly Allowance</h1>
                  <div className="flex ml-0">
                    £ <InputText defaultValue={group.groupAllowance} />
                  </div>
                  <div className="flex mt-4 justify-between">
                    <Button
                      style={{ width: '140px' }}
                      className="focus:outline-none mr-2"
                      variant="contained"
                      color="primary"
                      onClick={handleCancelClick}
                    >
                      Cancel
                    </Button>
                    <Button
                      style={{ width: '140px' }}
                      className="focus:outline-none"
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        handleSaveClick(group.groupName)
                      }}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              )}
            </AccordionDetails>
          </Accordion>
        )
      })}
    </div>
  )
}
export default SetAllowanceMob
