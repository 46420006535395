import React from 'react'
import Main from './Main'

import { CardContainer } from '../../container'

function Home() {
  return (
    <div>
      <CardContainer.Provider>
        <Main />
      </CardContainer.Provider>
    </div>
  )
}

export default Home
