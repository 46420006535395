import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
//import { makeStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import { GroupContainer } from '../../../container'
import { Container } from '../../../component/common'
import consoleLog from '../../../util/consoleLog'

function EditGroup() {
  let { groupId } = useParams<{ groupId: string }>()
  const [error, setError] = useState(false)
  const { getGroupDetailsById, updateGroupName, updateGroupStatus } = GroupContainer.useContainer()
  const groupDetails = getGroupDetailsById(groupId)
  consoleLog('EditGroup','19','groupId : ', groupId, groupDetails)
  return (
    <div className="mt-8">
      <Container>
        <h1 className="my-4  text-gray-800 text-xl">Edit Membership Group</h1>
        {groupDetails && (
          <div className="w-full">
            <div className="flex flex-wrap">
              <div className="w-6/12 mt-6 pr-8">
                <TextField
                  label="Group Name"
                  variant="outlined"
                  value={groupDetails.groupName}
                  className="w-full"
                  onChange={(event: any) => {
                    event.target.value === '' ? setError(true) : setError(false)
                    updateGroupName(event.target.value, groupId)
                  }}
                  helperText={error ? 'Please enter group name' : ''}
                  error={error}
                />
              </div>
              <div className="w-3/12 mt-6 pr-8">
                <TextField
                  label="Group Id"
                  variant="outlined"
                  value={groupDetails.groupId}
                  className="w-full"
                  disabled
                />
              </div>
              <div className="w-3/12 mt-6">
                <FormControl variant="outlined" className="w-full h-12">
                  <InputLabel id="label-status">Status</InputLabel>
                  <Select
                    labelId="label-status"
                    value={groupDetails.status}
                    onChange={(event: any) => {
                    event.target.value === '' ? setError(true) : setError(false)
                    updateGroupStatus(event.target.value, groupId)
                  }}
                    label="Status"
                    className="w-full h-12"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={'Active'}>Active</MenuItem>
                    <MenuItem value={'InActive'}>InActive</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="w-3/12 mt-12 pr-8">
                <TextField
                  label="Club Name"
                  variant="outlined"
                  value={groupDetails.clubName}
                  className="w-full"
                  disabled
                />
              </div>
              <div className="w-3/12 mt-12 pr-8">
                <TextField
                  label="Club Id"
                  variant="outlined"
                  value={groupDetails.clubId}
                  className="w-full"
                  disabled
                />
              </div>
              <div className="w-3/12 mt-12 pr-8">
                <TextField
                  label="Start Date"
                  variant="outlined"
                  value={groupDetails.startDate}
                  className="w-full"
                  disabled
                />
              </div>
              <div className="w-3/12 mt-12">
                <TextField
                  label="End Date"
                  variant="outlined"
                  value={groupDetails.endDate}
                  className="w-full"
                  disabled
                />
              </div>
            </div>
            <div className="flex justify-end h-10 mt-10">
              <Button variant="contained" className="mr-4 w-32">
                Cancel
              </Button>
              <Button variant="contained" color="primary" className=" w-32">
                Save
              </Button>
            </div>
          </div>
        )}
      </Container>
    </div>
  )
}

export default EditGroup
