import React from 'react'
import { DataTable } from 'primereact/datatable'
import { PrimeColumn } from '../common'
import { SearchContainer } from '../../container'

const CustomerIdTemplate = ({
    accountID,
    accountIDMismatchError,
}: any) => {
    return (
        <>
            <div className={`${accountIDMismatchError ? 'p-error' : null}`}>
                {accountID}
            </div>
        </>
    )
}
function MoreCardResults() {
    const cellCommonStyle = { fontSize: '14px', fontWeight: 'normal' }
    const headerStyle = {
        fontSize: '14px',
        fontWeight: 'bold',
        itemAlign: 'center',
    }

    const { moreCardDetails } = SearchContainer.useContainer()

    return (
        <div className="mt-4 w-full">
            {
                Array.isArray(moreCardDetails) && moreCardDetails.length > 0 
                ? <DataTable value={moreCardDetails}>
                    <PrimeColumn
                        field="firstName"
                        style={cellCommonStyle}
                        header="First Name"
                        headerStyle={{ ...headerStyle, width: '60px' }}
                    />
                    <PrimeColumn
                        field="lastName"
                        style={cellCommonStyle}
                        header="Last Name"
                        headerStyle={{ ...headerStyle, width: '40px' }}
                    />
                    <PrimeColumn
                        field="email"
                        style={cellCommonStyle}
                        header="Email ID"
                        headerStyle={{ ...headerStyle, width: '80px' }}
                    />

                    <PrimeColumn
                        field="accountID"
                        body={CustomerIdTemplate}
                        style={cellCommonStyle}
                        header="Customer ID"
                        headerStyle={{ ...headerStyle, width: '40px' }}
                    />

                    <PrimeColumn
                        field="address1"
                        style={cellCommonStyle}
                        header="Address Line1"
                        headerStyle={{ ...headerStyle, width: '60px' }}
                    />
                    <PrimeColumn
                        field="address2"
                        style={cellCommonStyle}
                        header="Address Line2"
                        headerStyle={{ ...headerStyle, width: '60px' }}
                    />

                    <PrimeColumn
                        field="postCode"
                        style={cellCommonStyle}
                        header="Post code"
                        headerStyle={{ ...headerStyle, width: '60px' }}
                    />
                    <PrimeColumn
                        field="digitalFlag"
                        style={cellCommonStyle}
                        header="Digital Flag"
                        headerStyle={{ ...headerStyle, width: '60px' }}
                    />

                </DataTable> : <div className=''>No results available</div>
            }
        </div>
    )
}

export default MoreCardResults
