import React from 'react'

interface CurrencyProps {
  amount: string
}

function Currency({ amount }: CurrencyProps) {
  return amount && amount !== '' ? <span>£ {amount}</span> : <span>-</span>
}

export default Currency
