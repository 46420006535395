import React from 'react'

interface IconProps {
  MIcon: any
  size?: number
  color?: string
  onClick?: any
  disable?:boolean
}

interface iStyle {
  color?: string
  fontSize?: number
}

function Icon({ MIcon, color, size, onClick, disable }: IconProps) {
  const style: iStyle = { color: color }
  if (size) style.fontSize = size
  return (
    <i
      className={`flex items-center justify-items-end ${disable === true ? '' : "cursor-pointer "}`}
      onClick={onClick}
    >
      <MIcon style={style} />
    </i>
  )
}

export default Icon
