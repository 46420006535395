import React from 'react'
import { useParams } from 'react-router-dom'
import { CardContainer } from '../../container'
import LinkMoreCard from './LinkMoreCard'
import { SpinLoaderColleague } from '../../component/common'
const LinkMoreCardIndex = () => {
  let { empId } = useParams<{ empId: string }>()
  return (
    <CardContainer.Provider>
      <SpinLoaderColleague />
      <LinkMoreCard empId={empId} />
    </CardContainer.Provider>
  )
}

export default LinkMoreCardIndex
