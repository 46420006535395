import React, { useEffect } from 'react'
import { GroupContainer } from '../../container'

interface placeholderProps {
  defaultValue: string
}

function InputText({ defaultValue }: placeholderProps) {
  const { updateAllowance } = GroupContainer.useContainer()

  const handleChange = (event: any) => {
    updateAllowance(event.target.value)
  }

  useEffect(() => {
    if (defaultValue) updateAllowance(defaultValue)
    // eslint-disable-next-line
  }, [defaultValue])
  return (
    <div>
      <input
        type="number"
        defaultValue={defaultValue}
        onChange={handleChange}
        className="ml-1 border border-white-600 rounded-xl"
      />
    </div>
  )
}
export default InputText
