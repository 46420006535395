import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import { DiscountCardValidationSuccess, InputField } from './'
import Recaptcha from '../common/google-reCAPTCHA/Recaptcha'

const DiscountCardDetails: React.FC<any> = ({
  status,
  errors,
  lastName,
  firstName,
  discountCardNumber,
  handleLastNameChange,
  handleFirstNameChange,
  handleDiscountCardNumberChange,
  validateColleagueDiscount,
  validateFields,
}) => {
  const {
    lastName: lastNameError,
    firstName: firstNameError,
    discountCardNumber: discountCardNumberError,
  } = errors

  const [isCaptchaValidate, setIsCaptchaValidate] = useState(false)
  const disableValidate = () => {
    return !firstName || !lastName || !discountCardNumber || !isCaptchaValidate
  }

  function captchaValidate(value: any) {
    setIsCaptchaValidate(value ? true : false)
  }

  return (
    <>
      <div className="flex flex-col items-center lg:flex-row lg:items-center">
        <div className="w-full sm:pr-20 sm:h-28">
          <InputField
            value={discountCardNumber}
            label="Discount Card Number"
            onChange={(discountCardNumber) => {
              handleDiscountCardNumberChange(discountCardNumber)
            }}
            placeholder="Enter Discount Card Number*"
            errorMessage={discountCardNumberError}
          />
        </div>
        <div className="w-full sm:pr-20 sm:h-28 ">
          <InputField
            value={firstName}
            label="First Name"
            onChange={(firstName) => {
              handleFirstNameChange(firstName)
            }}
            placeholder="Enter First Name*"
            errorMessage={firstNameError}
          />
        </div>
        <div className="w-full sm:pr-20 sm:h-28">
          <InputField
            value={lastName}
            label="Last Name"
            onChange={(lastName) => {
              handleLastNameChange(lastName)
            }}
            placeholder="Enter Last Name*"
            errorMessage={lastNameError}
          />
        </div>
      </div>

      <div className="flex justify-center mt-10 mb-5 sm:mt-1">
        <Recaptcha onChange={captchaValidate} />
      </div>

      <div className="flex justify-center mt-10 sm:mt-1">
        <Button
          size="large"
          color="primary"
          variant="contained"
          className="px-10"
          disabled={disableValidate()}
          onClick={() => {
            validateColleagueDiscount()
          }}
        >
          {status ? 'Validated' : 'Validate'}
        </Button>
      </div>
      {status && <DiscountCardValidationSuccess />}
    </>
  )
}

export default DiscountCardDetails
