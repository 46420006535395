import React from 'react'
import { CardContainer } from '../../../container'
import { DataTable } from 'primereact/datatable'
import { PrimeColumn, ContentLoader } from '../../common'
import {
    TemplatedAllowanceDisable,
    TemplateAmountRemainingDisable,
    TemplateAmountUsedDisable,
} from './CurrencyTemplate'
import { EditTemplate, } from './CardTemplate'
import { Tooltip } from 'primereact/tooltip'
import { Icon } from '../../common'
import CancelledCards from '../cancelledCards/CancelledCards'
import consoleLog from '../../../util/consoleLog'
import { formatCard } from '../../../util/format'
import DialogApiError from '../../common/dialogApiError/ApiDialog'
import InfoIcon from '@material-ui/icons/Info'
import { brandName } from '../../../util/brandConfig'

const ShowDisableValue = ({ value }: any) => {
    return <div className='text-gray-400 flex text-sm' >
        {value}
    </div>
}

const TemplateName = ({ cardNumber, cardHolderName }: any) => {
    return cardNumber? <ShowDisableValue value={cardHolderName} />:  '-'
}

const TemplateCardNumber = ({ cardNumber, moreCardNumber, cardId }: any) => {
    return cardNumber?<div style={{ display: 'flex', flexDirection: 'row' }}>

        <ShowDisableValue value={cardNumber} />
        {moreCardNumber && (
            <div className="p-d-flex p-ai-center">
                <Tooltip target={`.card${cardId}`}>
                    <p className="text-sm">{`${brandName} card number`}</p>
                    <p className="text-sm"> {formatCard(moreCardNumber)}</p>
                </Tooltip>
                <div className={`card${cardId} -mt-1`}>
                    <Icon MIcon={InfoIcon} color="#616161" />
                </div>
            </div>
        )}
    </div>: '-'
}

const TemplateCardRefreshDate = ({cardNumber,  refreshDate }: any) => {
    return cardNumber? <ShowDisableValue value={refreshDate} />: '-'
}

const TemplateCardStatus = ({cardNumber}: any) => {
    return cardNumber ? <div className='capitalize'><ShowDisableValue value={'Disabled'} /></div> : '-'
}


function CardTablesDisable() {

    const { cardDetailsAction, loading, showDialogApiCardError } = CardContainer.useContainer()
    consoleLog('CardTables', '22', 'cardDetailsAction : ', cardDetailsAction)
    const headerStyle = { fontWeight: 'bold', fontSize: '0.875rem' }
    const cellCommonStyle = { fontWeight: 'bold' }
    return loading ? (
        <>
            {showDialogApiCardError && <DialogApiError />}
            <div className="cardTable" style={{ margin: '0 -24px' }}>
                {Array.isArray(cardDetailsAction) && (
                    <DataTable value={cardDetailsAction}>
                        <PrimeColumn
                            field="cardNumber"
                            body={TemplateCardNumber}
                            style={cellCommonStyle}
                            headerStyle={{ ...headerStyle, width: '210px' }}
                            header="Card Number"
                        />
                        <PrimeColumn
                            field="cardholderName"
                            body={TemplateName}
                            style={cellCommonStyle}
                            headerStyle={{ ...headerStyle, width: '180px' }}
                            header="Cardholder Name"
                        />
                        <PrimeColumn
                            field="cardAllowance"
                            body={TemplatedAllowanceDisable}
                            style={cellCommonStyle}
                            headerStyle={{ ...headerStyle, width: '110px' }}
                            header="Card Allowance"
                        />
                        <PrimeColumn
                            field="refreshDate"
                            style={cellCommonStyle}
                            body={TemplateCardRefreshDate}
                            headerStyle={{ ...headerStyle, width: '130px' }}
                            header=" Card Allowance Refresh Date"
                        />
                        <PrimeColumn
                            field="amountRemaining"
                            body={TemplateAmountRemainingDisable}
                            style={cellCommonStyle}
                            headerStyle={{ ...headerStyle, width: '110px' }}
                            header="Discount Remaining"
                        />
                        <PrimeColumn
                            field="amountUsed"
                            body={TemplateAmountUsedDisable}
                            style={cellCommonStyle}
                            headerStyle={{ ...headerStyle, width: '110px' }}
                            header="Discount Received"
                        />
                        <PrimeColumn
                            field="status"
                            body={TemplateCardStatus}
                            style={cellCommonStyle}
                            headerStyle={{ ...headerStyle, width: '90px' }}
                            header="Status"
                        />
                        <PrimeColumn
                            field="Action"
                            style={cellCommonStyle}
                            body={EditTemplate}
                            headerStyle={{ ...headerStyle, width: '60px' }}
                            header="Action"
                        />
                    </DataTable>
                )}

                <CancelledCards />
            </div>
        </>
    ) : (
            <ContentLoader />

        )
}
export default CardTablesDisable
