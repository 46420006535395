import React from 'react'
import EditIcon from '@material-ui/icons/Edit'
import SaveIcon from '@material-ui/icons/Save'
import { Currency, Icon } from '../common'
import { primary } from '../../materialTheme/colors'
import { GroupContainer } from '../../container'
import InputText from './InputText'

export const CurrencyTemplate = ({ groupAllowance, action }: any) => {
  return action === 'edit' ? (
    <div className="flex">
      <>
        £ <InputText defaultValue={groupAllowance} />
      </>
    </div>
  ) : groupAllowance ? (
    <Currency amount={groupAllowance} />
  ) : (
    <span className="text-center w-full">-</span>
  )
}
export const ActionIcons = ({ groupName, action }: any) => {
  const {
    updateAction,
    allowance,
    updateGroupAllowance,
  } = GroupContainer.useContainer()

  const handleEditClick = () => {
    updateAction(groupName, 'edit')
  }

  const handleSaveClick = () => {
    updateGroupAllowance(allowance, groupName)
    updateAction(groupName, 'save')
  }
  return action === 'save' ? (
    <Icon MIcon={EditIcon} color={primary.primary} onClick={handleEditClick} />
  ) : (
    <Icon MIcon={SaveIcon} color={primary.primary} onClick={handleSaveClick} />
  )
}
export const EditTemplate = ({ groupName, action }: any) => {
  return groupName.toLowerCase() === 'colleague' ? (
    ''
  ) : (
    <ActionIcons groupName={groupName} action={action} />
  )
}
