import React, { useState, useEffect } from 'react'

import InputLabel from '@material-ui/core/InputLabel'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

import { SearchContainer } from '../../container'
import { isEmail } from '../../util/validation'
import DialogApiErrorSearchContainer from '../common/dialogApiError/ApiDialogSearchContainer'
import ResultTable from './Result'


function SearchByEmailId() {
    const [email, setEmail] = useState('')
    const [error, setError] = useState(false)
    const [clicked, setClicked] = useState(false)

    const {
        moreCardByEmail,
        callGetMoreCardByEmail,
        showDialogApiCardError,
        clearEmailSearch
    } = SearchContainer.useContainer()

    useEffect(() => {
        if (clearEmailSearch) {
            setEmail('')
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clearEmailSearch])

    async function searchMoreCardByEmail() {

        if (isEmail(email)) {
            await callGetMoreCardByEmail({ email })
            setError(false)
        }
        else setError(true)
        setClicked(true)
    }

    useEffect(() => {
        if (email === '') {
            setError(false)
        }
    }, [email])

    return (
        <div className="w-full mob:w-full">
            {showDialogApiCardError && <DialogApiErrorSearchContainer />}
            <section className="m-4">
                <div className="flex-col justify-start ml-2">
                    <div className="text-md text-gray-400 mb-4">
                        Please enter the email id below without spaces.
            </div>
                    <div className="lg:h-28 w-full ">
                        <InputLabel className="text-md font-bold">
                            Enter Email ID
            </InputLabel>
                        <div className="flex-row">
                            <TextField
                                size="small"
                                className="my-4 sm:mr-8 w-3/12 mob:w-full"
                                variant="outlined"
                                 value={email}
                                helperText={error ? 'Please enter a valid email' : ''}
                                onChange={(e) => {
                                    setEmail(e.target.value)
                                }}
                                placeholder="Enter Email ID"
                            />

                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                disabled={!email}
                                className="w-36 h-12 mt-3 mob:w-6/12 focus:outline-none"
                                onClick={() => {
                                    searchMoreCardByEmail()
                                }}
                            >
                                <span className=" text-white text-transform: capitalize text-lg">Search</span>
                            </Button>
                        </div>
                    </div>
                </div>
                {clicked && (!moreCardByEmail || (Array.isArray(moreCardByEmail) && moreCardByEmail.length === 1 && !moreCardByEmail[0].moreCardNumber)) && isEmail(email) ? (
                    <div className="w-full flex h-14 justify-center items-center text-red-600 text-lg">
                        <span>No Result found!</span>
                    </div>
                ) : (Array.isArray(moreCardByEmail) && moreCardByEmail.length > 0) && <ResultTable searchResult={moreCardByEmail} />}
            </section>
        </div>
    )
}
export default SearchByEmailId
