import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Button } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { AppContainer } from '../../container'
import consoleLog from '../../util/consoleLog'

const PTMain = () => {
  const history = useHistory()
  const [email, setEmail] = useState<any>('')
  const [count, setCount] = useState<any>('')
  const [password, setPassword] = useState<any>('')
  const [emailTemplate, setEmailTemplate] = useState<any>('testuser10000')
  const [userListArray, setUserListArray] = useState<any>([])

  const userList: any = []
  const {
    googleSignWithEmail,
    ptLogin,
    googleCreateUserWithEmail,
  } = AppContainer.useContainer()

  const generateUser = async () => {
    for (let i = 1; i <= Number(count); i++) {
      const autoEmail = emailTemplate + i + '@morrisonsplc.co.uk'
      const autoPassword = 'Network123'
      await googleCreateUserWithEmail(autoEmail, autoPassword)
      userList.push({ email: autoEmail, password: autoPassword })
    }
    consoleLog('Main', '31', 'userList : ', userList)
    setUserListArray(userList)
  }

  useEffect(() => {
    if (ptLogin === true) {
      history.push('/home')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ptLogin])

  return (
    <div className="w-full  items-center justify-center h-full">
      <div className="w-full flex items-center justify-center">
        <div className="w-1/2 mt-20">
          <div className="w-full ">
            <div>Email</div>
            <input
              type="text"
              name="email"
              value={email}
              onChange={(event: any) => {
                setEmail(event.target.value)
              }}
              className="h-12 border-gray-500 border-2"
            />
          </div>
          <div className="w-full mt-4">
            <div>Password</div>
            <input
              type="password"
              name="password"
              value={password}
              onChange={(event: any) => {
                setPassword(event.target.value)
              }}
              className="h-12 border-gray-500 border-2"
            />
          </div>
          <Button
            onClick={(event: any) => {
              googleSignWithEmail(email, password)
            }}
            className="h-12 w-48 bg-green-800 mt-4"
          >
            Login
          </Button>

          <Link to="/home" className="ml-4">
            Home
          </Link>
        </div>
      </div>

      <div className="w-full flex items-center justify-center">
        <div className="w-1/2 mt-20">
          <div className="w-full ">
            <div>Email Start</div>
            <input
              type="text"
              name="emailTemplate"
              value={emailTemplate}
              onChange={(event: any) => {
                setEmailTemplate(event.target.value)
              }}
              className="h-12 border-gray-500 border-2"
            />
          </div>
          <div className="w-full ">
            <div>count</div>
            <input
              type="text"
              name="count"
              value={count}
              onChange={(event: any) => {
                setCount(event.target.value)
              }}
              className="h-12 border-gray-500 border-2"
            />
          </div>
          <Button
            onClick={(event: any) => {
              generateUser()
            }}
            className="h-12 w-48 bg-green-800 mt-4 ml-4"
          >
            Create
          </Button>
          <div>{JSON.stringify(userListArray)}</div>
        </div>
      </div>
    </div>
  )
}

export default PTMain
