import React from 'react'
import { isCard } from '../../util/validation'
import { SearchContainer } from '../../container'

function TroubleShootAccountLookUp() {
    const { troubleshootFullResult } = SearchContainer.useContainer()
    const accountLookUp = troubleshootFullResult ? troubleshootFullResult.accountLookUp : null

    return (
        accountLookUp ? <div className="mt-4 w-full">
            {(Array.isArray(accountLookUp) && accountLookUp.length) > 0 ?
                <>
                    {
                        (accountLookUp || []).length > 1 && <div className=''>Warning : There are two or more records for this card number</div>
                    }
                    {
                        (accountLookUp || []).map((card: any, index: any) => {
                            return <div className=''>
                                {isCard(card.accountID) ?
                                    <span>The card number {card.cardNumber} is linked with customer id / card number {card.accountID}</span>
                                    : <span>The card number {card.cardNumber} is linked with customer id {card.accountID}</span>
                                }

                            </div>
                        })
                    }
                </>
                : <span>No results available</span>
            }
        </div> : null
    )
}

export default TroubleShootAccountLookUp
