import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { CardContainer } from '../../../container'
import consoleLog from '../../../util/consoleLog'

function SpinLoaderColleague() {
  const { spinCardLoading } = CardContainer.useContainer()
  consoleLog('SpinLoaderColleague','8','spinCardLoading : ', spinCardLoading)
  return spinCardLoading ? (
    <div
      className="fixed top-0 bottom-0 right-0 left-0 bg-gray-300  opacity-60"
      style={{ zIndex: 1350 }}
    >
      <div className="w-full h-full  flex justify-center items-center">
        <CircularProgress />
      </div>
    </div>
  ) : null
}

export default SpinLoaderColleague
