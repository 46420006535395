import React from 'react'
import CardDetails from '../../component/card/CardDetails'
import ColleagueDetails from '../../component/card/ColleagueDetails'
import { SpinLoaderColleague } from '../../component/common'

function Main() {
  return (
    <>
      <SpinLoaderColleague />
      <ColleagueDetails />
      <CardDetails />
    </>
  )
}

export default Main
