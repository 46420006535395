import React from 'react'
import AdminNavigation from '../../../component/adminHome/AdminNavigation'
import { Container } from '../../../component/common'
import MainSearch from '../../../component/adminHome/MainSearch'
import { AppContainer } from '../../../container'
import consoleLog from '../../../util/consoleLog'

function Home() {
  // eslint-disable-next-line
  const { role } = AppContainer.useContainer()
  consoleLog('Home','11',role)
  return (
    <Container>
      {role === 'ADMIN' ? <AdminNavigation /> : <div className="h-32"></div>}
      <MainSearch />
    </Container>
  )
}

export default Home
