import { createMuiTheme } from '@material-ui/core/styles'
import { primary, tertiary,white, black } from './colors'

const theme = createMuiTheme({
  typography: {
    fontFamily:
      '"Morrisons-Agenda, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
  },
  palette: {
    type: 'light',
    background: {
      paper: white.white,
      default: white.white,
    },
    common: {
      black: black.black,
      white: white.white,
    },
    text: {
      primary: black.black,
      secondary: black.black,
      disabled: black.black50,
      hint: black.black,
    },
    primary: {
      main: primary.primary,
      dark: primary.dark,
      light: primary.light,
      contrastText: white.white,
    },
    secondary: {
      main: tertiary.dark,
      dark: tertiary.dark,
      light: tertiary.light,
      contrastText: primary.dark,
    }
  },
})

  
  export default theme