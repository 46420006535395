import React from 'react'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import theme from '../materialTheme/theme'
import 'primereact/resources/themes/saga-blue/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'
import AuthenticatedApp from './AuthenticatedApp'
import UnAuthenticatedApp from './UnAuthenticatedApp'
import '../util'
import { AppContainer } from '../container/index'

function App() {
  const path = window.location.pathname
  return (
    <MuiThemeProvider theme={createMuiTheme(theme)}>
      <div className="App">
        {path === '/extendedfamily' ? (
          <UnAuthenticatedApp />
        ) : (
          <AppContainer.Provider>
            <AuthenticatedApp />
          </AppContainer.Provider>
        )}
      </div>
    </MuiThemeProvider>
  )
}

export default App
