import React from 'react'
import EditIcon from '@material-ui/icons/Edit'
import SaveIcon from '@material-ui/icons/Save'
import { Icon } from '../common'
import { primary, disable } from '../../materialTheme/colors'
import { GroupContainer } from '../../container'

export const CellTemplate = ({ status, text }: any) => {
  return (
    <div
      className={`${
        status.toLowerCase() === 'active' ? '' : 'text-gray-400'
      } flex`}
    >
      {text}
    </div>
  )
}

export const StatueTemplate = ({ status }: any) => (
  <CellTemplate text={status} status={status} />
)

export const GroupNameTemplate = ({ groupName, status }: any) => (
  <CellTemplate text={groupName} status={status} />
)

export const GroupIDTemplate = ({ groupId, status }: any) => (
  <CellTemplate text={groupId} status={status} />
)

export const ClubNameTemplate = ({ clubName, status }: any) => (
  <CellTemplate text={clubName} status={status} />
)

export const ClubIdTemplate = ({ clubId, status }: any) => (
  <CellTemplate text={clubId} status={status} />
)

export const StartDateTemplate = ({ startDate, status }: any) => (
  <CellTemplate text={startDate} status={status} />
)

export const EndDateTemplate = ({ endDate, status }: any) => (
  <CellTemplate text={endDate} status={status} />
)

export const ActionIcons = ({ groupName, action, status }: any) => {
  const {
    updateAction,
    groupStatus,
    updateGroupStatus,
  } = GroupContainer.useContainer()

  const handleEditClick = () => {
    updateAction(groupName, 'edit')
  }

  const handleSaveClick = () => {
    updateAction(groupName, 'save')
    updateGroupStatus(groupStatus, groupName)
  }

  return status.toLowerCase() === 'processing' ? (
    <Icon MIcon={EditIcon} color={disable.primary} />
  ) : action === 'save' ? (
    <Icon MIcon={EditIcon} color={primary.primary} onClick={handleEditClick} />
  ) : (
    <Icon MIcon={SaveIcon} color={primary.primary} onClick={handleSaveClick} />
  )
}

export const EditTemplate = ({ groupName, action, status }: any) => {
  return <ActionIcons groupName={groupName} action={action} status={status} />
}
