import React from 'react'
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel'
import { FieldErrors } from './FieldErrors'

interface InputFieldProps {
  type?: string
  value: string
  label: string
  onChange(value: string): void
  errorMessage?: string
  className?: string
  placeholder?: string
}

const InputField: React.FC<InputFieldProps> = ({
  type = 'text',
  value,
  label,
  onChange,
  errorMessage = '',
  className = '',
  placeholder = '',
}) => {
  return (
    <div className={className}>
      <InputLabel className="mb-2 text-xs">{label}</InputLabel>
      <TextField
        size="small"
        className="mb-4 w-full sm:mr-8"
        variant="outlined"
        type={type}
        value={value}
        placeholder={placeholder}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          onChange(e.target.value)
        }}
      />
      <FieldErrors message={errorMessage} show={!!errorMessage} />
    </div>
  )
}

export default InputField
