import React from 'react'
import { DataTable } from 'primereact/datatable'
import { PrimeColumn } from '../common'
import { brandName } from '../../util/brandConfig'

interface SearchResultProps {
  searchResult: any
}

function ResultTable ({searchResult}:SearchResultProps){
    const headerStyle = {
    fontSize: '14px',
    fontWeight: 'bold',
    itemAlign: 'center',
  }

  const cellCommonStyle = { fontSize: '14px', fontWeight: 'normal' }
return(
<div className="w-full ml-2">
     <h1>Result</h1>
     <DataTable value={searchResult}>
            <PrimeColumn
              field="firstName"
              style={cellCommonStyle}
              header="First Name"
              headerStyle={{ ...headerStyle, width: '50px' }}
            />
            <PrimeColumn
              field="lastName"
              style={cellCommonStyle}
              header="Last Name"
              headerStyle={{ ...headerStyle, width: '50px' }}
            />
            <PrimeColumn
              field="moreCardNumber"
              style={cellCommonStyle}
              header={`${brandName} Card No`}
              headerStyle={{ ...headerStyle, width: '50px' }}
            />
            <PrimeColumn
              field="address1"
              style={cellCommonStyle}
              header="Address Line 1"
              headerStyle={{ ...headerStyle, width: '50px' }}
            />
            <PrimeColumn
              field="address2"
              style={cellCommonStyle}
              header="Address Line 2"
              headerStyle={{ ...headerStyle, width: '50px' }}
            />
            <PrimeColumn
              field="postCode"
              style={cellCommonStyle}
              header="Post Code"
              headerStyle={{ ...headerStyle, width: '50px' }}
            />
            <PrimeColumn
              field="digitalFlag"
              style={cellCommonStyle}
              header="Digital Flag"
              headerStyle={{ ...headerStyle, width: '50px' }}
            />
          </DataTable>
</div>

)}
export default ResultTable