import React, { useState, useEffect } from 'react'

import InputLabel from '@material-ui/core/InputLabel'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { SearchContainer } from '../../container'
import { brandName } from '../../util/brandConfig'

function TroubleShootCardSearch() {
    const [moreCardNumber, setMoreCardNumber] = useState('')
    //   const [clickButton, setClickButton] = useState(false)

    const {
        callGetMoreCardBFF,
        moreCardResponse,
        clearMoreCardSearch,
    } = SearchContainer.useContainer()

    useEffect(() => {
        if (clearMoreCardSearch) {
            setMoreCardNumber('')
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clearMoreCardSearch])

    const isValidMoreCard = (cardNumber: string) => {
        if (!cardNumber) return true
        const moreCardValidation = /^[0-9]{19}$/
        return moreCardValidation.test(cardNumber)
    }

    async function searchMoreCard() {
        await callGetMoreCardBFF({ moreCardNumber })
        // setClickButton(true)
    }

    const disableSubmit = !moreCardNumber || !isValidMoreCard(moreCardNumber)

    return (
        <div className="w-full mob:w-full bg-gray-100">
            <section className="m-4">
                <div className="flex-col justify-start">
                    <div className="text-md text-gray-400 mb-4">
                        {`Please enter the ${brandName} / Colleague discount card number below without spaces.`}
            </div>
                    <div className="lg:h-28 w-full">
                        <InputLabel className="text-md font-bold">
                            Enter Card Number
            </InputLabel>

                        <div className="flex">
                            <div>
                                <TextField
                                    size="small"
                                    className="my-4 sm:mr-28 w-full mob:w-full"
                                    variant="outlined"
                                    error={
                                        !isValidMoreCard(moreCardNumber) ||
                                        (moreCardResponse && !moreCardResponse.statusFlag)
                                    }
                                    value={moreCardNumber}
                                    onChange={(e) => {
                                        setMoreCardNumber(e.target.value)
                                    }}
                                    placeholder={`Enter ${brandName} Card Number`}
                                />
                                {!isValidMoreCard(moreCardNumber) && (
                                    <div className="text-xs text-error -mt-3 mb-3 sm:mb-0">
                                        This Card Number is incorrect, please try again.
                                    </div>
                                )}

                                {/* {clickButton === true &&
              isValidMoreCard(moreCardNumber) &&
              (moreCardResponse && moreCardResponse.statusFlag ? null : (
                <div className="text-xs text-error  -mt-3 mb-3 sm:mb-0">
                  {`${brandName} Card Number is invalid!`}
                </div>
              ))} */}
                            </div>
                            <div>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    disabled={disableSubmit}
                                    className="w-36 h-12 mt-3 ml-8 mob:w-6/12 focus:outline-none"
                                    onClick={() => {
                                        searchMoreCard()
                                    }}
                                >
                                    <span className=" text-white text-transform: capitalize text-lg">Search</span>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
export default TroubleShootCardSearch
