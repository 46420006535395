import React from 'react'
import Button from '@material-ui/core/Button'

import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles'

import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import { brandName } from '../../../util/brandConfig'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  })

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string
  children: React.ReactNode
  onClose: () => void
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles((theme: Theme) => ({
  root: {},
}))(MuiDialogContent)

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

function CancelDialogFAndF({
  handleCancelCards,
  openDialog,
  cardNumber,
  setOpenDialog,
}: any) {
  const handleClose = () => {
    setOpenDialog(false)
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={openDialog}
      className="mob:h-1/2 mob:w-full"
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        <span className="text-xl capitalize">Cancel / Order New Card</span>
      </DialogTitle>
      <DialogContent>
        <div className="flex:wrap  items-center h-full mob:h-16">
          <div className="w-full mob:text-justify">
            Please confirm do you want to delete your Family & Friend's card{' '}
            {cardNumber}?
            <br />
            <br />
            <div>
              {`Please remember to let the customer know that you have cancelled
              their membership to the discount club and that they will be issued
              a ${brandName} card.`}
            </div>
          </div>
        </div>
      </DialogContent>

      <DialogActions>
        <div className="mob:flex mob:w-8/12 mob:justify-center mob:m-auto">
          <Button
            autoFocus
            onClick={handleCancelCards}
            color="primary"
            className="focus:outline-none mb-4 mob:w-11/12"
            variant="contained"
          >
            Confirm
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  )
}

export default CancelDialogFAndF
