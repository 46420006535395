import React, { useState, useEffect } from 'react'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { GroupContainer } from '../../container'

const NonColleagueSearchOption = ({ onChange }: any) => {
  // eslint-disable-next-line
  const [defaultValue, setDefaultValue] = useState('MISCELLENOUS')
  const { groupDetails } = GroupContainer.useContainer()

  useEffect(() => {
    if (groupDetails && groupDetails.length > 0) {
      setDefaultValue(groupDetails[0].groupName)
    }
  }, [groupDetails])
  useEffect(() => {
    onChange(defaultValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue])

  return (
    <div className="mob-w-full">
      <Select
        value={defaultValue}
        className="h-14 w-48 pl-4 mr-4 focus:outline-none border rounded border-gray-300 mob:w-full"
        onChange={(event: any) => {
          setDefaultValue(event.target.value)
        }}
      >
        {(groupDetails || []).map((group: any, index: any) => {
          if (
            group.groupName.toLowerCase() !==
              'miscellaneous-family and friends' &&
            group.groupName.toLowerCase() !== 'morrisons colleagues' &&
            group.groupName.toLowerCase() !== 'morrisons family and friends'
          )
            return (
              <MenuItem key={index} value={group.groupName} className="h-14">
                {group.groupName}
              </MenuItem>
            )
          return null
        })}
      </Select>
    </div>
  )
}
export default NonColleagueSearchOption
