import React from 'react'
import { useParams } from 'react-router-dom'
import { Container, SpinLoaderColleague } from '../../../component/common'
import ColleagueDetails from '../../../component/card/ColleagueDetails'
import CardDetails from '../../../component/card/CardDetails'
import { CardContainer } from '../../../container'

function AdminColleagueDetails() {
  let { empId } = useParams<{ empId: string }>()

  return (
    <CardContainer.Provider>
      <Container>
        <SpinLoaderColleague />
        <ColleagueDetails empId={empId} />
        <CardDetails empId={empId} />
      </Container>
    </CardContainer.Provider>
  )
}
export default AdminColleagueDetails
