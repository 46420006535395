import React from 'react'
import { CardContainer } from '../../../container'
import { DataTable } from 'primereact/datatable'
import { PrimeColumn, ContentLoader } from '../../common'
import {
  CurrencyTemplateCardAllowance,
  CurrencyTemplateAmountRemaining,
  CurrencyTemplateAmountUsed,
} from './CurrencyTemplate'
import { EditTemplate, CardRefreshDateTemple } from './CardTemplate'
import CardStatusTemplate from './CardStatusTemplate'
import {
  EditCardHolderNameTemplate,
  EditCardNumberTemplate,
} from './CardHolderTemplate'

import CancelledCards from '../cancelledCards/CancelledCards'
import consoleLog from '../../../util/consoleLog'

import DialogApiError from '../../common/dialogApiError/ApiDialog'

function CardTables() {
    
  const { cardDetailsAction, loading ,showDialogApiCardError} = CardContainer.useContainer()
  consoleLog('CardTables', '22', 'cardDetailsAction : ', cardDetailsAction)
  const headerStyle = { fontWeight: 'bold', fontSize: '0.875rem' }
  const cellCommonStyle = { fontWeight: 'bold' }
  return loading ? (
      <>
      {showDialogApiCardError && <DialogApiError/>}
       <div className="cardTable" style={{ margin: '0 -24px' }}>
      {Array.isArray(cardDetailsAction) && (
        <DataTable value={cardDetailsAction}>
          <PrimeColumn
            field="cardNumber"
            body={EditCardNumberTemplate}
            style={cellCommonStyle}
            headerStyle={{ ...headerStyle, width: '210px' }}
            header="Card Number"
          />
          <PrimeColumn
            field="cardholderName"
            body={EditCardHolderNameTemplate}
            style={cellCommonStyle}
            headerStyle={{ ...headerStyle, width: '180px' }}
            header="Cardholder Name"
          />
          <PrimeColumn
            field="cardAllowance"
            body={CurrencyTemplateCardAllowance}
            style={cellCommonStyle}
            headerStyle={{ ...headerStyle, width: '110px' }}
            header="Card Allowance"
          />
          <PrimeColumn
            field="refreshDate"
            style={cellCommonStyle}
            body={CardRefreshDateTemple}
            headerStyle={{ ...headerStyle, width: '130px' }}
            header=" Card Allowance Refresh Date"
          />
          <PrimeColumn
            field="amountRemaining"
            body={CurrencyTemplateAmountRemaining}
            style={cellCommonStyle}
            headerStyle={{ ...headerStyle, width: '110px' }}
            header="Discount Remaining"
          />
          <PrimeColumn
            field="amountUsed"
            body={CurrencyTemplateAmountUsed}
            style={cellCommonStyle}
            headerStyle={{ ...headerStyle, width: '110px' }}
            header="Discount Received"
          />
          <PrimeColumn
            field="status"
            body={CardStatusTemplate}
            style={cellCommonStyle}
            headerStyle={{ ...headerStyle, width: '90px' }}
            header="Status"
          />
          <PrimeColumn
            field="Action"
            style={cellCommonStyle}
            body={EditTemplate}
            headerStyle={{ ...headerStyle, width: '60px' }}
            header="Action"
          />
        </DataTable>
      )}

      <CancelledCards />
    </div>
    </>
  ) : (
    <ContentLoader />
     
  )
}
export default CardTables
