import React from 'react'
import { Currency } from '../../common'
import { CardContainer } from '../../../container'

const TransactionListMob = () => {
  const { cardTransactionDetails } = CardContainer.useContainer()
  const cardTransactions = cardTransactionDetails?.cardTransaction
  
  return cardTransactionDetails ? (
    <div className="h-32">
      <div className="mob:text-sm">
        You can view last {(cardTransactions || []).length} transactions for
        colleague card
      </div>
      <div className="mt-4 mob:w-full mob:flex-col">
        <div className="flex mt-2 mob:w-full text-gray-600 mob:text-sm">
          <div className="text-left">Cardholder Name</div>
          <div className="mob:px-1.5">-</div>
          <div className="text-left">Card No.</div>
        </div>
        <div className="flex w-full mt-2 font-bold mob:text-sm">
          <div className="text-left">
            {cardTransactionDetails.cardHolderName}
          </div>
          <div className="mob:px-1.5">-</div>
          <div className="text-left">{cardTransactionDetails.cardNumber}</div>
        </div>
      </div>

      <div className="mt-6 flex mob:w-full mob:text-sm transactionTable border-b-2 border-gray-200">
        <div className="w-2/5">Date of Shopping</div>
        <div className="w-2/5">Total Shopping Amount</div>
        <div className="w-1/5">Discount Received</div>
      </div>

      {(cardTransactions || []).map((data: any, index: number) => (
        <div
          className="mob:w-full mob:text-sm py-4 border-b border-gray-200"
          key={index}
        >
          <div className="flex mob:w-full">
            <div className="w-2/5 font-bold">{data.dateShoped} </div>
            <div className="w-2/5 font-bold">
              <Currency amount={data.totalAmount} />{' '}
            </div>
            <div className="w-1/5 font-bold">
              <Currency amount={data.discountReceived} />
            </div>
          </div>
          <div className="mob:w-full mt-4">
            <span className="font-bold  mob-text-sm">{data.storeName}, </span>{' '}
            <span>{data.location}</span>
          </div>
        </div>
      ))}
      <div className="mob:w-fullborder-b border-gray-200"></div>
    </div>
  ) : (
    <div>No transaction found !</div>
  )
}

export default TransactionListMob
