import React from 'react'
import { AppContainer } from '../../container'
import { Container } from '../common'
import UserHeaderInfo from './UserHeaderInfo'
import HamburgerMenu from './HamburgerMenu'

function Header() {
  const { userInfo, role, logout } = AppContainer.useContainer()

  return (
    <header className="flex text-center bg-primary h-20 w-full text-white text-2xl justify-left items-center">
      <Container>
        <div className="flex w-full justify-between">
          <div className="flex ">
            {(role === 'ADMIN' ||
              role === 'STOREADMIN' ||
              role === 'LPADMIN') && <HamburgerMenu role={role} />}
            {userInfo && (
              <UserHeaderInfo
                imgSrc={userInfo.imgSrc}
                firstName={userInfo.firstName}
                lastName={userInfo.lastName}
                displayName={userInfo.displayName}
              />
            )}
          </div>
          {userInfo && (
            <div
              className="flex justify-center text-base items-center cursor-pointer"
              onClick={() => logout()}
            >
              <span>Logout</span>
            </div>
          )}
        </div>
      </Container>
    </header>
  )
}

export default Header
