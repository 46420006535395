import React from 'react'
import { DataTable } from 'primereact/datatable'
import { PrimeColumn } from '../common'


const SearchLastNameHolderTemplate = ({
    lastName,
    lastNameMismatchError,
}: any) => {
    return (
        <>
            <div className={`${lastNameMismatchError ? 'p-error' : null}`}>
                {lastName}
            </div>
        </>
    )
}


const SearchCustometIdTemplate = ({
    accountID,
    accountIDMismatchError,
}: any) => {
    return (
        <>
            <div className={`${accountIDMismatchError ? 'p-error' : null}`}>
                {accountID}
            </div>
        </>
    )
}

const CDSCardResult = ({ searchResult }: any) => {
    const cellCommonStyle = { fontSize: '14px', fontWeight: 'normal' }
    const headerStyle = {
        fontSize: '14px',
        fontWeight: 'bold',
        itemAlign: 'center',
    }

    return searchResult === null ? null : (
        <div className="mt-1 w-full">
            {Array.isArray(searchResult) && searchResult.length > 0 ? (
                <DataTable value={searchResult}>
                    <PrimeColumn
                        field="firstName"
                        // body={SearchFirstNameHolderTemplate}
                        style={cellCommonStyle}
                        header="First Name"
                        headerStyle={{ ...headerStyle, width: '50px' }}
                    />
                    <PrimeColumn
                        field="lastName"
                        body={SearchLastNameHolderTemplate}
                        style={cellCommonStyle}
                        header="Last Name"
                        headerStyle={{ ...headerStyle, width: '50px' }}
                    />
                    <PrimeColumn
                        field="prefName"
                        style={cellCommonStyle}
                        header="Preferred Name"
                        headerStyle={{ ...headerStyle, width: '50px' }}
                    />
                    <PrimeColumn
                        field="email"
                        style={cellCommonStyle}
                        header="Email ID"
                        headerStyle={{ ...headerStyle, width: '140px' }}
                    />
                    <PrimeColumn
                        field="accountID"
                        body={SearchCustometIdTemplate}
                        style={cellCommonStyle}
                        header="Customer ID"
                        headerStyle={{ ...headerStyle, width: '50px' }}
                    />
                    <PrimeColumn
                        field="address1"
                        style={cellCommonStyle}
                        header="Address Line1"
                        headerStyle={{ ...headerStyle, width: '60px' }}
                    />
                    <PrimeColumn
                        field="address2"
                        style={cellCommonStyle}
                        header="Address Line2"
                        headerStyle={{ ...headerStyle, width: '60px' }}
                    />

                    <PrimeColumn
                        field="postCode"
                        style={cellCommonStyle}
                        header="Post code"
                        headerStyle={{ ...headerStyle, width: '50px' }}
                    />
                    <PrimeColumn
                        field="locationId"
                        style={cellCommonStyle}
                        header="Location ID"
                        headerStyle={{ ...headerStyle, width: '40px' }}
                    />
                    <PrimeColumn
                        field="linkStatus"
                        style={cellCommonStyle}
                        header="Link Status"
                        headerStyle={{ ...headerStyle, width: '30px' }}
                    />

                </DataTable>
            ) : <div className=''>No results available</div>}
        </div>
    )
}

export default CDSCardResult
