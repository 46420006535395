import React, { useState } from 'react'
import EditIcon from '@material-ui/icons/Edit'
import SaveIcon from '@material-ui/icons/Save'
import InfoIcon from '@material-ui/icons/Info'
import DeleteIcon from '@material-ui/icons/Delete'
import { Tooltip } from 'primereact/tooltip'
import { Icon } from '../../common'
import { primary, disable } from '../../../materialTheme/colors'
import { CardContainer, AppContainer } from '../../../container'
import { isAdmin } from '../../../util/validation'
import { formatDate } from '../../../util/format'
import CancelDialogFAndF from './CancelDialogFAndF'
import consoleLog from '../../../util/consoleLog'

export const ActionIcons = ({
    isColleague,
    cardNumber,
    cardHolderName,
    cardHolderPostCode,
    status,
    action,
    cardId,
    saved,
    cardStatusAdmin,
    custNo,
    fAndfLimitReached,
    isAdminDisabled,
    showAnchor,
}: any) => {
    const {
        updateAction,
        updateCardHolderDetails,
        updateCardStatusBFF,
        callCancelOrderNewCardFAndFBFF,
        isActionAdmin,
    } = CardContainer.useContainer()

    const [openDialog, setOpenDialog] = useState(false)
    const { colleagueDetail, role, isColleagueTerminated } = AppContainer.useContainer()

    const handleEditClick = () => {
        updateAction(cardId, 'edit')
    }

    const handleDeleteFAndFClick = () => {
        setOpenDialog(true)
    }

    const deleteFAndFClick = async () => {
        const payload = {
            cardNumber: cardNumber,
            empId: colleagueDetail.employeeId,
            action: 'delete',
            role: 'f&f',
            actionDoneBy: isAdmin(role),
        }

        await callCancelOrderNewCardFAndFBFF(payload)
        updateAction(cardId, 'save')
        setOpenDialog(false)
    }

    const handleDisableClick = () => { }

    const handleSaveClick = () => {
        if (!saved) {
            updateCardHolderDetails(
                colleagueDetail.employeeId,
                cardId,
                cardNumber,
                cardHolderName,
                cardHolderPostCode
            )
        } else {
            consoleLog('CardTemplate', '74', 'custNo : ', custNo)
            updateCardStatusBFF(
                colleagueDetail.employeeId,
                cardNumber,
                cardId,
                status,
                isColleague,
                isAdmin(role),
                custNo
            )
        }
        updateAction(cardId, 'save')
    }
    consoleLog(
        'CardTemplate',
        '87',
        'fAndfLimitReached : ',
        fAndfLimitReached,
        isAdminDisabled
    )
    consoleLog(
        'CardTemplate',
        '88',
        'isAdminDisabled : ',
        isAdminDisabled === 'true'
    )
    consoleLog('CardTemplate', '89', 'cardStatusAdmin : ', cardStatusAdmin)
    const disableDeleteIcon = () => {
        if (isColleagueTerminated()) {
            return false
        }
        if (cardNumber === 'xxxxxxxxxxxxxxxxxxx') {
            return false
        }
        if (fAndfLimitReached) {
            return false
        }

        return cardStatusAdmin !== 'true' && status !== 'Cancelled'
    }

    const disableEditIcon = () => {
         if (isColleagueTerminated()) {
            return false
        }

        if (cardNumber === 'xxxxxxxxxxxxxxxxxxx') {
            return false
        }
        if (isActionAdmin) {
            return true
        }
        if (isAdminDisabled === 'true' && role === 'COLLEAGUE') {
            return false
        }
        if (
            status.toLowerCase() === 'cancelled' ||
            status.toLowerCase() === 'processing'
        ) {
            return false
        }
        return true
    }

    return (
        <>
            <CancelDialogFAndF
                handleCancelCards={deleteFAndFClick}
                openDialog={openDialog}
                cardNumber={cardNumber}
                setOpenDialog={setOpenDialog}
            />
            <div className={` ${showAnchor ? 'mt-8' : ''}`}>
                {action === 'save' && saved ? (
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <Icon
                            MIcon={EditIcon}
                            color={disableEditIcon() ? primary.primary : disable.primary}
                            onClick={disableEditIcon() ? handleEditClick : handleDisableClick}
                        />
                        {!isColleague ? (
                            <Icon
                                MIcon={DeleteIcon}
                                color={disableDeleteIcon() ? primary.primary : disable.primary}
                                onClick={
                                    disableDeleteIcon()
                                        ? handleDeleteFAndFClick
                                        : handleDisableClick
                                }
                            />
                        ) : (
                                ''
                            )}
                    </div>
                ) : (
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <Icon
                                MIcon={SaveIcon}
                                color={isColleagueTerminated()?  disable.primary :primary.primary}
                                onClick={isColleagueTerminated()? handleDisableClick : handleSaveClick}
                            />
                            {!isColleague ? (
                                <Icon
                                    MIcon={DeleteIcon}
                                    color={isColleagueTerminated()?  disable.primary :primary.primary}
                                    onClick={isColleagueTerminated()? handleDisableClick : handleDeleteFAndFClick}
                                />
                            ) : (
                                    ''
                                )}
                        </div>
                    )}
            </div>
        </>
    )
}

export const EditTemplate = ({
    isColleague,
    cardNumber,
    action,
    cardId,
    status,
    saved,
    cardHolderName,
    cardHolderPostCode,
    cardStatusAdmin,
    custNo,
    fAndfLimitReached,
    isAdminDisabled,
    showAnchor,
}: any) => {
    consoleLog(
        'CardTemplate',
        '179',
        'fAndfLimitReached edit : ',
        cardNumber,
        ' , ',
        fAndfLimitReached,
        isAdminDisabled
    )
    return (
        <ActionIcons
            cardId={cardId}
            isColleague={isColleague}
            cardHolderName={cardHolderName}
            cardHolderPostCode={cardHolderPostCode}
            cardNumber={cardNumber}
            action={action}
            saved={saved}
            status={status}
            cardStatusAdmin={cardStatusAdmin}
            custNo={custNo}
            fAndfLimitReached={fAndfLimitReached}
            isAdminDisabled={isAdminDisabled}
            showAnchor={showAnchor}
        />
    )
}

export const CardNumberTemple = ({
    cardNumber,
    status,
    showAnchor,
    saved,
    moreCardNumber,
}: any) => (
        <>
            <div className="underline text-sm font-bold text-gray-700 pb-4">
                {showAnchor ? 'Add Family & Friends Cards' : ''}
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div
                    className={`${
                        status.toLowerCase() === 'disable' ? 'text-gray-400' : ''
                        }`}
                >
                    {cardNumber}
                </div>{' '}
                {moreCardNumber && (
                    <div className="p-d-flex p-ai-center">
                        <Tooltip target=".custom-target-icon">{moreCardNumber}</Tooltip>

                        <div className="custom-target-icon">
                            <Icon MIcon={InfoIcon} color="#616161" />
                        </div>
                    </div>
                )}
            </div>
        </>
    )

export const CardHolderNameTemplate = ({
    cardHolderName,
    status,
    showAnchor,
}: any) => (
        <>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div
                    className={`${
                        status.toLowerCase() === 'disable' ? 'text-gray-400' : ''
                        }`}
                >
                    {cardHolderName}
                </div>
            </div>
        </>
    )

export const CardRefreshDateTemple = ({
    refreshDate,
    status,
    action,
    showAnchor,
}: any) => (
        <div
            className={`${
                status.toLowerCase() === 'disable' && action === 'save'
                    ? 'text-gray-400'
                    : ''
                } flex text-sm ${showAnchor ? 'mt-8' : ''}`}
        >
            {refreshDate !== '' ? formatDate(refreshDate) : '-'}
        </div>
    )
