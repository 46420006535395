import React from 'react'
import { Container } from '../component/common'

function Error() {
  return (
    <>
      <Container>
        <h1 className="text-lg text-center mt-8">
          You do not have enough permission to access the application
        </h1>
      </Container>
    </>
  )
}

export default Error
