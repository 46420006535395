import React from 'react'
import { brandName } from '../../util/brandConfig'

interface RegistrationSuccessProps {}

const RegistrationSuccess: React.FC<RegistrationSuccessProps> = () => {
  return (
    <section className="mt-14 sm:mt-20 md:pr-48">
      <p className="mb-4 font-semibold text-lg leading-tight">
        Congratulations!
      </p>
      <p className="mb-6 leading-tight">
        {`You have successfully linked your ${brandName} Card with your Morrisons
        Discount Club.`}
      </p>
      <p className="mb-4 leading-tight">
        {`You will only need to use your Morrisons Discount Club card at the
        checkout to receive Morrisons Discount and ${brandName} offers. Please
        dispose of your old ${brandName} Card if you haven't done so already.`}
      </p>
    </section>
  )
}

export default RegistrationSuccess
