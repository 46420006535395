import React from 'react'
import { AppContainer } from '../../../container'
import { formatCard } from '../../../util/format'
import { brandName } from '../../../util/brandConfig'

const CardList = ({ cardNumber }: any) => {
    return (
        <div className={`flex  items-center`}>
            <div className="w-full mob:text-justify">
                Please confirm whether you want to delete your Family & Friend card
                <p className="font-bold">{formatCard(cardNumber)}?</p>
                <div className="mt-4">
                    {`Please remember to let the customer know that you have cancelled
                    their membership to the discount club and that they will be issued a
                    ${brandName} card.`}
                </div>
            </div>
        </div>
    )
}

const DeleteCard = ({ cardNumber }: any) => {
    const { isMobile } = AppContainer.useContainer()
    const width = isMobile ? '100%' : '500px'
    return (
        <div style={{ width }}>
            <div className="flex w-full">
                <div className="w-1/12 "></div>
            </div>
            <CardList cardNumber={cardNumber} />
        </div>
    )
}

export default DeleteCard
