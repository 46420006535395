import React from 'react'

import { SearchContainer } from '../../container'
import MoreCardResults from './MoreCardResults'
import CDSCardResult from './CDSCardResult'
import TroubleShootAccountLookUp from './TroubleShootAccountLookUp'
import TroubleShootClubs from './TroubleShootClubs'


function TroubleShootCardSearchResult() {

    const { moreCardDetails, troubleshootByDiscountCardResult, troubleshootFullResult } = SearchContainer.useContainer()

    return (
        <div className="mt-12 w-full">
            {troubleshootFullResult &&
                Array.isArray(troubleshootFullResult.cardType) &&
                troubleshootFullResult.cardType.length > 0 &&
                <div className="mt-12 w-full">
                    The card number {troubleshootFullResult.cardType[0].cardNumber} is a {troubleshootFullResult.cardType[0].name}
                </div>
            }
            <div className="mt-12 w-full">
                <h2 className="font-bold">Result from Salesforce</h2>
                {moreCardDetails && <MoreCardResults />}
            </div>
            <div className="mt-12 w-full">
                <h2 className="font-bold mb-4">Result from Colleague Discount System</h2>
                <CDSCardResult searchResult={troubleshootByDiscountCardResult} />
            </div>
            <div className="mt-12 w-full">
                <h2 className="font-bold">Result from Account Lookup</h2>
                <TroubleShootAccountLookUp />

            </div>
            <div className="mt-12 w-full">
                <h2 className="font-bold">Result from Clubs</h2>
                <TroubleShootClubs />
            </div>
        </div>
    )
}

export default TroubleShootCardSearchResult
