import React from 'react'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'

interface SearchResultProps {
  searchResult: any
  role: string
}

const SearchResultMob = ({ searchResult, role }: SearchResultProps) => {
  return searchResult === null ? null : (
    <div className="mt-2 w-full">
      {Array.isArray(searchResult) && searchResult.length > 0 ? (
        searchResult.map((details: any) => {
          return (
            <div className="flex-col mt-2 ml-1.5 w-full">
              <div className="font-bold mt-4">Result</div>
              <div>
                <h1 className="text-gray-600 mt-4">Employee Name</h1>
                <span className="font-medium">{details.colleagueName}</span>
              </div>
              <div className="mt-4">
                <h1 className="text-gray-600">Employee ID</h1>
                <span className="font-medium">{details.employeeId}</span>
              </div>
              <div className="mt-4">
                <h1 className="text-gray-600">Email Address</h1>
                <span className="font-medium">{details.email}</span>
              </div>
              <div className="mt-4 mb-4">
                <h1 className="text-gray-600">Card Number</h1>
                <span className="font-medium">{details.cardNumber}</span>
              </div>
              <Link
                to={
                  role === 'admin'
                    ? `/admin/colleaguedetails/${details.employeeId}`
                    : `/storeadmin/colleaguedetails/${details.employeeId}`
                }
                className="mob:w-full"
              >
                <Button
                  variant="contained"
                  className="w-full h-10 focus:outline-none"
                  color="primary"
                >
                  View Details
                </Button>
              </Link>
            </div>
          )
        })
      ) : (
        <div className="w-full flex h-14 justify-center text-center items-center text-red-600 text-lg">
          <span>No colleague found!</span>
        </div>
      )}
    </div>
  )
}

export default SearchResultMob
