import React from 'react'
import { SpinLoader } from '../../../component/common'
import {
  GroupContainer,
  SearchContainer,
  CardContainer,
  AppContainer,
} from '../../../container'

import { isStoreAdmin } from '../../../util/validation'
import ErrorRole from '../../../app/Error'

import StoreAdminRoute from '../../../route/StoreAdminRoute'
import consoleLog from '../../../util/consoleLog'

function StoreMain() {
  const { role } = AppContainer.useContainer()
  consoleLog('Main','18','role : ', role)
  return isStoreAdmin(role) ? (
    <GroupContainer.Provider>
      <SearchContainer.Provider>
        <CardContainer.Provider>
          <SpinLoader />
          <StoreAdminRoute />
        </CardContainer.Provider>
      </SearchContainer.Provider>
    </GroupContainer.Provider>
  ) : role ? (
    <ErrorRole />
  ) : null
}

export default StoreMain
