import React, { useState } from 'react'
import {
  Container,
  SearchResult,
  SearchResultMob,
} from '../../component/common'
import Search from '../../component/adminHome/Search'
import { SearchContainer, AppContainer } from '../../container'

function StoreHome() {
  const [searchResult, setSearchResult] = useState<any>(null)
  const { getSearchResult } = SearchContainer.useContainer()
  const { location, isMobile } = AppContainer.useContainer()
  const handleSearch = async (searchKey: string) => {
    const searchData = await getSearchResult(searchKey, '', {
      location: location,
      colleagueType: 'Colleague',
    })
    setSearchResult(searchData)
  }
  return (
    <Container>
      <div className="mt-36">
        <Search onSearch={handleSearch} />
        {isMobile ? (
          <SearchResultMob searchResult={searchResult} role="storeadmin" />
        ) : (
          <SearchResult searchResult={searchResult} role="storeadmin" />
        )}
      </div>
    </Container>
  )
}
export default StoreHome
