import { useState, useEffect } from 'react'
import { createContainer } from 'unstated-next'
import { callCloudFunctionsBFF } from '../cloudFunction'
import { getData } from '../service'
import { disableGoogleSignIn } from '../appConfig'
import consoleLog from '../util/consoleLog'

const useGroupContainer = () => {
  const [groupDetails, setGroupDetails] = useState<any>(null)
  const [groupDetailsAction, setGroupDetailsAction] = useState<any>(null)
  const [allowance, setAllowance] = useState<any>(null)
  const [allowanceError, setAllowanceError] = useState<boolean>(false)
  const [groupStatus, setGroupStatus] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [spinLoading, setSpinLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')

  const getGroupDetails = () => {
    const data = getData('groupDetails')
    consoleLog('GroupContainer','20','data : ', data)
    setGroupDetails(data)
    setLoading(true)
  }

  const getGroupDetailsById = (id: string) => {
    if (Array.isArray(groupDetails)) {
      return groupDetails.find((group: any) => group.groupId === id)
    }
  }

  const updateGroupName = (groupName: string, id: string) => {
    if (Array.isArray(groupDetails)) {
      const updatedGroup = groupDetails.map((group: any) => {
        if (group.groupId === id) group.groupName = groupName
        return group
      })
      setGroupDetails(updatedGroup)
    }
  }

  const updateGroupStatus = async (status: string, groupName: string) => {
    setSpinLoading(true)
    const updateGroupStatusBFF = await callCloudFunctionsBFF(
      'updateGroupStatus'
    )
    const resp = await updateGroupStatusBFF({
      groupName: groupName.trim(),
      status,
    })

    setSpinLoading(false)
    if (resp.data) {
      const { errorMessage } = resp.data
      await callGetMembershipGroupsBFF()
      setErrorMessage(errorMessage)
    }
  }

  const callGetMembershipGroupsBFF = async () => {
    const getMembershipGroupsBFF = await callCloudFunctionsBFF(
      'getMembershipGroups'
    )
    const { data } = await getMembershipGroupsBFF()
    setGroupDetails(data)
    setLoading(true)
  }

  const callUpdateGroupsAllowanceBFF = async (
    groupName: string,
    groupAllowance: string
  ) => {
    setSpinLoading(true)
    const payload = { groupName, allowance: groupAllowance }
    const updateGroupsAllowanceBFF = await callCloudFunctionsBFF(
      'updateGroupAllowance'
    )
    const { data } = await updateGroupsAllowanceBFF(payload)
    const { errorMessage } = data
    setErrorMessage(errorMessage)
    await callGetMembershipGroupsBFF()
    setSpinLoading(false)
  }

  const updateAction = (groupName: string, action: string) => {
    const parseGroup = groupDetailsAction.map((group: any) => {
      return group.groupName === groupName
        ? { ...group, action: action }
        : group
    })
    setGroupDetailsAction(parseGroup)
  }
  const updateAllowance = (amount: string) => {
    setAllowance(amount)
  }

  const updateGroupAllowance = async (
    groupAllowance: string,
    groupName: string
  ) => {
    if (groupAllowance === '') {
      setAllowanceError(true)
    } else {
      await callUpdateGroupsAllowanceBFF(groupName, groupAllowance)
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setAllowanceError(false)
    }, 1000)
    return () => clearTimeout(timer)
  })

  useEffect(() => {
    disableGoogleSignIn ? getGroupDetails() : callGetMembershipGroupsBFF()
  }, [])

  useEffect(() => {
    if (groupDetails) {
      const parseGroup = groupDetails.map((group: any) => {
        return { ...group, action: 'save' }
      })
      setGroupDetailsAction(parseGroup)
    }
  }, [groupDetails])

  return {
    groupDetails,
    groupDetailsAction,
    getGroupDetailsById,
    updateGroupName,
    updateGroupStatus,
    updateAction,
    updateGroupAllowance,
    updateAllowance,
    allowance,
    allowanceError,
    setGroupStatus,
    groupStatus,
    loading,
    spinLoading,
    errorMessage,
  }
}

const GroupContainer = createContainer(useGroupContainer)
export default GroupContainer
