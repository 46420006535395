import React from 'react'

interface HeadingAndTextProps {
  heading: string
  text: string
  className: any
  capitalize?: any
}

function HeadingAndText({
  heading,
  text,
  className,
  capitalize,
}: HeadingAndTextProps) {
  return (
    <div className={`${className} mt-4 ml-4 mob:w-full`}>
      <h2 className="text-sm text-gray-600">{heading}</h2>
      <h1 className={`text-sm ${capitalize ? 'capitalize' : ''}`} title={text}>
        {text}
      </h1>
    </div>
  )
}

export default HeadingAndText
