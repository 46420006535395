import React from 'react'
import { Link } from 'react-router-dom'
import { Icon } from '../../common'
import { primary } from '../../../materialTheme/colors'
import VisibilityIcon from '@material-ui/icons/Visibility'

export const ActionTemplateAdmin = ({ employeeId }: any) => (
  <Link to={`/admin/colleaguedetails/${employeeId}`}>
    <Icon MIcon={VisibilityIcon} color={primary.primary} />
  </Link>
)

export const ActionTemplateStoreAdmin = ({ employeeId }: any) => (
  <Link to={`/storeadmin/colleaguedetails/${employeeId}`}>
    <Icon MIcon={VisibilityIcon} color={primary.primary} />
  </Link>
)
