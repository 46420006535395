import React from 'react'
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom'
import { EditGroup, ListGroup, Home } from '../page/admin'

import AdminColleagueDetails from '../page/admin/colleagueDetails/AdminColleagueDetails'
import SetAllowance from '../page/admin/setAllowance/SetAllowance'
import MoreCdsCards from '../page/admin/moreCdsCards/MoreCdsCards'

function AdminRoute() {
  let { path } = useRouteMatch()

  return (
    <Switch>
      <Route
        path={`${path}/group-edit/:groupId`}
        exact={true}
        component={EditGroup}
      />
      <Route path={`${path}/group-list`} exact component={ListGroup} />
      <Route path={`${path}/home`} exact component={Home} />
      <Route
        path={`${path}/colleaguedetails/:empId`}
        exact={true}
        component={AdminColleagueDetails}
      />
      <Route path={`${path}/setallowance`} exact component={SetAllowance} />
      <Route path={`${path}/troubleshoot`} exact component={MoreCdsCards} />
      <Redirect exact={true} path="/admin" to="/admin/home" />
    </Switch>
  )
}

export default AdminRoute
