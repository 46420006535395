import React from 'react'
import GroupList from '../../../component/group/GroupList'
import { Container } from '../../../component/common'
import GroupListMob from '../../../component/group/GroupListMob'
import { AppContainer } from '../../../container'
import consoleLog from '../../../util/consoleLog'

function ListGroup() {
  const { isMobile } = AppContainer.useContainer()
  consoleLog('ListGroup','10','isMobile : ', isMobile)

  return <Container>{isMobile ? <GroupListMob /> : <GroupList />}</Container>
}

export default ListGroup
