import React from 'react'
import { Column } from 'primereact/column'

interface ColumnProps {
  field: string
  header: string
  style?: any
  headerStyle?: any
  body?: any
  bodyStyle?: any
  editor?: any
}
function PrimeColumn({
  field,
  style,
  header,
  headerStyle,
  body,
  bodyStyle,
  editor,
}: ColumnProps) {
  const props: ColumnProps = { field, header }
  if (style) props.style = style
  if (headerStyle) props.headerStyle = headerStyle
  if (body) props.body = body
  if (bodyStyle) props.bodyStyle = bodyStyle
  if (bodyStyle) props.editor = editor

  return <Column {...props} />
}

export default PrimeColumn
