import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import { Home } from '../page'
import { Main } from '../page/admin'
import StoreMain from '../page/storeAdmin/main/Main'
import PTMain from '../page/pt/Main'
import LinkMoreCardIndex from '../page/linkMoreCard'

function AppRoute() {
  return (
    <Switch>
      <Route path="/home" exact={true} component={Home} />
      <Route path="/linkmorecard" component={LinkMoreCardIndex} />
      <Route
        path="/colleague/linkmorecard/:empId"
        component={LinkMoreCardIndex}
      />
      <Route path="/admin" component={Main} />
      <Route path="/storeAdmin" component={StoreMain} />
      <Route path="/ptlogin" exact={true} component={PTMain} />
      <Redirect exact={true} path="/" to="/home" />
    </Switch>
  )
}

export default AppRoute
