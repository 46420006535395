import React, { useState, useEffect } from 'react'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { Icon } from '../common'
import SearchIcon from '@material-ui/icons/Search'
import { tertiary, disable } from '../../materialTheme/colors'
// import { isNameValid } from '../../util/nameValidation'
import { SearchContainer } from '../../container'

const NonColleagueSearchName = ({
  onSearch,
  onError,
  nonColleagueOption,
  colleagueType,
}: any) => {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const { getSearchResult } = SearchContainer.useContainer()

  const handleTextFirstName = (event: any) => {
    setFirstName(event.target.value)
  }

  const handleTextLastName = (event: any) => {
    setLastName(event.target.value)
  }

  const isEmptyText = () => {
    return firstName === '' || lastName === ''
  }

  const isValidSearchText = () => {
      return firstName !== '' || lastName !== ''
    // return isNameValid(firstName) || isNameValid(lastName)
  }

  const getPayload = () => {
    if (firstName !== '' && lastName !== '')
      return { payload: { firstName, lastName }, type: 'and' }
    else if (firstName !== '') return { payload: firstName, type: 'firstname' }
    else if (lastName !== '') return { payload: lastName, type: 'lastname' }
    else return { payload: '', type: '' }
  }

  const handleClick = async () => {
    if (isValidSearchText()) {
      onError(false)
      const { payload, type } = getPayload()
      const searchResult = await getSearchResult(payload, type, {
        nonColleagueOption,
        colleagueType,
      })
      onSearch(searchResult)
    } else {
      onError(true)
    }
  }

  useEffect(() => {
    if (isEmptyText()) {
      onError(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstName, lastName])

  return (
    <>
      <div className="flex flex-wrap mob:w-full w-3/4 mob:my-4">
        <TextField
          className="w-1/3 ml-0.5 mr-0.5 focus:outline-none mob:w-full"
          id="outlined-basic"
          variant="outlined"
          placeholder="Search by First Name"
          onChange={handleTextFirstName}
        />
        <span className="font-bold mob:w-full text-center mt-4 mob:mt-1.5 mob:mb-1.5">
          and
        </span>
        <TextField
          className="w-1/3 ml-0.5 focus:outline-none mob:w-full mob:mb-4"
          id="outlined-basic"
          variant="outlined"
          placeholder="Last Name"
          onChange={handleTextLastName}
        />
        <Button
          className="w-36 h-14 focus:outline-none  mob:w-full"
          variant="contained"
          color="primary"
          onClick={handleClick}
          startIcon={
            <Icon
              MIcon={SearchIcon}
              color={!isValidSearchText() ? disable.primary : tertiary.tertiary}
              size={40}
            />
          }
          disabled={!isValidSearchText()}
        >
          <span className=" text-white text-transform: capitalize text-lg">
            Search
          </span>
        </Button>
      </div>
    </>
  )
}
export default NonColleagueSearchName
