import React from 'react'

function Container({ children }: any) {
  return (
    <div className="container mx-auto lg:px-8 lg:px-4">
      <div className="w-full">{children}</div>
    </div>
  )
}
export default Container
