import React from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { GroupContainer } from '../../container'

import { EditTemplate } from './CellTemplateMob'

import StatusTemplate from './GroupStatus'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            flexBasis: '33.33%',
            flexShrink: 0,
        },
        secondaryHeading: {
            fontSize: theme.typography.pxToRem(15),
            color: theme.palette.text.secondary,
        },
    })
)

const Group = ({ group }: any) => {
    return (
        <div className="flex-wrap items-center text-gray-800 w-full text-sm">
            <div className="w-full text-left text-gray-800">Club Name</div>
            <div className="w-full text-left-bold ">{group.clubName}</div>

            <div className="w-full text-left text-gray-800 mt-2">Start Date</div>
            <div className="w-full text-left ">{group.startDate}</div>
            <div className="w-full text-left text-gray-800 mt-2">End Date</div>
            <div className="w-full text-left ">{group.endDate !=="" ? group.endDate : '-'}</div>
            <div className="w-full text-left text-gray-800 mt-2">Status</div>
            <div className="w-full text-left ">
                <StatusTemplate
                    groupName={group.groupName}
                    action={group.action}
                    status={group.status}
                />
            </div>

            <EditTemplate groupName={group.groupName} action={group.action}  status={group.status} />
        </div>
    )
}

export default function ControlledAccordions() {
    const classes = useStyles()
    const [expanded, setExpanded] = React.useState<string | false>(false)

    const handleChange = (panel: string) => (
        event: React.ChangeEvent<{}>,
        isExpanded: boolean
    ) => {
        setExpanded(isExpanded ? panel : false)
    }

    const { groupDetailsAction } = GroupContainer.useContainer()

    return (
        <div>
            <div className="font-bold">
                <h1 className="ml-4">
                    Manage Membership Group ({(groupDetailsAction || []).length})
        </h1>
            </div>

            <div className="flex h-8 justify-center items-center m-4 ">
                <div className="w-3/4 ">Group Name</div>
                <div className="w-1/4 mr-4">Status</div>
            </div>
            <div className={classes.root}>
                {(groupDetailsAction || []).map((group: any, index: any) => {
                    const panel = 'panel' + index
                    return (
                        <Accordion
                            key={index}
                            expanded={expanded === panel}
                            onChange={handleChange(panel)}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                                className="h-20 "
                            >
                                <div className="flex h-8 justify-center items-center w-full text-sm ">
                                    <div className={` w-4/5`}>{group.groupName}</div>
                                    <div className={` w-1/5`}>{group.status}</div>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Group group={group} />
                            </AccordionDetails>
                        </Accordion>
                    )
                })}
            </div>
        </div>
    )
}
