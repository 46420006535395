import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { NonColleagueContainer } from '../../container'

function SpinLoader() {
  const { loading } = NonColleagueContainer.useContainer()

  return loading ? (
    <div className="fixed top-0 bottom-0 right-0 left-0 bg-gray-300 z-20 opacity-60">
      <div className="w-full h-full  flex justify-center items-center">
        <CircularProgress />
      </div>
    </div>
  ) : null
}

export default SpinLoader
