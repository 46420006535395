import React from 'react'
import { Container } from '../common'

interface HeaderProps {}

const Header: React.FC<HeaderProps> = () => {
  return (
    <section className="flex text-center bg-primary h-20 w-full text-white text-2xl justify-left items-center">
      <Container>
        <header className="flex items-center justify-between">
          <h1 className="text-2xl mob:ml-4">Morrisons Discount Club</h1>
        </header>
      </Container>
    </section>
  )
}

export default Header
