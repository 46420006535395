import React from 'react'

import Button from '@material-ui/core/Button'


import { GroupContainer } from '../../container'
import consoleLog from '../../util/consoleLog'

export const CellTemplate = ({ status, text }: any) => {
    return (
        <div
            className={`${
                status.toLowerCase() === 'active' ? '' : 'text-gray-400'
                } flex`}
        >
            {text}
        </div>
    )
}

export const StatueTemplate = ({ status }: any) => (
    <CellTemplate text={status} status={status} />
)

export const GroupNameTemplate = ({ groupName, status }: any) => (
    <CellTemplate text={groupName} status={status} />
)

export const GroupIDTemplate = ({ groupId, status }: any) => (
    <CellTemplate text={groupId} status={status} />
)

export const ClubNameTemplate = ({ clubName, status }: any) => (
    <CellTemplate text={clubName} status={status} />
)

export const ClubIdTemplate = ({ clubId, status }: any) => (
    <CellTemplate text={clubId} status={status} />
)

export const StartDateTemplate = ({ startDate, status }: any) => (
    <CellTemplate text={startDate} status={status} />
)

export const EndDateTemplate = ({ endDate, status }: any) => (
    <CellTemplate text={endDate} status={status} />
)

export const ActionIcons = ({ groupName, action, status }: any) => {
    const {
        updateAction,
        groupStatus,
        updateGroupStatus,
    } = GroupContainer.useContainer()

    consoleLog('CellTemplateMob','56',action)

    const handleEditClick = () => {
        updateAction(groupName, 'edit')
    }

    const handleCancelClick = () => {
        updateAction(groupName, 'cancel')
    }

    const handleSaveClick = () => {
        updateAction(groupName, 'save')
        updateGroupStatus(groupStatus, groupName)
    }



    return action === 'save' || action === 'cancel' ? (

        <div className="flex justify-end">
            <Button disabled={status === 'processing'}
                variant="contained"
                className="w-32 h-8 focus:outline-none"
                color="primary"
                onClick={handleEditClick}>
                Edit
        </Button>
        </div>
    ) : (

            <div className="flex w-full mt-2"><Button
                variant="contained"
                className="w-1/2 h-8 focus:outline-none mr-1.5"
                color="primary"
                onClick={handleCancelClick}>
                Cancel
        </Button>
                <Button
                    variant="contained"
                    className="w-1/2 h-8 focus:outline-none"
                    color="primary"
                    onClick={handleSaveClick}>
                    Save
        </Button>
            </div>
        )
}

export const EditTemplate = ({ groupName, action, status }: any) => {
    consoleLog('CellTemplateMob','105', groupName, action)
    return <ActionIcons groupName={groupName} action={action} status={status} />
}
