import React from 'react'
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom'

import StoreHome from '../page/storeAdmin/StoreHome'
import StoreColleagueDetails from '../page/storeAdmin/ColleagueDetails'

function StoreAdminRoute() {
  let { path } = useRouteMatch()

  return (
    <Switch>
      <Route path={`${path}/home`} exact component={StoreHome} />
      <Route
        path={`${path}/colleaguedetails/:empId`}
        exact={true}
        component={StoreColleagueDetails}
      />

      <Redirect exact={true} path="/storeAdmin" to="storeAdmin/home" />
    </Switch>
  )
}

export default StoreAdminRoute
