import React from 'react'
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline'

interface DiscountCardValidationSuccessProps {}

const DiscountCardValidationSuccess: React.FC<DiscountCardValidationSuccessProps> = () => {
  return (
    <div className="mt-6 flex flex-col items-center">
      <CheckCircleOutline className="text-3xl " />
      <p className="mt-6">Discount Card validated!</p>
    </div>
  )
}

export default DiscountCardValidationSuccess
