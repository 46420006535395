import React from 'react'

import InfoIcon from '@material-ui/icons/Info'
import { Tooltip } from 'primereact/tooltip'
import { CardContainer } from '../../../container'
import { Icon } from '../../common'
import { formatCard } from '../../../util/format'
import { brandName } from '../../../util/brandConfig'

interface defaultValueProps {
  defaultValue: string
  valueType: string
  cardId: number
}

function InputText({ defaultValue, valueType, cardId }: defaultValueProps) {
  const {
    updateName,
    updatePostCode,
    updateCardNumber,
    getCardError,
    cardError,
    cardMapped,
  } = CardContainer.useContainer()
  const handleChange = (event: any) => {
    if (valueType === 'cardName') {
      updateName(event.target.value, cardId)
    }
    if (valueType === 'cardPostCode') {
      updatePostCode(event.target.value, cardId)
    }
    if (valueType === 'cardNumber') {
      updateCardNumber(event.target.value, cardId)
    }
  }

  return (
    <div className="pb-2">
      <input
        type="text"
        placeholder={defaultValue}
        onBlur={handleChange}
        className={`${
          (valueType === 'cardNumber' && getCardError(cardId, 'number')) ||
          (valueType === 'cardName' && getCardError(cardId, 'name')) ||
          (valueType === 'cardPostCode' && getCardError(cardId, 'postcode'))
            ? 'p-inputtext p-component p-invalid p-d-block'
            : ''
        } p-1 pl-2 border border-white-600 container`}
      />

      {cardMapped ? (
        getCardError(cardId, 'number') && valueType === 'cardNumber' ? (
          <small id="cardnumber2-help" className="font-thin p-error p-d-block">
            This card number is already added.
          </small>
        ) : (
          ''
        )
      ) : !cardError ? (
        getCardError(cardId, 'number') && valueType === 'cardNumber' ? (
          <small id="cardnumber2-help" className="font-thin p-error p-d-block">
            Card number is invalid.
          </small>
        ) : (
          ''
        )
      ) : getCardError(cardId, 'number') && valueType === 'cardNumber' ? (
        <small id="cardnumber2-help" className="font-thin p-error p-d-block">
          {`The ${brandName} card number doesn't match our records, please try
          again.`}
        </small>
      ) : (
        ''
      )}
      {!cardError ? (
        getCardError(cardId, 'name') && valueType === 'cardName' ? (
          <small id="cardname2-help" className="font-thin p-error p-d-block">
            Card name is not available.
          </small>
        ) : (
          ''
        )
      ) : getCardError(cardId, 'name') && valueType === 'cardName' ? (
        <small id="cardname2-help" className="font-thin p-error p-d-block">
          {`The last name doesn't match the ${brandName} card number, please try
          again.`}
        </small>
      ) : (
        ''
      )}
      {!cardError ? (
        getCardError(cardId, 'postcode') && valueType === 'cardPostCode' ? (
          <small
            id="cardpostcode2-help"
            className="font-thin p-error p-d-block"
          >
            Card post code is invalid.
          </small>
        ) : (
          ''
        )
      ) : getCardError(cardId, 'postcode') && valueType === 'cardPostCode' ? (
        <small id="cardpostcode2-help" className="font-thin p-error p-d-block">
          {`The post code doesn't match the ${brandName} card number, please try
          again.`}
        </small>
      ) : (
        ''
      )}
    </div>
  )
}

export const EditCardHolderNameTemplate = ({
  isColleague,
  cardHolderName,
  saved,
  status,
  cardId,
  action,
  isNew,
}: any) => {
  return isColleague === true || !isNew ? (
    <>
      <div
        className={`${
          status.toLowerCase() === 'disable' && action === 'save'
            ? 'text-gray-400'
            : ''
        } flex text-sm`}
      >
        {cardHolderName}
      </div>
    </>
  ) : (
    <div className="p-field">
      <InputText
        defaultValue="Enter Last Name"
        cardId={cardId}
        valueType="cardName"
        aria-describedby="cardname2-help"
      />
      <InputText
        defaultValue="Enter Post Code"
        cardId={cardId}
        valueType="cardPostCode"
        aria-describedby="cardpostcode2-help"
      />
    </div>
  )
}

export const EditCardNumberTemplate = ({
  isColleague,
  cardNumber,
  status,
  showAnchor,
  saved,
  cardId,
  action,
  moreCardNumber,
  isNew,
}: any) => {
  return (
    <>
      <div
        className={` text-sm  text-gray-700  ${
          showAnchor ? 'pb-3' : `${cardId === 0 ? 'pb-0' : 'pb-4'}`
        }`}
        style={{ width: '300px', position: 'relative', marginBottom:'20px' }}
      >
        {showAnchor ? (
          <span  style={{ width: '600px', position: 'absolute', top:'0', left: '0' }}>
            <span className="font-bold underline">
              Add Family & Friends Cards
            </span>{' '}
            <span className="font-light">
              {`(Please enter their ${brandName} card number below without
              spaces.)`}
            </span>
          </span>
        ) : (
          ''
        )}
      </div>
      <div>
        {isColleague === true || !isNew ? (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div
              className={`${
                status.toLowerCase() === 'disable' && action === 'save'
                  ? 'text-gray-400'
                  : ''
              } text-sm`}
            >
              {formatCard(cardNumber)}
            </div>
            {moreCardNumber && (
              <div className="p-d-flex p-ai-center">
                <Tooltip target={`.card${cardId}`}>
                  <p className="text-sm">{`${brandName} card number`}</p>
                  <p className="text-sm"> {formatCard(moreCardNumber)}</p>
                </Tooltip>
                <div className={`card${cardId} -mt-1`}>
                  <Icon MIcon={InfoIcon} color="#616161" />
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="p-field">
            <InputText
              defaultValue={`Enter ${brandName} Card No.`}
              cardId={cardId}
              valueType="cardNumber"
              aria-describedby="cardnumber2-help"
            />
          </div>
        )}
      </div>
    </>
  )
}
