import React from 'react'
import {
  GroupContainer,
  SearchContainer,
  AppContainer,
} from '../../../container'
import { SpinLoader } from '../../../component/common'
import AdminRoute from '../../../route/AdminRoute'
import { isAdminOrLP } from '../../../util/validation'
import ErrorRole from '../../../app/ErrorRole'
import consoleLog from '../../../util/consoleLog'

function Main() {
  const { role } = AppContainer.useContainer()
  consoleLog('Main','15','role : ', role)

  return isAdminOrLP(role) ? (
    <GroupContainer.Provider>
      <SearchContainer.Provider>
        <SpinLoader />
        <AdminRoute />
      </SearchContainer.Provider>
    </GroupContainer.Provider>
  ) : role ? (
    <ErrorRole />
  ) : null
}

export default Main
