import React, { useState, useEffect } from 'react'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { Icon } from '../common'
import SearchIcon from '@material-ui/icons/Search'
import { tertiary, disable } from '../../materialTheme/colors'
import { isValidSearchText } from '../../util/validation'

interface SearchProps {
  onSearch: any
}

const Search = ({ onSearch }: SearchProps) => {
  const [searchText, setSearchText] = useState('')
  const [error, setError] = useState(false)

  const handleTextChange = (event: any) => {
    setSearchText(event.target.value)
  }

  const handleClick = () => {
    if (isValidSearchText(searchText)) {
      onSearch(searchText)
      setError(false)
    } else {
      setError(true)
    }
  }

  useEffect(() => {
    if (searchText === '') {
      setError(false)
    }
  }, [searchText])

  return (
    <div className="w-full justify-center ">
      <TextField
        className="w-9/12 focus:outline-none mob:w-full mob:mb-4"
        id="outlined-basic"
        variant="outlined"
        value={searchText}
        placeholder="Search by Employee ID,Email Address,Card Number"
        onChange={handleTextChange}
        helperText={
          error ? 'Please enter a valid email, card number or employee id' : ''
        }
        error={error}
      />
      <Button
        className="w-3/12 h-14 focus:outline-none mob:w-full"
        variant="contained"
        color="primary"
        onClick={handleClick}
        startIcon={
          <Icon
            MIcon={SearchIcon}
            color={!searchText ? disable.primary : tertiary.tertiary}
            size={40}
          />
        }
        disabled={!searchText}
      >
        <span className=" text-white text-transform: capitalize text-lg">
          Search
        </span>
      </Button>
    </div>
  )
}
export default Search
