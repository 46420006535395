import React from 'react'
import { Container } from '../common'
import BlankHeader from '../../app/BlankHeader'

import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'

function GoogleSignIn({ firebaseObj }: any) {
  const emailDomain =
    process.env.REACT_APP_ENV === 'prd'
      ? 'morrisonsplc.co.uk'
      : 'test.morrisonsplc.co.uk'

  const uiConfig = {
    signInFlow: 'popup',
    signInOptions: [
      {
        provider: firebaseObj
          ? firebaseObj.auth.GoogleAuthProvider.PROVIDER_ID
          : '',
        customParameters: { hd: emailDomain },
      },
    ],
    callbacks: {
      signInSuccessWithAuthResult: () => false,
    },
  }

  return firebaseObj && uiConfig ? (
    <div>
      <BlankHeader />
      <Container>
        <div>
          <h1 className="flex justify-center h-8 mt-8">
            Sign in to Colleague Discount Club
          </h1>
          <StyledFirebaseAuth
            uiConfig={uiConfig}
            firebaseAuth={firebaseObj.auth()}
          />
        </div>
      </Container>
    </div>
  ) : null
}

export default GoogleSignIn
