import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import AppRoute from '../route/AppRoute'
import Header from '../component/header/Header'
import Error from './Error'
import Loading from './Loading'
import { AppContainer } from '../container/index'
import { performanceTesting } from '../appConfig'
import PTMain from '../page/pt/Main'
import GoogleSignIn from '../component/sso/GoogleSignIn'
import consoleLog from '../util/consoleLog'
import BlankHeader from './BlankHeader'
import Maintenance from './Maintenance'

function AuthenticatedApp() {
  const {
    authenticated,
    isLoading,
    isSSO,
    firebaseObj,
    fireStoreMaintenance,
  } = AppContainer.useContainer()
  consoleLog('AuthenticatedApp', '20', 'authenticated : ', authenticated, isLoading, isSSO)

  return fireStoreMaintenance && fireStoreMaintenance?.showMaintenance ? <>
    <BlankHeader />
    <Maintenance maintenanceMessage={fireStoreMaintenance} />
  </> :isSSO === false ? (
    <GoogleSignIn firebaseObj={firebaseObj} />
  ) : authenticated ? (
    <Router>
      <Header />
      <AppRoute />
    </Router>
  ) : isLoading ? (
    <Loading />
  ) : performanceTesting ? (
    <Router>
      <PTMain />
    </Router>
  ) : (
    <Error />
  )
}

export default AuthenticatedApp
