import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import NonColleagueRoute from '../route/NonColleagueRoute'
import { NonColleagueContainer } from '../container'

function UnAuthenticatedApp() {
  return (
    <Router>
      <NonColleagueContainer.Provider>
        <NonColleagueRoute />
      </NonColleagueContainer.Provider>
    </Router>
  )
}

export default UnAuthenticatedApp
