import React, { useState } from 'react'
import { GroupContainer } from '../../container'

const StatusDropdown = ({ action, status }: any) => {
  const { setGroupStatus } = GroupContainer.useContainer()
  const [value, setValue] = useState(status)
  const options = ['Active', 'Inactive']

  const setDropDown = (value: string) => {
    setValue(value)
    setGroupStatus(value)
  }

  return action === 'save' || action === 'cancel' ? (
    <span>{value}</span>
  ) : (
    <select
      value={value}
      onChange={(event) => {
        setDropDown(event.target.value)
      }}
      className="w-full bg-white text-xs h-6 border-gray-400 border  focus:outline-none"
    >
      {options.map((option, index) => (
        <option key={index} value={option}>
          {option}
        </option>
      ))}
    </select>
  )
}

const StatusTemplate = ({ groupName, action, status }: any) => {
  return (
    <StatusDropdown groupName={groupName} action={action} status={status} />
  )
}

export default StatusTemplate
