import React, { useState } from 'react'
import NonColleagueSearchOption from './NonColleagueSearchOption'
import NonColleagueSearchName from './NonColleageSearchName'

const NonColleagueSearch = ({ onSearch, onError, colleagueType }: any) => {
  const [nonColleagueOption, setNonColleagueOption] = useState<any>(null)

  return (
    <div className="flex flex-wrap">
      <NonColleagueSearchOption onChange={setNonColleagueOption} />
      <NonColleagueSearchName
        onSearch={onSearch}
        onError={onError}
        nonColleagueOption={nonColleagueOption}
        colleagueType={colleagueType}
      />
    </div>
  )
}
export default NonColleagueSearch
