import React, { useState, useRef, useEffect } from 'react'
import { Link } from 'react-router-dom'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import MenuIcon from '@material-ui/icons/Menu'
import CloseIcon from '@material-ui/icons/Close'
import { Icon } from '../common'

interface HamburgerMenuProps {
  role: string
}

function HamburgerMenu({ role }: HamburgerMenuProps) {
  const [open, setOpen] = useState(false)
  const anchorRef = useRef<any>(null)

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  function handleListKeyDown(event: any) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open)
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])

  return (
    <div className="flex">
      <i
        className="mr-4 flex justify-center items-center"
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        {open ? (
          <Icon MIcon={CloseIcon} color="#ffffff" size={40} />
        ) : (
          <Icon MIcon={MenuIcon} color="#ffffff" size={40} />
        )}
      </i>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{ zIndex: 100 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'center top',
              height: '85vh',
              width: '250px',
              marginTop: '5rem',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem onClick={handleClose}>
                    <Link to="/home" className="w-full">
                      Home
                    </Link>
                  </MenuItem>
                  {role === 'ADMIN' && (
                    <MenuItem onClick={handleClose}>
                      <Link to="/admin/home" className="w-full">
                        Admin Central
                      </Link>
                    </MenuItem>
                  )}
                  {role === 'LPADMIN' && (
                    <MenuItem onClick={handleClose}>
                      <Link to="/admin/home" className="w-full">
                        LP Team
                      </Link>
                    </MenuItem>
                  )}
                  {role === 'STOREADMIN' && (
                    <MenuItem onClick={handleClose}>
                      <Link to="/storeadmin/home" className="w-full">
                        People Team
                      </Link>
                    </MenuItem>
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  )
}

export default HamburgerMenu
