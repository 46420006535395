import React from 'react'
import { GroupContainer } from '../../container'
import { DataTable } from 'primereact/datatable'
import { PrimeColumn, ContentLoader } from '../common'
import Button from '@material-ui/core/Button'
import StatusTemplate from './GroupStatus'
import {
  GroupNameTemplate,
  ClubNameTemplate,
  StartDateTemplate,
  EndDateTemplate,
  EditTemplate,
} from './CellTemplate'
import consoleLog from '../../util/consoleLog'
import { brandName } from '../../util/brandConfig'

function GroupList() {
  const {
    groupDetailsAction,
    loading,
    errorMessage,
  } = GroupContainer.useContainer()

  const headerStyle = {
    fontSize: '14px',
    fontWeight: 'bold',
    itemAlign: 'center',
  }
  const cellCommonStyle = { fontSize: '14px', fontWeight: 'normal' }

  consoleLog('GroupList','30', groupDetailsAction)

  return (
    <div className="mb-16 mt-10">
      {errorMessage && (
        <div className="text-base text-red-500 h-10">{errorMessage}</div>
      )}
      <div className="font-bold">
        <h1>Manage Membership Group ({(groupDetailsAction || []).length})</h1>
      </div>

      <div className="mt-2">
        {!loading && <ContentLoader />}
        {loading && Array.isArray(groupDetailsAction) && (
          <DataTable value={groupDetailsAction}>
            <PrimeColumn
              field="groupName"
              style={cellCommonStyle}
              header="Group Name"
              headerStyle={{ ...headerStyle, width: '250px' }}
              body={GroupNameTemplate}
            />
            <PrimeColumn
              field="clubName"
              style={cellCommonStyle}
              header="Club Name"
              headerStyle={{ ...headerStyle, width: '200px' }}
              body={ClubNameTemplate}
            />

            <PrimeColumn
              field="startDate"
              style={cellCommonStyle}
              header="Start Date"
              headerStyle={{ ...headerStyle, width: '100px' }}
              body={StartDateTemplate}
            />
            <PrimeColumn
              field="endDate"
              style={cellCommonStyle}
              header="End Date"
              headerStyle={{ ...headerStyle, width: '100px' }}
              body={EndDateTemplate}
            />
            <PrimeColumn
              field="status"
              body={StatusTemplate}
              style={cellCommonStyle}
              header="Status"
              headerStyle={{ ...headerStyle, width: '70px' }}
            />
            <PrimeColumn
              field="Action"
              style={cellCommonStyle}
              body={EditTemplate}
              header="Action"
              headerStyle={{ ...headerStyle, width: '70px' }}
            />
          </DataTable>
        )}
      </div>

      {(groupDetailsAction || []).length > 10 && (
        <div className="flex justify-end text-white-800 mt-4">
          <Button color="primary" variant="outlined">
            {`View ${brandName} Groups`}
          </Button>
        </div>
      )}
    </div>
  )
}
export default GroupList
