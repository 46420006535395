import React, { useState} from 'react'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

interface onChangeProps {
  onChange: any
}

const ColleagueSearchOption = ({ onChange }: onChangeProps) => {
    const [defaultValue, setDefaultValue] = useState('Colleague')
  
  const handleChange = (event: any) => {
    const value = event.target.value
    setDefaultValue(value)
    onChange(value)
  }


  return (
    <div className="lg:mr-4 mob:my-4  mob:w-full">
      <Select
        value={defaultValue}
        className="h-14 w-56 pl-4 focus:outline-none border rounded border-gray-300 mob:w-full"
        onChange={handleChange}
      >
        <MenuItem value="Colleague" className="h-14 mob:mr-1">
          Colleague
        </MenuItem>
        <MenuItem value="Non-Colleague" className="h-14 mob:mr-1">
          Non-Colleague
        </MenuItem>
      </Select>
    </div>
  )
}
export default ColleagueSearchOption
