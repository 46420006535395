import React from 'react'
import Search from './Search'
import { SearchContainer } from '../../container'

const ColleagueSearch = ({ onSearch, colleagueType }: any) => {
  const { getSearchResult } = SearchContainer.useContainer()

  const handleSearch = async (searchText: string) => {
    const searchResult = await getSearchResult(searchText, '', {
      colleagueType,
    })
    onSearch(searchResult)
  }

  return <Search onSearch={handleSearch} />
}
export default ColleagueSearch
