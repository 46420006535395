import React from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { CardContainer } from '../../../container'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
  })
)

export default function CancelledCardsMob() {
  const { cancelledCards } = CardContainer.useContainer()

  const classes = useStyles()
  const [expanded, setExpanded] = React.useState<string | false>(false)

  const handleChange = (panel: string) => (
    event: React.ChangeEvent<{}>,
    isExpanded: boolean
  ) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <div>
      <div className="mt-4">
        <div className="mb-4">Colleague Cancelled Cards</div>
        <div className="flex h-8 items-center w-full mob:text-sm">
          <div className="w-3/5 ">Cardholder Name</div>
          <div className="w-2/5">Date of Cancellation</div>
        </div>
      </div>
      <div className={classes.root}>
        {(cancelledCards || []).map((card: any, key: any) => (
          <Accordion
            expanded={expanded === 'panel1'}
            onChange={handleChange('panel1')}
            key={key}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <div className="w-1/2 text-left font-bold">
                {card.cardHolderName}{' '}
              </div>
              <div className="w-1/2 text-right font-bold">
                {' '}
                {card.cancelledDate}
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div className="w-full">
                <div className="w-full text-left text-gray-800">
                  Card Number
                </div>
                <div className="w-full text-left font-bold mb-4">
                  {card.cardNumber}
                </div>
                <div className="w-full text-left text-gray-800">
                  Discount Received
                </div>
                <div className="w-full text-left font-bold mb-4">
                  {card.discountReceived}
                </div>
                <div className="w-full text-left text-gray-800">Status</div>
                <div className="w-full text-left font-bold mb-4">
                  {card.status}
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </div>
  )
}
