import React, { useEffect } from 'react'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {
  makeStyles,
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles'

import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import { Currency } from '../../common'
import { AppContainer, CardContainer } from '../../../container'
import { formatCard, formatDate } from '../../../util/format'
import { Tooltip } from 'primereact/tooltip'
import { Icon } from '../../common'
import InfoIcon from '@material-ui/icons/Info'
import {
  ActionTemplate,
  CellTemplate,
  StatusTemplate,
} from './CardTablesMobCellTemplate'
import { Button } from '@material-ui/core'
import { isAdmin } from '../../../util/validation'
import DeleteCard from './DeleteCard'
import CancelledCardsMob from '../cancelledCards/CancelledCardsMob'
import DialogApiError from '../../common/dialogApiError/ApiDialog'
import { brandName } from '../../../util/brandConfig'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
  })
)

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    headerText: {
      padding: '0 10px',
    },
  })

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string
  children: React.ReactNode
  onClose: () => void
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" className={classes.headerText}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

const Card = ({ cardDetail }: any) => {
 const {  isColleagueTerminated } = AppContainer.useContainer()
  return (
    <div className={`flex flex-col  w-full mob:text-sm ${isColleagueTerminated()? 'text-gray-400' : 'text-gray-800'}`}>
      {!cardDetail.isNew ? (
        <>
          <div className="w-full text-left text-gray-800">Card Number</div>
          <div className="flex">
            <div className="text-left font-bold mb-4 mr-1">
              {cardDetail.cardNumber !== ''
                ? formatCard(cardDetail.cardNumber)
                : '-'}
            </div>
            {cardDetail.moreCardNumber && (
              <div className="p-d-flex p-ai-center">
                <Tooltip target={`.card${cardDetail.cardId}`} position="top">
                  <p className="text-sm">{`${brandName} card number`}</p>
                  <p className="text-sm">
                    {' '}
                    {formatCard(cardDetail.moreCardNumber)}
                  </p>
                </Tooltip>
                <div className={`card${cardDetail.cardId}`}>
                  <Icon MIcon={InfoIcon} color="#616161" />
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          <div className="w-full text-left text-gray-800 flex flex-col ">
            Card Number
            <span className="mb-2 font-light text-gray-400">
              {`(Please enter their ${brandName} card number below without
              spaces.)`}
            </span>
          </div>
          <div className="w-full text-left font-bold mb-4">
            <CellTemplate
              defaultValue={`Enter ${brandName} Card Number`}
              cardId={cardDetail.cardId}
              valueType="cardNumber"
              aria-describedby="cardname2-help"
            />
          </div>
          <div className="w-full text-left text-gray-800">
            Cardholder Last Name
          </div>
          <div className="w-full text-left font-bold mb-4">
            <CellTemplate
              defaultValue="Enter Last Name"
              cardId={cardDetail.cardId}
              valueType="cardName"
              aria-describedby="cardname2-help"
            />
          </div>
          <div className="w-full text-left text-gray-800">Post Code</div>
          <div className="w-full text-left font-bold mb-4">
            <CellTemplate
              defaultValue="Enter Post Code"
              cardId={cardDetail.cardId}
              valueType="cardPostCode"
              aria-describedby="cardname2-help"
            />
          </div>
        </>
      )}
      <div className="w-full text-left text-gray-800">Card Allowance</div>
      <div className="w-full text-left font-bold mb-4">
        {' '}
        {cardDetail.cardAllowance !== '' ? (
          <Currency amount={cardDetail.cardAllowance} />
        ) : (
          '-'
        )}
      </div>
      <div className="w-full text-left text-gray-800">
        Card Allowance Refresh Date
      </div>
      <div className="w-full text-left font-bold mb-4">
        {cardDetail.refreshDate !== ''
          ? formatDate(cardDetail.refreshDate)
          : '-'}
      </div>
      <div className="w-full text-left text-gray-800">Discount Received</div>
      <div className="w-full text-left font-bold mb-4">
        {' '}
        {cardDetail.amountUsed !== '' ? (
          <Currency amount={cardDetail.amountUsed} />
        ) : (
          '-'
        )}
      </div>
      <div className="w-full text-left text-gray-800">Status</div>
      <div className="w-full text-left font-bold mb-4 capitalize">
        <StatusTemplate
          cardId={cardDetail.cardId}
          action={cardDetail.action}
          status={cardDetail.status}
          isNew={cardDetail.isNew}
        />
      </div>
      <ActionTemplate
        cardId={cardDetail.cardId}
        cardNo={cardDetail.cardNumber}
        colleague={cardDetail.isColleague}
        action={cardDetail.action}
        isNew={cardDetail.isNew}
        fAndfLimitReached={cardDetail.fAndfLimitReached}
        status={cardDetail.status}
        cardStatusAdmin={cardDetail.cardStatusAdmin}
        isAdminDisabled={cardDetail.isAdminDisabled}
        isColleague={cardDetail.isColleague}
      />
    </div>
  )
}

const CardTablesMob = () => {
  const {
    cardDetailsAction,
    resetCardError,
    openDialog,
    setOpenDialog,
    callCancelOrderNewCardBFF,
    updateAction,
    setCardError,
    showDialogApiCardError
  } = CardContainer.useContainer()
  const { colleagueDetail, role, isColleagueTerminated } = AppContainer.useContainer()
  const classes = useStyles()
  const [expanded, setExpanded] = React.useState<string | false>(false)
  const [selectedCardId, setSelectedCardId] = React.useState<number | any>(0)

  const [selectedCard, setSelectedCard] = React.useState<any>(null)
  const handleChange = (panel: string, cardId: number) => (
    event: React.ChangeEvent<{}>,
    isExpanded: boolean
  ) => {
    setExpanded(isExpanded ? panel : false)
    setSelectedCardId(cardId)
    resetCardError(cardId)
    setCardError(false)
  }

  const handleClose = () => {
    setOpenDialog(false)
  }

  const handleConfirmDelete = async () => {
    setOpenDialog(false)
    const payload = {
      cardNumber: selectedCard.cardNumber,
      empId: colleagueDetail.employeeId,
      custNo: '',
      action: 'delete',
      actionDone: '',
      role: 'f&f',
      actionDoneBy: isAdmin(role),
    }
    await callCancelOrderNewCardBFF(payload)
    updateAction(selectedCardId, 'delete')
  }

  useEffect(() => {
    if (openDialog) {
      let card = (cardDetailsAction || []).find(
        (item: any) => item.cardId === selectedCardId
      )
      setSelectedCard(card)
    }
  }, [openDialog, cardDetailsAction, selectedCardId])

  return (
    <div>
      {showDialogApiCardError && <DialogApiError/>} 
      <div className="flex h-8 items-center w-full mob:text-sm">
        <div className="w-3/5 ">Cardholder Name</div>
        <div className="w-2/5">Discount Remaining</div>
      </div>
      <div className={classes.root}>
        {(cardDetailsAction || []).map((cardDetail: any, index: any) => {
          const panel = 'panel' + index
          return (
            <Accordion
              key={index}
              expanded={expanded === panel}
              onChange={handleChange(panel, cardDetail.cardId)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                className="h-20 "
              >
                <div className={`flex h-8 items-center w-full font-bold mob:text-sm ${isColleagueTerminated() ? 'text-gray-400' : ''}`}>
                  <div className="w-2/3">
                    {!cardDetail.isNew
                      ? cardDetail.cardHolderName
                      : 'Add Friend / Family'}
                  </div>
                  <div className="w-1/3 text-right">
                    {cardDetail.amountRemaining !== '' ? (
                      <Currency amount={cardDetail.amountRemaining} />
                    ) : (
                      '-'
                    )}
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <Card cardDetail={cardDetail} />
              </AccordionDetails>
            </Accordion>
          )
        })}
      </div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openDialog}
        className="mob:h-full mob:w-full"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <span className="text-xl">Delete Family & Friend card</span>
        </DialogTitle>
        <DialogContent>
          <DeleteCard cardNumber={selectedCard?.cardNumber}></DeleteCard>
        </DialogContent>

        <DialogActions>
          <div className="mob:flex mob:w-8/12 mob:justify-center mob:m-auto">
            <Button
              autoFocus
              onClick={handleConfirmDelete}
              color="primary"
              className="focus:outline-none mb-4 mob:w-11/12"
              variant="contained"
            >
              Confirm
            </Button>
          </div>
        </DialogActions>
      </Dialog>

      <CancelledCardsMob />
    </div>
  )
}

export default CardTablesMob
