import React from 'react'
import { Currency } from '../../common'

export const CardNumberTemplate = ({ cardNumber }: any) => {
    return <div>{cardNumber}</div>
}

export const DiscountReceivedTemplate = ({ discountReceived }: any) => {
    return <div className='flex justify-center'> {discountReceived !== '' ? <Currency amount={discountReceived} /> : '-'}</div>
}

export const StatusTemplate = ({ status }: any) => {
    return <div className="capitalize text-red-600">{status}</div>
}

export const CancelledDateTemplate = ({ cancelledDate }: any) => {
    return <div>{cancelledDate}</div>
}
