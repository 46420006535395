import React from 'react'
import { brandName } from '../../util/brandConfig'

interface InfoProps {}

const Info: React.FC<InfoProps> = () => {
  return (
    <div className="mt-14 sm:mt-20 mb-10 leading-tight md:pr-48">
      <h3 className="mt-6 text-lg font-semibold">
        {`Link your Morrisons Discount Club card with your ${brandName} card`}
      </h3>
      <p className="mt-6">
        {`Linking your Morrisons Discount Club and ${brandName} Card number will mean that
        you will receive tailored offers and promotions. You will need to have a
        ${brandName} account to be able to do this and the ${brandName} account
        needs to be in your name.`}
      </p>
      <p className="mt-6"> Please enter your Discount card details below without spaces.</p>
    </div>
  )
}

export default Info
