import React from 'react'
import { DataTable } from 'primereact/datatable'
import { PrimeColumn } from '../../common'
import {
  ShoppingDateTemplate,
  StoreNameTemplate,
  TotalShoppingAmountTemplate,
  DiscountReceivedTemplate,
} from '../cardTable/TransactionTemplate'
import { CardContainer } from '../../../container'

const TransactionDetails = () => {
  const { cardTransactionDetails } = CardContainer.useContainer()

  const headerStyle = { fontSize: '14px', fontWeight: 'bold' }
  const cellCommonStyle = { fontSize: '14px', fontWeight: 'normal' }
  const cardTransactions = cardTransactionDetails?.cardTransaction
  
  return cardTransactionDetails ? (
    <div className="h-32" style={{ margin: '0 -24px' }}>
      <div className="mb-4">
        You can view last {(cardTransactions || []).length} transactions for
        colleague card
      </div>

      <div className="flex mt-2 w-1/2 text-gray-600 text-base">
        <div className="w-1/2 text-left">Card No.</div>
        <div className="w-1/2 text-left">Cardholder Name</div>
      </div>

      <div className="flex w-1/2 mb-4 text-sm">
        <div className="w-1/2 text-left">
          {cardTransactionDetails.cardNumber}
        </div>
        <div className="w-1/2 text-left">
          {cardTransactionDetails.cardHolderName}
        </div>
      </div>

      <DataTable value={cardTransactions}>
        <PrimeColumn
          field="cardNumber"
          body={ShoppingDateTemplate}
          style={cellCommonStyle}
          headerStyle={{ ...headerStyle, width: '180px' }}
          header="Date of Shopping"
        />
        <PrimeColumn
          field="cardNumber"
          body={StoreNameTemplate}
          style={cellCommonStyle}
          headerStyle={{ ...headerStyle, width: '180px' }}
          header="Store Name/Location"
        />
        <PrimeColumn
          field="cardNumber"
          body={TotalShoppingAmountTemplate}
          style={cellCommonStyle}
          headerStyle={{ ...headerStyle, width: '180px' }}
          header="Total Shopping Amount"
        />
        <PrimeColumn
          field="cardNumber"
          body={DiscountReceivedTemplate}
          style={cellCommonStyle}
          headerStyle={{ ...headerStyle, width: '180px' }}
          header="Discount Received"
        />
      </DataTable>
    </div>
  ) : (
    <div>No transaction found !</div>
  )
}

export default TransactionDetails
