import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

import { AppContainer, CardContainer } from '../../container'
import { Container } from '../../component/common'
import { LinkMoreCardSuccess } from './LinkMoreCardSuccess'
import Help from './Help'
import consoleLog from '../../util/consoleLog'
import DialogApiError from '../../component/common/dialogApiError/ApiDialog'
import { brandName } from '../../util/brandConfig'

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  root: {
    margin: 0,
  },
}))

function LinkMoreCard({ empId }: any) {
  const classes = useStyles()
  const { employeeId } = AppContainer.useContainer()
  const {
    callLinkMoreCard,
    linkMoreCardResponse,
    showDialogApiCardError, 
  } = CardContainer.useContainer()
  const [clickButton, setClickButton] = useState(false)
  const [moreCardNumber, setMoreCardNumber] = useState('')

  const isValidMoreCard = (cardNumber: string) => {
    if (!cardNumber) return true
    const moreCardValidation = /^[0-9]{19}$/
    return moreCardValidation.test(cardNumber)
  }

  const handleOnBlur = () => {
    isValidMoreCard(moreCardNumber)
  }

  const disableSubmit = !moreCardNumber || !isValidMoreCard(moreCardNumber)

  async function submitMoreCard(card: string) {
    setClickButton(false)
    const userEmpId = empId ? empId : employeeId
    await callLinkMoreCard({ moreCardNumber: card, empId: userEmpId })
    setClickButton(true)
  }

  const isMoreCardError = () => {
    return (
      clickButton === true &&
      linkMoreCardResponse &&
      (linkMoreCardResponse.statusFlag ||
        !linkMoreCardResponse.isValidMoreCardNumber ||
        !linkMoreCardResponse.isValidLastName ||
        !linkMoreCardResponse.isValidFirstName)
    )
  }
  consoleLog(
    'linkMoreCard',
    '60',
    'linkMoreCardResponse : ',
    linkMoreCardResponse
  )

  return (
    <Container>
      <div className={classes.root}>
      {showDialogApiCardError && <DialogApiError/>} 
        <section className="m-4">
          <h3 className="my-8 text-lg font-semibold">
            {`Link your ${brandName} card number with your Colleague Discount Club`}
          </h3>
          <div className="mb-8 leading-tight">
            <p className="mb-4">
              {`Linking your Colleague Discount Club and ${brandName} Card number
              will mean that you will receive tailored offers and promotions.
              You will need to have a ${brandName} account to be able to do this
              and the ${brandName} account needs to be in your name.`}
            </p>
            <p className="mb-4">
              {`Please enter your ${brandName} card number below without spaces.`}
            </p>
          </div>
          <div className="flex flex-col justify-start sm:flex-row sm:items-center">
            <div className="lg:h-28 w-4/12 mob:w-11/12">
              <InputLabel className="mb-1 text-sm font-semibold">
                {`${brandName} Card Number*`}
              </InputLabel>
              <TextField
                size="small"
                className="my-4 sm:mr-8 w-11/12"
                variant="outlined"
                error={!isValidMoreCard(moreCardNumber)}
                value={moreCardNumber}
                onChange={(e) => {
                  setMoreCardNumber(e.target.value)
                  setClickButton(false)
                }}
                placeholder={`Enter ${brandName} Card Number`}
                onBlur={handleOnBlur}
              />
              <div className="text-xs -mt-3 mb-3 sm:mb-0"></div>
              {!isValidMoreCard(moreCardNumber) && (
                <div className="text-xs text-error mt-3 mb-3 sm:mb-0">
                  {`Please enter a valid ${brandName} Card number`}
                </div>
              )}

              {clickButton === true ? (
                linkMoreCardResponse && linkMoreCardResponse.apiFailedMessage ? null :  linkMoreCardResponse.isCardMapped ? (
                  <div className="text-xs text-error mt-3 mb-3 sm:mb-0">
                    This card number is already linked.
                  </div>
                ) : linkMoreCardResponse &&
                  linkMoreCardResponse.statusFlag ? null : !linkMoreCardResponse.isValidMoreCardNumber ? (
                  <div className="text-xs text-error mt-3 mb-3 sm:mb-0">
                    {`The ${brandName} card number doesn't match our records,
                    please check and try again.`}
                  </div>
                ) : (
                  linkMoreCardResponse &&
                  (!linkMoreCardResponse.isValidLastName ||
                    !linkMoreCardResponse.isValidFirstName) && (
                    <div className="text-xs text-error mt-3 mb-3 sm:mb-0">
                      {`The name on this ${brandName} card doesn't match our
                      record, please try again.`}
                    </div>
                  )
                )
              ) : null}
            </div>
            {linkMoreCardResponse && !linkMoreCardResponse.statusFlag && (
              <Button
                variant="contained"
                color="primary"
                size="large"
                disabled={disableSubmit}
                className={`${
                  !isValidMoreCard(moreCardNumber) ? '' : 'sm:mt-2'
                } w-36 h-11 mt-4`}
                onClick={() => {
                  submitMoreCard(moreCardNumber)
                }}
              >
                Submit
              </Button>
            )}
          </div>
          {linkMoreCardResponse && linkMoreCardResponse.statusFlag && (
            <LinkMoreCardSuccess />
          )}
          {isMoreCardError() && <Help />}
        </section>
      </div>
    </Container>
  )
}

export default LinkMoreCard
