import React from 'react'
import { InputField } from './'
import Button from '@material-ui/core/Button'
import consoleLog from '../../util/consoleLog'
import { brandName } from '../../util/brandConfig'

const NonColleagueLinkMoreCard = ({
  moreCardNumber,
  handleMoreCardNumber,
  moreCardNumberError,
  validateMoreCard,
}: any) => {
  const errorMessageFiled = moreCardNumberError.moreCardNumber
    ? moreCardNumberError.moreCardNumber
    : moreCardNumberError.lastName
  consoleLog(
    'NonColleagueLinkMoreCard',
    '15',
    'moreCardNumberError : ',
    moreCardNumberError
  )
  consoleLog(
    'NonColleagueLinkMoreCard',
    '16',
    'errorMessageFiled : ',
    errorMessageFiled
  )
  return (
    <div className=" flex items-center justify-center lg:flex-row lg:items-center">
      <div className="w-1/2 mob:w-full">
        <div className="w-full">
          <p className="mt-2">
            {`Please enter your ${brandName} card number without spaces to link
            your Morrisons Discount card.`}
          </p>
          <div className=" flex mob:flex-wrap  justify-center sm:pr-20 sm:h-28 mt-4">
            <InputField
              type="number"
              value={moreCardNumber}
              label={`${brandName} Card Number*`}
              onChange={(moreCardNumber) => {
                handleMoreCardNumber(moreCardNumber)
              }}
              placeholder={`Enter ${brandName} Card Number`}
              errorMessage={errorMessageFiled}
              className="mob:w-11/12"
            />
            <Button
              size="large"
              color="primary"
              variant="contained"
              className="ml-4 mt-6 h-10 mob:w-11/12"
              // disabled={status}
              onClick={() => {
                validateMoreCard()
              }}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NonColleagueLinkMoreCard
