import React, { useEffect } from 'react'
import CardTables from './cardTable/CardTables'
import { MuiTab } from '../common'
import TransactionDetails from './transactions/TransactionDetails'
import { AppContainer, CardContainer } from '../../container'
import TransactionListMob from './transactions/TransactionListMob'
import CardTablesMob from './cardTable/CardTablesMob'
import CardTablesDisable from './cardTable/CardTablesDisable'

const Transaction = () => {
  const { isMobile } = AppContainer.useContainer()
  return isMobile ? <TransactionListMob /> : <TransactionDetails />
}

const CardInformation = () => {
  const { isMobile, isColleagueTerminated } = AppContainer.useContainer()
  return isMobile ? <CardTablesMob /> : isColleagueTerminated() ? <CardTablesDisable/> :< CardTables />
}

const CardDetails = ({ empId }: any) => {
  const { employeeId } = AppContainer.useContainer()

  const {
    getColleagueCardDetails,
    getColleagueCardTransactionDetails,
    setIsActionAdmin,
  } = CardContainer.useContainer()

  useEffect(() => {
    if (!empId) {
      getColleagueCardDetails('')
      getColleagueCardTransactionDetails(employeeId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeId])

  useEffect(() => {
    if (empId) {
      getColleagueCardDetails(empId)
      getColleagueCardTransactionDetails(empId)
    }
    setIsActionAdmin(empId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [empId])

  const tabConfig = [
    {
      header: 'Discount Card Information',
      Content: <CardInformation />,
    },
    {
      header: 'Transactions',
      Content: <Transaction />,
    },
  ]

  return <MuiTab tabConfig={tabConfig} />
}

export default CardDetails
