import React from 'react'
import { Avatar } from '../common'

interface UserHeaderInfoProps {
  imgSrc: string
  firstName: string
  lastName: string
  displayName: string
}

function UserHeaderInfo({
  imgSrc,
  firstName,
  lastName,
  displayName,
}: UserHeaderInfoProps) {
  return (
    <div className="flex">
      <Avatar imgSrc={imgSrc} />
      <div className="text-left mt-2">
        <h4 className="text-sm">Welcome to Colleague Discount Club</h4>
        <h3 className="text-xl">
          {displayName ? displayName : firstName + ' ' + lastName}
        </h3>
      </div>
    </div>
  )
}

export default UserHeaderInfo
