import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import { HeadingAndText, Container, ContentLoader } from '../common'
import { AppContainer, CardContainer } from '../../container'

import {
    createStyles,
    Theme,
    withStyles,
    WithStyles,
} from '@material-ui/core/styles'

import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import OrderNewCard from './orderNewCard/OrderNewCard'
import { isAdmin, isCentralAdmin } from '../../util/validation'
import DialogApiError from '../common/dialogApiError/ApiDialog'
import { brandName } from '../../util/brandConfig'

const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
    })

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string
    children: React.ReactNode
    onClose: () => void
}

const CancelCardDisableMsg = () => {
    const { isMobile } = AppContainer.useContainer()
    const width = isMobile ? '100%' : '500px'
    return (
        <div style={{ width }}>
            <div className="flex w-full">
                <div className="w-1/12 "></div>
            </div>
            <div> 
                You have requested a new discount card in the last 28 days, you are not able to request another card in this time period.
            </div>
            <div className="mt-4">
                {`If you have already linked your ${brandName} account, your new discount card will take a couple of weeks to arrive at your home address, but you will be able to use the digital card in the ${brandName} app in the meantime. If you haven't linked your ${brandName} account, your card will be sent to your site/store People team.`}
            </div>
        </div>
    )
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    )
})

const DialogContent = withStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent)

const DialogActions = withStyles((theme: Theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions)

function ColleagueDetails({ empId }: any) {
    const {
        colleagueDetail,
        isLoading,
        getColleagueDetailsBFF,
        role,
        isColleagueTerminated,
    } = AppContainer.useContainer()
    //   const [disableCancelButton, setDisableCancelButton] = useState(false)
    const {
        getCancelOrderNewCards,
        callCancelOrderNewCardBFF,
        isColleagueCardCancelled,
        isLinkMoreCard,
        isColleagueCardCancelledShowPopup,
        showDialogApiCardError,
    } = CardContainer.useContainer()


    const [open, setOpen] = React.useState(false)

    const primaryCardNumber = getCancelOrderNewCards()

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleCancelCards = async () => {
        const payload = {
            cardNumber: primaryCardNumber,
            empId: colleagueDetail.employeeId,
            custNo: '',
            action: 'cancel',
            actionDoneBy: isAdmin(role),
        }
        await callCancelOrderNewCardBFF(payload)
        handleClose()
        // setDisableCancelButton(cancelOrderNewResp && (cancelOrderNewResp.apiFailedMessage ? false : true))
    }

    useEffect(() => {
        if (!empId) {
            getColleagueDetailsBFF('')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (empId) {
            getColleagueDetailsBFF(empId)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [empId])

    return (
        <>
        <div className="w-full  bg-gray-100   flex justify-center py-4">
            <Container>
                {showDialogApiCardError && <DialogApiError />}
                {isLoading ? (
                    <div className="mt-4">
                        <ContentLoader />
                    </div>
                ) : (
                        <div className="w-full text-xs bg-gray-100   flex justify-between flex-wrap">
                            <div className="w-9/12 flex flex-wrap text-left mob:w-full">
                                <HeadingAndText
                                    heading="Membership Group"
                                    text={colleagueDetail ? colleagueDetail.membershipGroup : ''}
                                    className="w-5/12"
                                />

                                <HeadingAndText
                                    heading="Colleague Name"
                                    text={colleagueDetail ? colleagueDetail.colleagueName : ''}
                                    className="w-3/12"
                                />

                                <HeadingAndText
                                    heading="Employee Id"
                                    text={colleagueDetail ? colleagueDetail.employeeId : ''}
                                    className="w-3/12"
                                />

                                <HeadingAndText
                                    heading="Email Address"
                                    text={colleagueDetail ? colleagueDetail.email : ''}
                                    className="w-5/12"
                                />

                                <HeadingAndText
                                    heading="Person Type"
                                    text={colleagueDetail ? colleagueDetail.personType : ''}
                                    className="w-3/12"
                                    capitalize={true}
                                />

                                <HeadingAndText
                                    heading="Location"
                                    text={colleagueDetail ? colleagueDetail.location : ''}
                                    className="w-3/12"
                                />
                                {
                                    colleagueDetail && colleagueDetail.isColleagueTerminate && <>
                                        <HeadingAndText
                                            heading="Leaver"
                                            text={colleagueDetail ? colleagueDetail.isColleagueTerminate ? 'Yes' : 'No' : ''}
                                            className="w-5/12"
                                        />
                                        <HeadingAndText
                                            heading="Terminate Date"
                                            text={colleagueDetail ? colleagueDetail.colleagueTerminateDate : ''}
                                            className="w-3/12"
                                        />
                                    </>
                                }
                            </div>
                            <div className="w-2/12 mt-4 mob:w-full mob:flex">
                                <div className="w-full mob:flex mob:w-1/2 mob:justify-center lg:justify-end ">
                                    {isColleagueTerminated() ? <Button
                                        variant="contained"
                                        color="primary"
                                        className="focus:outline-none mob:w-11/12 mob:p-0 capitalize lg:w-48"
                                        disabled={true}
                                    >
                                        Cancel / Order New Card
                </Button> : <Button
                                            variant="contained"
                                            color="primary"
                                            className="focus:outline-none mob:w-11/12 mob:p-0 capitalize lg:w-48"
                                            onClick={handleClickOpen}
                                            disabled={isCentralAdmin(role) ? false : isColleagueCardCancelled()}
                                        >
                                            Cancel / Order New Card
                </Button>
                                    }
                                </div>
                                <div className="w-full mob:flex mob:justify-center mob:w-1/2 ">
                                    {isLinkMoreCard() || isColleagueTerminated() ? (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className="focus:outline-none mt-8 mob:w-11/12 mob:mt-0 capitalize lg:w-48"
                                            disabled={true}
                                        >
                                           {`Link ${brandName} Card`}
                                        </Button>
                                    ) : (
                                            <Link
                                                to={
                                                    empId
                                                        ? `/colleague/linkmorecard/${empId}`
                                                        : './linkmorecard'
                                                }
                                                className="mob:w-11/12"
                                            >
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    className="focus:outline-none mt-8 mob:w-11/12 mob:mt-0 capitalize lg:w-48"
                                                >
                                                     {`Link ${brandName} Card`}
                    </Button>
                                            </Link>
                                        )}
                                </div>
                            </div>
                        </div>
                    )}
            </Container>

            {isColleagueCardCancelledShowPopup() && !isCentralAdmin(role)  ? <Dialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                className="mob:h-full mob:w-full"
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    <span className="text-xl capitalize"> </span>
                </DialogTitle>
                <DialogContent>
                    <CancelCardDisableMsg />
                </DialogContent>
                <DialogActions>
                    <div className="flex mob:w-8/12 mob:justify-center mob:m-auto justify-right ">
                        <Button
                            autoFocus
                            onClick={handleClose}
                            color="primary"
                            className="focus:outline-none mb-4 mob:w-11/12"
                            variant="contained"
                        >
                            Close
            </Button>
                    </div>
                </DialogActions>
            </Dialog> :

                <Dialog
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={open}
                    className="mob:h-full mob:w-full"
                >
                    <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                        <span className="text-xl capitalize">Cancel / Order New Card</span>
                    </DialogTitle>
                    <DialogContent>
                        <OrderNewCard cardNumber={primaryCardNumber} isAdmin={empId} />
                    </DialogContent>

                    <DialogActions>
                        <div className="mob:flex mob:w-8/12 mob:justify-center mob:m-auto">
                            <Button
                                autoFocus
                                onClick={handleCancelCards}
                                color="primary"
                                className="focus:outline-none mb-4 mob:w-11/12"
                                variant="contained"
                            >
                                Confirm
            </Button>
                        </div>
                    </DialogActions>
                </Dialog>
            }
        </div>
       
        </>
    )
}

export default ColleagueDetails
