import React, { useState } from 'react'
import { CardContainer } from '../../../container'
import { status as statusColor } from '../../../materialTheme/colors'
import consoleLog from '../../../util/consoleLog'

const StatusDropdown = ({
  action,
  status,
  cardId,
  saved,
  isNew,
  showAnchor,
}: any) => {
  const { updateCardStatus} = CardContainer.useContainer()


  const [value, setValue] = useState(status)
  const options = ['Active', 'Disable']

  const setDropDown = (value: string) => {
    setValue(value)
    updateCardStatus(value, cardId)
  }

  consoleLog('CardStatusTemplate', '16', 'isNew : ', isNew, ' status :', status)
  return action === 'save' ? (
    <span
      className={`text-sm capitalize block ${showAnchor ? 'mt-8' : ''}`}
      style={{
        color:
          status.toLowerCase() === 'disable'
            ? statusColor.disabled
            : statusColor.active,
      }}
    >
      {isNew ? '-' : status}
    </span>
  ) : (
    <select
      value={value}
      onChange={(event) => {
        setDropDown(event.target.value)
      }}
      className="w-full bg-white text-xs h-6 border-gray-400 border focus:outline-none"
    >
      {options.map((option, index) => (
        <option key={index} value={option}>
          {option}
        </option>
      ))}
    </select>
  )
}
const CardStatusTemplate = ({
  cardNumber,
  action,
  status,
  cardId,
  saved,
  isNew,
  showAnchor,
}: any) => {
  return status ? (
    <StatusDropdown
      cardId={cardId}
      cardNumber={cardNumber}
      action={action}
      status={status}
      saved={saved}
      isNew={isNew}
      showAnchor={showAnchor}
    />
  ) : (
    '-'
  )
}
export default CardStatusTemplate
