import React from 'react'
import { Switch, Route } from 'react-router-dom'

import LinkMoreCard from '../page/nonColleague/LinkMoreCardNonColleague'
import { NonColleagueContainer } from '../container'
import Maintenance from '../app/Maintenance'
import Header from '../component/nonColleague/Header'

function NonColleagueRoute() {
  const { fireStoreMaintenance } = NonColleagueContainer.useContainer()
  
  return (
    fireStoreMaintenance === null ?
    <Header />
    :
    fireStoreMaintenance && fireStoreMaintenance.showMaintenance ? <>
      <Header />
      <Maintenance maintenanceMessage={fireStoreMaintenance} />
    </> :
    <Switch>
      <Route path="/extendedfamily" exact={true} component={LinkMoreCard} />
    </Switch>
  )
}

export default NonColleagueRoute
