import React from 'react'
import { GroupContainer } from '../../container'
import { DataTable } from 'primereact/datatable'
import { PrimeColumn } from '../common'
import { EditTemplate, CurrencyTemplate } from './ActionTemplate'
import GoBackTo from '../adminHome/GoBackTo'

function GroupAllowance() {
  const { groupDetailsAction, allowanceError } = GroupContainer.useContainer()

  const headerStyle = {
    fontSize: '14px',
    fontWeight: 'bold',
    itemAlign: 'center',
  }
  const cellCommonStyle = { fontSize: '14px', fontWeight: 'normal' }
  return (
    <div className="mt-16">
      <GoBackTo title="Set Allowances" link="/admin" />
      {allowanceError && (
        <div className="mb-2 text-red-400">
          Please enter a valid yearly allowance
        </div>
      )}
      <div>
        {Array.isArray(groupDetailsAction) && (
          <DataTable value={groupDetailsAction}>
            <PrimeColumn
              field="groupName"
              style={cellCommonStyle}
              header="Group Name"
              headerStyle={{ ...headerStyle, width: '300px' }}
            />
            <PrimeColumn
              field="groupAllowance"
              style={cellCommonStyle}
              header="Yearly Allowance"
              body={CurrencyTemplate}
              headerStyle={{ ...headerStyle, width: '300px' }}
            />
            <PrimeColumn
              field="Action"
              style={cellCommonStyle}
              body={EditTemplate}
              header="Action"
              headerStyle={{ ...headerStyle, width: '50px' }}
            />
          </DataTable>
        )}
      </div>
    </div>
  )
}
export default GroupAllowance
