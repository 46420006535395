import React from 'react'

export const ShoppingDateTemplate = ({ dateShoped }: any) => {
  return <div>{dateShoped}</div>
}

export const StoreNameTemplate = ({ storeName, location }: any) => {
  return (
    <div>
      <div>{storeName}</div>
      <div>{location}</div>
    </div>
  )
}

export const TotalShoppingAmountTemplate = ({ totalAmount }: any) => {
  return <div>{totalAmount}</div>
}

export const DiscountReceivedTemplate = ({ discountReceived }: any) => {
  return <div>{discountReceived}</div>
}
