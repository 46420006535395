import React from 'react'
import { isCard } from '../../util/validation'
import { SearchContainer } from '../../container'


function TroubleShootClubs() {

    const { troubleshootFullResult } = SearchContainer.useContainer()
    const  clubs  = troubleshootFullResult ? troubleshootFullResult.clubs : null
    return (
       clubs ? <div className="mt-4 w-full">
            {
                 (Array.isArray(clubs) && clubs.length ) > 0 ?  
                (clubs || []).map((club: any, index: any) => {
                    return (club.clubID.length > 0) && <div className=''>
                        {isCard(club.accountID) ?
                            <span> The customer id / card number {club.accountID} is mapped to colleague discount club {club.clubID.join()}</span>
                            : <span> The customer id {club.accountID} is mapped to colleague discount club {club.clubID.join()}</span>
                        }
                    </div>
                })
                :<span>No CDS club available</span>
            }


        </div>: null
    )
}

export default TroubleShootClubs
