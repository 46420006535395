import React, { useEffect } from 'react'

import { Container } from '../../component/common'
import {
  Header,
  DiscountCardDetails,
  Info,
  Help,
  NonColleagueLinkMoreCard,
  RegistrationSuccess,
  SpinLoader,
} from '../../component/nonColleague'
import { NonColleagueContainer, CardContainer } from '../../container'
import consoleLog from '../../util/consoleLog'
import DialogApiErrorNonColleague from '../../component/common/dialogApiError/ApiDialogNonColleague'
import { brandName } from '../../util/brandConfig'

const LinkMoreCardNonColleague = () => {
    const {
    nonColleagueDetails,
    discountCardApiResp,
    setFirstName,
    setLastName,
    setMoreCardNumber,
    setDiscountCardNumber,
    moreCardApiResp,
    linkMoreCardNonColleagueBFF,
    validateCDSCardBFF,
    employeeId,
    showDialogApiNonColleagueCardError
  } = NonColleagueContainer.useContainer()

  const {
    lastName,
    firstName,
    discountCardNumber,
    moreCardNumber,
  } = nonColleagueDetails

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const createDiscountError = () => {
    const errorMsg: any = {
      discountCardNumber: null,
      firstName: null,
      lastName: null,
    }

    if (discountCardApiResp) {
        if (
        discountCardApiResp.error ||
        discountCardApiResp.isCardNumber === false
      ) {
        errorMsg.discountCardNumber =
          "The Discount card number doesn't match our records, please check and try again."
      }

      if (discountCardApiResp.isFirstName === false) {
        errorMsg.firstName =
          "The First Name on this Discount card doesn't match our records, please try again."
      }
      if (discountCardApiResp.isLastName === false) {
        errorMsg.lastName =
          "The Last Name on this Discount card doesn't match our records, please try again."
      }
    }
    return errorMsg
  }

  const createMoreCardError = () => {
    const errorMsg: any = {
      moreCardNumber: null,
      firstName: null,
    }

    consoleLog(
      'LinkMoreCardNonColleague',
      '77',
      'moreCardValidError : ',
      moreCardApiResp
    )

    if (moreCardApiResp) {

        if(moreCardApiResp.apiFailedMessage){

        }else {
            if (moreCardApiResp.isCardMapped === true) {
        errorMsg.moreCardNumber = 'This card number is already linked'
      } else if (
        moreCardApiResp.error ||
        moreCardApiResp.isValidMoreCardNumber !== true
      ) {
        errorMsg.moreCardNumber =
          "The "+ brandName +" card number doesn't match our records, please check and try again."
      } else if (
        moreCardApiResp.isValidLastName === false ||
        moreCardApiResp.isValidFirstName === false
      ) {
        errorMsg.lastName =
          "The name on this"+ brandName +" card doesn't match our record, please try again.."
      }
    }
    }

    consoleLog('LinkMoreCardNonColleague', '91', 'errorMsg : ', errorMsg)
    return errorMsg
  }

  const checkIsAnyError = () => {
    let isError = false
    if (discountCardApiResp) {
      if (
        discountCardApiResp.error ||
        discountCardApiResp.isCardNumber === false
      ) {
        isError = true
      }

      if (discountCardApiResp.isFirstName === false) {
        isError = true
      }
      if (discountCardApiResp.isLastName === false) {
        isError = true
      }
    }
    if (moreCardApiResp) {
      if (
        moreCardApiResp.error ||
        moreCardApiResp.isValidMoreCardNumber !== true
      ) {
        isError = true
      } else if (
        moreCardApiResp.isValidLastName === false ||
        moreCardApiResp.isValidFirstName === false
      ) {
        isError = true
      }
    }
    return isError
  }

  const validateMoreNumber = () => {
    if (
      moreCardApiResp &&
      moreCardApiResp.isValidMoreCardNumber === true &&
      moreCardApiResp.isValidLastName === true &&
      moreCardApiResp.isValidFirstName === true
    ) {
      return true
    }
    return false
  }

  const validateCardNumber = () => {
    if (discountCardApiResp) {
      return (
        discountCardApiResp.isCardNumber &&
        discountCardApiResp.isFirstName &&
        discountCardApiResp.isLastName
      )
    }
    return false
  }

  const handleDiscountCardNumberChange = (cardNumber: string) => {
    setDiscountCardNumber(cardNumber)
  }

  const handleLastNameChange = (lastName: string) => {
    setLastName(lastName)
  }

  const handleMoreCardChange = (moreCard: string) => {
    setMoreCardNumber(moreCard)
  }

  const handleMoreClick = async () => {
    const payload = {
      moreCardNumber: moreCardNumber,
      lastName: lastName,
      empId: employeeId,
    }
    await linkMoreCardNonColleagueBFF(payload)
  }

  const handleFirstNameChange = (firstName: string) => {
    setFirstName(firstName)
  }

  consoleLog(
    'LinkMoreCardNonColleague',
    '172',
    'checkIsAnyError :',
    checkIsAnyError()
  )
  return (
    <CardContainer.Provider>
        {showDialogApiNonColleagueCardError && <DialogApiErrorNonColleague/>}   
      <div className="w-full flex items-center justify-center h-full">
        <Container>
          <SpinLoader />
          <Header />
          <div className="w-full mob:w-11/12 ml-3 mb-4">
            <Info />
            <DiscountCardDetails
              status={validateCardNumber()}
              lastName={lastName}
              firstName={firstName}
              discountCardNumber={discountCardNumber}
              handleLastNameChange={handleLastNameChange}
              handleFirstNameChange={handleFirstNameChange}
              handleDiscountCardNumberChange={handleDiscountCardNumberChange}
              validateColleagueDiscount={validateCDSCardBFF}
              errors={createDiscountError()}
            />

            {validateCardNumber() && (
              <NonColleagueLinkMoreCard
                handleMoreCardNumber={handleMoreCardChange}
                moreCardNumber={moreCardNumber}
                moreCardNumberError={createMoreCardError()}
                validateMoreCard={handleMoreClick}
              />
            )}
            {validateMoreNumber() && (
              <div className="mb-10">
                <RegistrationSuccess />
              </div>
            )}
          </div>
          {checkIsAnyError() && <Help />}
        </Container>
      </div>
    </CardContainer.Provider>
  )
}

export default LinkMoreCardNonColleague
