import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import BlankHeader from './BlankHeader'
import { Container } from '../component/common'

function Error() {
  return (
    <>
      <BlankHeader />
      <Container>
        <div className="flex justify-center items-center flex-wrap mt-10">
          <div className="w-full flex justify-center items-center">
            <CircularProgress />
          </div>
          <h1 className="text-sm mt-2 text-center  text-gray-600">
            Loading ...
          </h1>
        </div>
      </Container>
    </>
  )
}

export default Error
