import React from 'react'

import { Container } from '../component/common'

function BlankHeader() {
  return (
    <header className="flex text-center bg-primary h-20 w-full text-white text-2xl justify-left items-center">
      <Container>
        <div className="flex w-full justify-between">
          <div className="flex ">Colleague Discount Club</div>
        </div>
      </Container>
    </header>
  )
}

export default BlankHeader
